import { useState, useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'

const useChartData = () => {
  const [ptData, setPtData] = useState([])
  const [stData, setStData] = useState([])
  const [rebData, setRebData] = useState([])
  const [astData, setAstData] = useState([])
  const [bksData, setBksData] = useState([])
  const [tovData, setTovData] = useState([])
  const [threePData, setThreePData] = useState([])
  const [pfsData, setPfsData] = useState([])

  const [fpsData, setFpsData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    PlayerService.getPlayerPieChartValue().then((res) => {
      setLoading(true)
      if (res.status === 200) {
        setPtData(res.ptRangeData)
        setStData(res.stRangeData)
        setRebData(res.rebRangeData)
        setAstData(res.astRangeData)
        setBksData(res.bksRangeData)
        setTovData(res.tovRangeData)
        setThreePData(res.threepRangeData)
        setPfsData(res.pfsRangeData)
        setFpsData(res.fpRangeData)
        setLoading(false)
      }
    })
  }, [])

  const dataPtValues = Object.values(ptData).map((value) => parseInt(value, 10))
  const dataStValues = Object.values(stData).map((value) => parseInt(value, 10))
  const dataRebValues = Object.values(rebData).map((value) => parseInt(value, 10))
  const dataAstValues = Object.values(astData).map((value) => parseInt(value, 10))
  const dataBksValues = Object.values(bksData).map((value) => parseInt(value, 10))
  const dataTovValues = Object.values(tovData).map((value) => parseInt(value, 10))
  const dataThreePValues = Object.values(threePData).map((value) => parseInt(value, 10))
  const dataPfsValues = Object.values(pfsData).map((value) => parseInt(value, 10))
  const dataFpValues = Object.values(fpsData).map((value) => parseInt(value, 10))

  const formattedPtData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Point Per match',
        backgroundColor: '#36A2EB',
        data: dataPtValues,
      },
    ],
  }

  const formattedStData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Steals Per Match',
        backgroundColor: '#4BC0C0',
        data: dataStValues,
      },
    ],
  }

  const formattedRebData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Rebounds Per Match',
        backgroundColor: '#36A2EB',
        data: dataRebValues,
      },
    ],
  }

  const formattedAstData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Assists Per Match',
        backgroundColor: '#4BC0C0',
        data: dataAstValues,
      },
    ],
  }

  const formattedBksData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Blocks Per Match',
        backgroundColor: '#4BC0C0',
        data: dataBksValues,
      },
    ],
  }
  const formattedTovData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Turnover Per Match',
        backgroundColor: '#4BC0C0',
        data: dataTovValues,
      },
    ],
  }
  const formattedThreePData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: '3-Pointers Per Match',
        backgroundColor: '#4BC0C0',
        data: dataThreePValues,
      },
    ],
  }
  const formattedPfsData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Personal Fouls Per Match',
        backgroundColor: '#4BC0C0',
        data: dataPfsValues,
      },
    ],
  }

  const formattedFpData = {
    labels: ['1-5', '6-10', '11-15', '16-20', '21-30', '31-40', '41-50', '51+'],
    datasets: [
      {
        label: 'Fantasy Points Per Inning',
        backgroundColor: '#36A2EB',
        data: dataFpValues,
      },
    ],
  }

  return {
    loading,
    formattedPtData,
    formattedStData,
    formattedRebData,
    formattedAstData,
    formattedBksData,
    formattedTovData,
    formattedThreePData,
    formattedPfsData,
    formattedFpData,
  }
}

export default useChartData
