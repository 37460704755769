import { CCol, CForm, CFormFeedback, CFormLabel, CLoadingButton } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import GradeService from 'src/service/GradeService'
import ToastComponent from 'src/components/common/TaostComponent'
const EditPointSetting = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    ball_faced: Yup.string()
      .required('Ball faced is required')
      .max(50, '50 Character Limit is allowed.'),
    overs_bowled: Yup.string()
      .required('Overs Bowled is required')
      .max(50, '50 Character Limit is allowed.'),
  })
  const formik = useFormik({
    initialValues: {
      ball_faced: props.gradeDetail?.ball_faced,
      overs_bowled: props.gradeDetail?.overs_bowled,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      data.gradeId = props.gradeDetail?.id
      actions.resetForm()
      setLoader(true)
      GradeService.updateGradePointSetting(data).then((res) => {
        if (res.status === 200) {
          props.toggleDetails(props?.gradeId)
          props.fetchUsers()
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="grade">
            Minimum ball faced
          </CFormLabel>
          <input
            type="text"
            name="ball_faced"
            className={
              'form-control' +
              (formik.errors.ball_faced && formik.touched.ball_faced ? ' is-invalid' : '')
            }
            id="validationServer01"
            placeholder="Minimum ball faced"
            defaultValue={formik.values.ball_faced}
            onChange={formik.handleChange}
          />
          {formik.errors.ball_faced && formik.touched.ball_faced && (
            <CFormFeedback invalid>{formik.errors.ball_faced}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="overs_bowled">
            Minimum overs bowled
          </CFormLabel>
          <input
            type="text"
            name="overs_bowled"
            className={
              'form-control' +
              (formik.errors.overs_bowled && formik.touched.overs_bowled ? ' is-invalid' : '')
            }
            id="validationServer01"
            placeholder="Minimum overs bowled"
            defaultValue={formik.values.overs_bowled}
            onChange={formik.handleChange}
          />
          {formik.errors.overs_bowled && formik.touched.overs_bowled && (
            <CFormFeedback invalid>{formik.errors.overs_bowled}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EditPointSetting
