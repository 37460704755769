import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CFormSelect,
  CRow,
  CSmartTable,
  CTable,
  CTableBody,
  CTableHead,
} from '@coreui/react-pro'
import moment from 'moment'
import PlayerService from 'src/service/PlayerService'
const FixturePlayerStatsTab = (props) => {
  const [statsData, setStatsData] = useState([])
  const handlePlayer = (e) => {
    const playerId = e.target.value
    PlayerService.playerStatsByFixture(playerId).then((res) => {
      if (res.success) {
        setStatsData(res.data)
      }
    })
  }
  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CFormSelect
              aria-label="Select player"
              name="player"
              className={'form-control'}
              onChange={handlePlayer}
              id="player"
            >
              <option value={0}>Select Player</option>
              {props.playerList &&
                props.playerList.map((item, key) => (
                  <option value={item?.id} key={key}>
                    {item?.full_name}
                  </option>
                ))}
            </CFormSelect>
            <CTable className="main-table table innertable">
              <CTableHead color="dark">
                <tr>
                  <th>Date</th>
                  <th>Team</th>
                  <th>FP</th>
                  <th>PT</th>
                  <th>ST</th>
                  <th>REB</th>
                  <th>AST</th>
                  <th>BKS</th>
                  <th>TOV </th>
                  <th>3P</th>
                  <th>PFS</th>
                </tr>
              </CTableHead>
              <CTableBody>
                {statsData &&
                  statsData.map((item, key) => (
                    <tr key={key}>
                      <td> {moment(item.fixture.start_date).format('D.MM.YYYY')}</td>
                      <td>{item?.teamName}</td>
                      <td>{item.fantasy_points}</td>
                      <td>{item?.pt}</td>
                      <td>{item?.st}</td>
                      <td>{item?.reb}</td>
                      <td>{item?.ast}</td>
                      <td>{item?.bks}</td>
                      <td>{item?.tov}</td>
                      <td>{item?.threep}</td>
                      <td>{item?.pfs}</td>
                    </tr>
                  ))}
                {statsData.length === 0 && (
                  <tr>
                    <td colSpan={15}>No record yet available.</td>
                  </tr>
                )}
              </CTableBody>
            </CTable>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default FixturePlayerStatsTab
