import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CListGroupItem,
  CButton,
  CCol,
  CFormLabel,
  CFormSelect,
  CHeader,
  CContainer,
  CHeaderNav,
} from '@coreui/react-pro'
import logo from '../../assets/brand/logo.jpg'
import { cilInfo } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Notify from '../Notify'
import CommonService from 'src/service/CommonService'
import Loader from 'src/components/Loader'
import { AppHeaderDropdown } from 'src/components/header'
import { AppFooter } from 'src/components'
import ToastComponent from 'src/components/common/TaostComponent'
import ShowTooltipText from './ShowTooltipText'

const CopyGame = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [gameDropdown, setGameDropdown] = useState([])
  const [selectedSettings, setSelectedSettings] = useState([])

  const fetchData = async () => {
    try {
      const infoTextResponse = await CommonService.getGameList()
      if (infoTextResponse.status === 200) {
        setIsLoading(false)
        setGameDropdown(infoTextResponse.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [infoType, setInfoType] = useState('')
  const [gameId, setGameId] = useState(0)
  const [settingType, setSettingType] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)

  const showInfoContentModal = (type) => {
    setModalIsOpen(true)
    setInfoType(type)
  }

  const handleGameChange = (value) => {
    setGameId(value)
  }

  const copyAboutGamedata = (settingType) => {
    const data = {}
    data.gameId = gameId
    data.settingType = settingType
    CommonService.copyGameSettingFromType(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }

  const handleCancel = () => {
    setSettingType(0)
    setShowConfirm(false)
  }

  const handleConfirm = () => {
    copyAboutGamedata(settingType)
    setShowConfirm(false)
  }

  const handleSettingChange = (e) => {
    const { name } = e.target
    if (selectedSettings.includes(name)) {
      setSelectedSettings(selectedSettings.filter((setting) => setting !== name))
    } else {
      setSelectedSettings([...selectedSettings, name])
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    if (selectedSettings.length > 0 && gameId !== 0) {
      const data = { gameId, settingTypes: selectedSettings }
      CommonService.copyGameSettingFromType(data).then((res) => {
        if (res.status === 200) {
          setIsLoading(false)
          ToastComponent(res.message, 'success')
        }
      })
    }
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CRow>
        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Duplicate Game</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CCol md={6}>
                <CFormLabel className="fw-bold" htmlFor="gameId">
                  Select an existing game*
                </CFormLabel>
                <CFormSelect
                  aria-label="select Game"
                  name="gameId"
                  className={'form-control'}
                  value={gameId}
                  onChange={(e) => handleGameChange(e.target.value)}
                  id="gameId"
                >
                  <option value={0}>Select Game</option>
                  {gameDropdown &&
                    gameDropdown.map((item, key) => (
                      <option value={item?.id} key={key}>
                        {item?.game_name}
                      </option>
                    ))}
                </CFormSelect>
              </CCol>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Settings</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="about_game"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'About Game'}
                    onClick={() => showInfoContentModal('about_game')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; About Game
                </p>
                <input
                  type="checkbox"
                  name="about_game"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="game_structure"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'Game Structure'}
                    onClick={() => showInfoContentModal('game_structure')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Game Structure
                </p>
                <input
                  type="checkbox"
                  name="game_structure"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="fantasy_values"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'Fantasy Values'}
                    onClick={() => showInfoContentModal('fantasy_value')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Fantasy Values
                </p>
                <input
                  type="checkbox"
                  name="fantasy_values"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="comps"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'Comps'}
                    onClick={() => showInfoContentModal('comps')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Comps & Point System
                </p>
                <input
                  type="checkbox"
                  name="comps"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="teams"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'Teams'}
                    onClick={() => showInfoContentModal('teams')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Teams & Bonus Points
                </p>
                <input
                  type="checkbox"
                  name="teams"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Controls</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="game_spots"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'game_spots'}
                    onClick={() => showInfoContentModal('game_spots')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Game Spots
                </p>
                <input
                  type="checkbox"
                  name="game_spots"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="branding"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'branding'}
                    onClick={() => showInfoContentModal('branding')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Branding
                </p>
                <input
                  type="checkbox"
                  name="branding"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
              <CListGroupItem
                className="d-flex justify-content-between align-items-center"
                key="sponsors"
              >
                <p style={{ margin: 0 }}>
                  <CButton
                    color={'warning'}
                    size="sm"
                    title={'sponsors'}
                    onClick={() => showInfoContentModal('sponsors')}
                  >
                    <CIcon icon={cilInfo} />
                  </CButton>{' '}
                  &nbsp; Sponsors
                </p>
                <input
                  type="checkbox"
                  name="sponsors"
                  onChange={(e) => handleSettingChange(e)}
                  disabled={gameId === 0}
                />
              </CListGroupItem>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      {selectedSettings.length > 0 && (
        <CRow className="mt-3">
          <CCol md={6} className="d-grid">
            <CButton onClick={handleSubmit} color="success" variant="outline">
              Create Duplicate Game
            </CButton>
          </CCol>
          <CCol md={6} className="d-grid">
            {' '}
            <CButton component="a" color="primary" href="/account" role="button">
              Back
            </CButton>
          </CCol>
        </CRow>
      )}
      <ShowTooltipText
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        infoType={infoType}
      />
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are you sure you want to Copy this?"
      />
    </>
  )
}

export default CopyGame
