import React, { useState } from 'react'
import {
  CForm,
  CRow,
  CFormInput,
  CCol,
  CFormSelect,
  CLoadingButton,
  CFormLabel,
  CFormFeedback,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
const SetMinPlayerValue = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    min_limit: Yup.string().required('Min Limit is required'),
  })
  const formik = useFormik({
    initialValues: {
      min_limit: props.advanceMinPlayerprice,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      data.limit_type = 'min'
      CommonService.updateSetFantasyValueLimit(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoader(false)
            props.fetchData()
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          console.log('Here is Error', e)
          setLoader(false)
        })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CRow className="">
          <CCol md={12} xs={12}>
            <CFormLabel className="fw-bold pt-2" htmlFor="team">
              Minimum Fantasy Value *
            </CFormLabel>

            <CFormSelect
              aria-label="Select Team"
              name="min_limit"
              className={
                'form-control' +
                (formik.errors.min_limit && formik.touched.min_limit ? ' is-invalid' : '')
              }
              defaultValue={formik.values.min_limit}
              onChange={formik.handleChange}
              id="min_limit"
            >
              <option value="0">Select Minimum Limit </option>
              <option value="4">4 </option>
              <option value="5">5 </option>
            </CFormSelect>
            {formik.errors.min_limit && formik.touched.min_limit && (
              <CFormFeedback invalid>{formik.errors.min_limit}</CFormFeedback>
            )}
          </CCol>
        </CRow>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default SetMinPlayerValue
