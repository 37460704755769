import {
  cilApps,
  cilAppsSettings,
  cilBell,
  cilCopy,
  cilExternalLink,
  cilInfo,
  cilMoon,
  cilNotes,
  cilPlus,
  cilSettings,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CNav,
  CNavLink,
  CRow,
  CWidgetStatsF,
  CAvatar,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CBadge,
  CSmartTable,
  CContainer,
  CHeaderNav,
  CButtonGroup,
  CHeaderDivider,
  CHeader,
  CHeaderBrand,
  CCardTitle,
  CCardText,
} from '@coreui/react-pro'
import { cilPeople } from '@coreui/icons'
import React, { useEffect, useRef, useState } from 'react'

import ToastComponent from 'src/components/common/TaostComponent'

const HubInfo = (props) => {
  const adminDetails = JSON.parse(localStorage.getItem('admin'))

  const [textToCopy, setTextToCopy] = useState(
    `${process.env.REACT_APP_FRONT_URL}account-hub?account_name=${adminDetails?.username}`,
  ) // Initialize with the text you want to copy

  // Function to handle copying text to the clipboard
  const copyToClipboard = () => {
    // Create a temporary input element
    const tempInput = document.createElement('textarea')
    tempInput.value = textToCopy
    document.body.appendChild(tempInput)

    // Select the text in the input element
    tempInput.select()
    tempInput.setSelectionRange(0, 99999) // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy')

    ToastComponent('Copied to clipboard', 'success')
    // Remove the temporary input element
    document.body.removeChild(tempInput)
  }

  return (
    <>
      <>
        <CRow className="justify-content-center" style={{ maxHeight: '700px;' }}>
          <CCol xs={12} sm={12} lg={12} className="mb-4">
            <CCard>
              <CCardHeader className="fw-bold">Fantasy Hub (ID: {adminDetails?.id})</CCardHeader>
              <CCardBody>
                <CCardText>
                  Share the link with your members to get them to join your Fantasy Hub and access
                  all fantasy games run through this account.
                </CCardText>
                <CButton
                  href={`${process.env.REACT_APP_FRONT_URL}account-hub?account_name=${adminDetails?.username}`}
                  target="_blank"
                  title="Go to Fantasy Hub"
                >
                  Go to Hub
                </CButton>{' '}
                &nbsp;
                <CButton onClick={copyToClipboard} color="success" title="Copy Fantasy Hub Link">
                  <CIcon icon={cilCopy} className="me-2" />
                </CButton>{' '}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </>
    </>
  )
}

export default HubInfo
