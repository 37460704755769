import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import PlayerProfileListing from './PlayerProfileListing'
import TeamPlayerImage from './TeamPlayerImage'
import PlayerPositionBulkUpload from './PlayerPositionBulkUpload'
import PlayerBulkImageUpload from './PlayerBulkImageUpload'

const PlayerProfile = () => {
  const [users, setUsers] = useState([])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Bulk Upload (By Players)</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <PlayerBulkImageUpload />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Bulk Upload (By Position)</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <PlayerPositionBulkUpload />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Bulk Upload (By Team)</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <TeamPlayerImage />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Players Profile</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <PlayerProfileListing users={users} setUsers={setUsers} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default PlayerProfile
