import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import { cilEnvelopeLetter, cilLockLocked, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/8.jpg'
import { useNavigate } from 'react-router-dom'
import useImageLoader from '../useImageLoader'
import ClubService from 'src/service/ClubService'

const AppHeaderDropdown = () => {
  const [accountDetails, setAccountDetails] = useState({})
  const adminDetails = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    ClubService.getClubDetails().then((res) => {
      if (res.status === 200) {
        setAccountDetails(res.user_details)
      }
    })
  }, [])
  const imageUrl = `${process.env.REACT_APP_USER_PROFILE}${adminDetails.image}`
  const alternativeImageUrl = `${process.env.REACT_APP_USER_PROFILE}${adminDetails.image}`
  const imageSrc = useImageLoader(imageUrl, alternativeImageUrl)

  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.clear()
    navigate('/login')
  }
  return (
    <>
      <CDropdown variant="nav-item" alignment="end">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <img
            src={`${process.env.REACT_APP_ACCOUNT_IMAGE}${accountDetails?.club_logo}`}
            alt="user"
            className="avatar-img"
            style={{ width: '40px', height: '40px' }}
          />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0">
          <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
            Account
          </CDropdownHeader>

          <CDropdownItem href="/update-profile">
            <CIcon icon={cilUser} className="me-2" />
            Profile
          </CDropdownItem>
          <CDropdownItem href="/welcome-message">
            <CIcon icon={cilEnvelopeLetter} className="me-2" />
            Manage Hub
          </CDropdownItem>
          <CDropdownItem onClick={handleLogout}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

export default AppHeaderDropdown
