import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const scorecardDetail = async (fixtureId, inning) => {
  const response = await agent.get(`${API_URL}scorecard/${fixtureId}/${inning}`)
  return response.data
}

const saveScorecard = async (data) => {
  const response = await agent.post(`${API_URL}save-scorecard`, data)
  return response.data
}
const showScorecard = async (fixtureId) => {
  const response = await agent.get(`${API_URL}show-scorecard/${fixtureId}`)
  return response.data
}
const showSquad = async (fixtureId) => {
  const response = await agent.get(`${API_URL}show-squads/${fixtureId}`)
  return response.data
}
const manageScorecard = async (fixtureId) => {
  const response = await agent.get(`${API_URL}manage-scorecards/${fixtureId}`)
  return response.data
}
const manageUpdateScorecard = async (data) => {
  const response = await agent.post(`${API_URL}scorecard/manage-scorecards`, data)
  return response.data
}
const getSavedScorecardData = async (data) => {
  const response = await agent.post(`${API_URL}scorecard/get-saved-scorecard-data`, data)
  return response.data
}
const markAsComplete = async (fixtureId) => {
  const response = await agent.post(`${API_URL}scorecard/markAsComplete/${fixtureId}`)
  return response.data
}
const changFixtureStatus = async (fixtureId, status) => {
  const response = await agent.get(`${API_URL}scorecard/changFixtureStatus/${fixtureId}/${status}`)
  return response.data
}
const revertScorcard = async (fixtureId, status) => {
  const response = await agent.get(`${API_URL}revertScorcard/${fixtureId}`)
  return response.data
}
const imageUplaod = async (formData) => {
  const response = await agent.post(`${API_URL}scorecard/imageUpload`, formData)
  return response.data
}
const updateCustomScorecardField = async (data) => {
  const response = await agent.post(`${API_URL}scorecard/updateCustomScorecardField`, data)
  return response.data
}
const clearFixtureScorecard = async (data) => {
  const response = await agent.post(`${API_URL}clearFixtureScorecard`, data)
  return response.data
}

const ScorecardService = {
  scorecardDetail,
  changFixtureStatus,
  saveScorecard,
  showScorecard,
  showSquad,
  manageScorecard,
  manageUpdateScorecard,
  getSavedScorecardData,
  markAsComplete,
  imageUplaod,
  revertScorcard,
  updateCustomScorecardField,
  clearFixtureScorecard,
}

export default ScorecardService
