import React, { useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CHeader,
  CHeaderNav,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import logo from '../../assets/brand/logo.jpg'
import CIcon from '@coreui/icons-react'
import { useFormik } from 'formik'
import ToastComponent from 'src/components/common/TaostComponent'
import ClubService from 'src/service/ClubService'
import SponsorService from 'src/service/SponsorService'
import { useMemo } from 'react'
import ReactQuill from 'react-quill'
import { useRef } from 'react'
import { useEffect } from 'react'
import { cilApps } from '@coreui/icons'
import AppHeaderDropdown from './AppHeaderDropdown'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader'
import 'react-quill/dist/quill.snow.css' // Import Quill styles
const GameWelcomeMessage = (props) => {
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [welcomeMessage, setWelcomeMessage] = useState('')
  useEffect(() => {
    ClubService.getWelcomeMessage().then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setWelcomeMessage(res.data)
      }
    })
  }, [])
  const formik = useFormik({
    initialValues: {
      welcome_message: welcomeMessage,
    },
    enableReinitialize: true,
    onSubmit: (data) => {
      data.welcome_message = welcomeMessage
      setLoading(true)
      ClubService.setWelcomeMessage(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setLoading(false)
        }
      })
    },
  })

  // Editor code here.
  const quillRef = useRef()
  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (/^image\//.test(file.type)) {
        const formData = new FormData()
        formData.append('image', file)
        const res = await SponsorService.imageUplaod(formData) // upload data into server or aws or cloudinary
        const url = res?.url
        editor.insertEmbed(editor.getSelection(), 'image', url)
      } else {
        ToastComponent('You could only upload images.', 'error')
      }
    }
  }
  const navigate = useNavigate()
  const handleAccountPage = () => {
    navigate('/account')
  }
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
          [
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  )
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CRow className="justify-content-center mt-4">
        <CCol md={12} lg={12} xl={12}>
          <CCard className="mx-4 ">
            <CCardBody className="p-4">
              <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                <CCol md={12}>
                  <CFormLabel className="fw-bold" htmlFor="About Game">
                    Welcome Message
                  </CFormLabel>
                  <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    value={welcomeMessage}
                    onChange={setWelcomeMessage}
                    modules={modules}
                  />
                </CCol>

                <CCol md={6}>
                  <CLoadingButton type="submit" color="success" variant="outline" loading={loading}>
                    Submit
                  </CLoadingButton>
                </CCol>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default GameWelcomeMessage
