import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  CRow,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CTooltip,
  CListGroup,
  CListGroupItem,
  CCol,
  CLoadingButton,
} from '@coreui/react-pro'
import UserService from 'src/service/UserService'
import PlayerService from 'src/service/PlayerService'
import HeadToHeadService from 'src/service/HeadToHeadService'
import CommonService from 'src/service/CommonService'

const DownloadCsv = () => {
  const [roundList, setRoundList] = useState([])
  const [roundId, setRoundId] = useState(0)
  const [gameName, setGameName] = useState(0)

  const fetchData = (roundId) => {
    UserService.getRank(roundId).then((result) => {
      setRoundList(result.data?.round_list)
    })
  }

  useEffect(() => {
    fetchData(roundId)
    CommonService.getGameName().then((res) => {
      if (res.success) {
        setGameName(res.game_name)
      }
    })
  }, [roundId])

  const convertToPlayerStatsCsv = (data) => {
    const csvHeader = 'Player Name,FP,POS,RS,4S,6S,OVR,MDN,WKS,CS,CWK,STS,ROD,ROA,DKS,HT\n'
    const csvRows = data.map(
      (user) =>
        `${user?.player_name},${user.sum_fp},${user.position_name},${user.sum_rs},${user.sum_fours},${user.sum_sixes},${user.sum_overs},${user.sum_mdns},${user.sum_wks},${user.sum_cs},${user.sum_cwks},${user.sum_sts},${user.sum_rods},${user.sum_roas},${user.sum_dks},${user.sum_hattrick}`,
    )
    return csvHeader + csvRows.join('\n')
  }

  const convertToCsv = (data) => {
    const csvHeader = 'Full Name,Team Name,Points,Rank\n'
    const csvRows = data.map(
      (user) =>
        `${user?.userdata['full_name']},${user.my_team_name},${user.team_points},${user.team_rank}`,
    )
    return csvHeader + csvRows.join('\n')
  }
  const convertToPlayerValuesCsv = (data) => {
    const csvHeader = 'Full Name,Starting Value,Current Value,Value Change,Value Change(%)\n'
    const csvRows = data.map(
      (user) =>
        `${user?.full_name},${user.initial_value},${user.svalue},${user.value_change},${user.value_change_in_percent}`,
    )
    return csvHeader + csvRows.join('\n')
  }

  const convertToH2HCsv = (data) => {
    const csvHeader =
      'Full Name,Played,Wins,Losses,Ties,Win%,Points For,Points Against,Point Difference,Points\n'
    const csvRows = data.map(
      (user) =>
        `${user?.full_name},${user.played},${user.wins},${user.losses},${user.ties},${user.win_percentage},${user.points_for},${user.points_against},${user.point_difference},${user.points}`,
    )
    return csvHeader + csvRows.join('\n')
  }

  const downloadCsvFile = async (roundId) => {
    setRoundId(roundId) // Update roundId
    // Wait for fetchData to complete
    await UserService.getRank(roundId).then((result) => {
      const fetchedData = result.data?.user_teams || []
      if (fetchedData.length) {
        const csvData = convertToCsv(fetchedData)
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `MyClubtap-${gameName}-Rank.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }

  const downloadPlayerStatsCsvFile = async () => {
    await PlayerService.playerCsvStats(roundId).then((result) => {
      const fetchedData = result.data || []
      if (fetchedData.length) {
        const csvData = convertToPlayerStatsCsv(fetchedData)
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `MyClubtap-${gameName}-Player-Stats.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }
  const downloadPlayerValuesCsvFile = async () => {
    await PlayerService.playerCsvValues().then((result) => {
      const fetchedData = result.data || []
      if (fetchedData.length) {
        const csvData = convertToPlayerValuesCsv(fetchedData)
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `MyClubtap-${gameName}-Player-Values.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }
  const downloadH2HCsvFile = async () => {
    await HeadToHeadService.headToHeadLeaderboardCsv().then((result) => {
      const fetchedData = result.data || []
      if (fetchedData.length) {
        const csvData = convertToH2HCsv(fetchedData)
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `MyClubtap-${gameName}-H2H.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }

  const convertToMemberCsv = (data) => {
    const csvHeader = 'Full Name,Email,Phone,Registered,Status\n'
    const csvRows = data.map(
      (user) =>
        `${user?.full_name},${user.email},${user.phone},${moment(user.created_at).format(
          'D.MM.YYYY',
        )},${user.is_verified === 1 ? 'Verified' : 'Unverified'}`,
    )

    return csvHeader + csvRows.join('\n')
  }

  const downloadMemberCsvFile = async () => {
    await UserService.getMemberCsvData().then((result) => {
      const fetchedData = result.data || []
      if (fetchedData.length) {
        const csvData = convertToMemberCsv(fetchedData)
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `MyClubtap-${gameName}-Members.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }

  return (
    <CRow>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <CTooltip
              content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              placement="top"
            >
              <strong>Downloads</strong>
            </CTooltip>
          </CAccordionHeader>
          <CAccordionBody>
            <CCol md={12}>
              <CListGroup>
                <CListGroupItem className="fw-bold"> Overall Ranking </CListGroupItem>
                <CListGroupItem className="d-flex justify-content-between align-items-center">
                  Overall{' '}
                  <CLoadingButton color="danger" size="sm" onClick={() => downloadCsvFile(0)}>
                    Download CSV
                  </CLoadingButton>
                </CListGroupItem>
                {roundList &&
                  roundList.map((item) => (
                    <CListGroupItem
                      key={item.id}
                      className="d-flex justify-content-between align-items-center"
                    >
                      Round {item?.round}
                      <CLoadingButton
                        size="sm"
                        color="danger"
                        onClick={() => downloadCsvFile(item?.id)}
                      >
                        Download CSV
                      </CLoadingButton>
                    </CListGroupItem>
                  ))}
              </CListGroup>
            </CCol>
            <CCol md={12} className="mt-2">
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center fw-bold">
                  Player Stats (All)
                  <CLoadingButton size="sm" color="danger" onClick={downloadPlayerStatsCsvFile}>
                    Download CSV
                  </CLoadingButton>
                </CListGroupItem>
              </CListGroup>
            </CCol>
            <CCol md={12} className="mt-2">
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center fw-bold">
                  Player Values (All)
                  <CLoadingButton size="sm" color="danger" onClick={downloadPlayerValuesCsvFile}>
                    Download CSV
                  </CLoadingButton>
                </CListGroupItem>
              </CListGroup>
            </CCol>
            <CCol md={12} className="mt-2">
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center fw-bold">
                  Head-2-Head (All)
                  <CLoadingButton size="sm" color="danger" onClick={downloadH2HCsvFile}>
                    Download CSV
                  </CLoadingButton>
                </CListGroupItem>
              </CListGroup>
            </CCol>
            <CCol md={12} className="mt-2">
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center fw-bold">
                  Members
                  <CLoadingButton size="sm" color="danger" onClick={downloadMemberCsvFile}>
                    Download CSV
                  </CLoadingButton>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default DownloadCsv
