import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import TeamOfTheWeekTable from './TeamOfTheWeekTable'
import CommonService from 'src/service/CommonService'

const BonusCard = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    CommonService.getTotwData().then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Team of the Round</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <TeamOfTheWeekTable users={users} loading={loading} fetchData={fetchData} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default BonusCard
