import React, { useCallback, useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent.js'
import FixtureService from 'src/service/FixtureService'
import EditForm from './EditForm'
import Loader from 'src/components/Loader'
import Pusher from 'pusher-js'
import Bootbox from 'bootbox-react'
const ManageFixture = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState({})
  const navigate = useNavigate()

  const [details, setDetails] = useState([])

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const deleteFixture = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    // setUsers((previousEmployeeData) => previousEmployeeData.data.filter((data) => data.id !== id))
    FixtureService.deleteFixture(data).then((res) => {
      if (res.status === 200) {
        toast.dismiss()
        setUsers(res.data)
        ToastComponent(res.message, 'success')
        setLoading(false)
      }
    })
  }

  const [loader, setLoader] = useState(true)
  const [loaderText, setLoaderText] = useState(true)
  const [squadFixtureIds, setSquadFixtureIds] = useState([])
  const fetchUsers = useCallback(async () => {
    setLoader(false)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await FixtureService.getFixture(offset, itemsPerPage, activePage, params)
      setUsers(result.data)
      const squadResult = await FixtureService.getSquadFixture()
      setSquadFixtureIds(squadResult.data)
      const inProgressFixtureResult = await FixtureService.getInProgressFixture()
      if (inProgressFixtureResult.data && inProgressFixtureResult.data != null) {
        setLoader(true)
        setLoaderText(inProgressFixtureResult?.message)
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const hideEditDiv = () => {
    setVisibleHorizontal(false)
  }
  const toggleCheckbox = (id) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  // Delete All confirm box
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  const toggleAllCheckboxes = () => {
    const allIds = squadFixtureIds.map((user) => user.id)
    setSelectedCheckboxes((prevSelected) => (prevSelected.length === allIds.length ? [] : allIds))
  }
  const [showDeleteAllConfirm, showShowDeleteAllConfirm] = useState(false)
  const showDeleteAllCofirmBox = () => {
    showShowDeleteAllConfirm(true)
  }
  const handleDeleteAllConfirm = () => {
    handleDeleteAllFixture()
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllCancel = () => {
    console.log('You clicked No!')
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllFixture = () => {
    const data = {
      fixturesIds: selectedCheckboxes, // Include the array of IDs here
    }
    FixtureService.deleteAllFixture(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        setLoading(false)
        fetchUsers()
      }
    })
  }
  useEffect(() => {
    const pusher = new Pusher('2604209a1a0fdaa07dfc', {
      cluster: 'ap4',
    })
    const channel = pusher.subscribe('test-channel')
    channel.bind('test-event', (data) => {
      ToastComponent(data.message, 'red_error')
      fetchUsers()
    })

    const fixtureCompletionChannel = pusher.subscribe('fixture-completion-channel')
    fixtureCompletionChannel.bind('fixture-completion-event', (data) => {
      ToastComponent(data.message, 'success')
      fetchUsers()
    })
    return () => {
      // Unbind events and unsubsKcribe from channels
      channel.unbind_all()
      channel.unsubscribe()
      fixtureCompletionChannel.unbind_all()
      fixtureCompletionChannel.unsubscribe()
    }
  }, [fetchUsers])
  const columns = [
    {
      key: 'delete_fixture',
      label: (
        <input
          type="checkbox"
          name="delete_all"
          // checked={selectedCheckboxes.length === squadFixtureIds.length}
          checked={
            selectedCheckboxes.length === squadFixtureIds.length && squadFixtureIds.length > 0
          }
          onChange={toggleAllCheckboxes}
        />
      ),
      filter: false,
      sorter: false,
    },
    {
      label: 'FID',
      key: 'id',
      _style: { width: '10%' },
    },
    {
      key: 'round',
    },
    {
      label: 'Team Name',
      key: 'team_name',
    },
    { label: 'Start Date', filter: false, key: 'start_date' },
    { label: 'Start Time', filter: false, key: 'start_time' },
    { label: 'Status', filter: false, key: 'status' },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  return loader ? (
    <Loader loaderText={loaderText} />
  ) : (
    <>
      <CButton
        color="danger"
        className="mb-3"
        onClick={showDeleteAllCofirmBox}
        disabled={selectedCheckboxes.length === 0 ? 'true' : false}
      >
        Delete
      </CButton>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Scores</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  delete_fixture: (item) => {
                    const fixtureIdExists = squadFixtureIds.some(
                      (fixture) => fixture.id === item.id,
                    )
                    return (
                      <th>
                        {fixtureIdExists && (
                          <input
                            type="checkbox"
                            name="delete"
                            value={item.id}
                            checked={selectedCheckboxes.includes(item.id)}
                            onChange={() => toggleCheckbox(item.id)}
                          />
                        )}
                      </th>
                    )
                  },
                  round: (item) => <td>{item.round ?? '-'}</td>,
                  status: (item) => (
                    <td>
                      {item.status === 0 && <CBadge color="warning">Not Started</CBadge>}
                      {item.status === 1 && <CBadge color="danger">In-Progress</CBadge>}
                      {item.status === 2 && <CBadge color="success">Completed</CBadge>}
                    </td>
                  ),
                  grade: (item) => <td>{item.grade ?? ''}</td>,
                  start_date: (item) => <td>{moment(item.start_date).format('D.MM.YYYY')}</td>,
                  start_time: (item) => (
                    <td> {moment(item.start_time, ['HH:mm']).format('h:mm A')}</td>
                  ),
                  show_details: (item) => {
                    return (
                      <>
                        <td className="py-2">
                          {/* <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(item.id)
                            }}
                          >
                            {details.includes(item.id) ? 'Hide' : 'Manage'}
                          </CButton> */}
                          <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/manage-scores/team-players/${item.id}`}
                          >
                            Create Squad
                          </Link>
                          <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/scorecard/${item.id}`}
                          >
                            Manage Scores
                          </Link>
                        </td>
                      </>
                    )
                  },
                  details: (item) => {
                    return (
                      <CCollapse visible={details.includes(item.id)}>
                        <CCardBody>
                          <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/manage-scores/team-players/${item.id}`}
                          >
                            1. Create Squad
                          </Link>
                          <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/scorecard/${item.id}`}
                          >
                            2. Manage Scores
                          </Link>
                          {/* <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/manage-scores/assign-feedback-manager/${item.id}`}
                          >
                            3. Assign Feedback Manager
                          </Link> */}
                          {/* <Link
                            size="sm"
                            color="danger"
                            className="btn btn-success btn-sm ms-1"
                            to={`/scorecard/${item.id}`}
                          >
                            4. Feedback Page
                          </Link> */}
                          <CCollapse id="collapseEdit" horizontal visible={visibleHorizontal}>
                            <CCard className="mb-4">
                              <CCardHeader>
                                <strong>Edit Fixture</strong>
                              </CCardHeader>
                              <CCardBody>
                                <EditForm
                                  fixtureId={item.id}
                                  selectedId={selectedId}
                                  hideEditDiv={hideEditDiv}
                                  toggleDetails={toggleDetails}
                                />
                              </CCardBody>
                            </CCard>
                          </CCollapse>
                        </CCardBody>
                      </CCollapse>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <Bootbox
        show={showDeleteAllConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete all fixtures?'}
        onSuccess={handleDeleteAllConfirm}
        onCancel={handleDeleteAllCancel}
        onClose={handleDeleteAllCancel}
      />
    </>
  )
}

export default ManageFixture
