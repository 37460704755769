import React, { useEffect, useState } from 'react'
import {
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import moment from 'moment'
import ClubService from 'src/service/ClubService'

const GameInfo = () => {
  const [gameDetails, setGameDetails] = useState({})
  const [roundStartDetails, setRoundStartDetails] = useState({})
  const [roundEndDetails, setRoundEndDetails] = useState({})
  const [userDetails, setUserDetails] = useState({})
  useEffect(() => {
    ClubService.getClubDetails().then((res) => {
      if (res.status === 200) {
        setGameDetails(res.game_details)
        setRoundStartDetails(res.roundStartDetails)
        setRoundEndDetails(res.roundEndDetails)
        setUserDetails(res.user_details)
      }
    })
  }, [])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Game Info</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <CCol xs={12}>
                  <table className="main-table table table-bordered">
                    <tbody>
                      <tr>
                        <td>Hub ID</td>
                        <td>{userDetails?.id}</td>
                      </tr>
                      <tr>
                        <td>Game ID</td>
                        <td>{gameDetails?.id}</td>
                      </tr>
                      <tr>
                        <td>Game Name</td>
                        <td>{gameDetails?.game_name}</td>
                      </tr>
                      <tr>
                        <td>Timezone</td>
                        <td>{gameDetails?.timezone}</td>
                      </tr>
                      <tr>
                        <td>Terms & Conditions</td>
                        <td>{gameDetails?.is_termcondition === 1 ? 'Yes' : 'No'}</td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Basic Settings</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <CCol xs={12}>
                  <table className="main-table table table-bordered">
                    <tbody>
                      <tr>
                        <td>Team Size</td>
                        <td>{gameDetails?.team_size}</td>
                      </tr>
                      <tr>
                        <td>Selection Mode</td>
                        <td>{gameDetails?.team_view === 0 ? 'Position Based' : 'Position Free'}</td>
                      </tr>
                      <tr>
                        <td>Salary Cap</td>
                        <td>{gameDetails?.salary_cap}</td>
                      </tr>
                      <tr>
                        <td>Trades</td>
                        <td>{gameDetails?.trades}</td>
                      </tr>
                      <tr>
                        <td>Game Privacy</td>
                        <td>{gameDetails?.game_privacy === 2 ? 'Private' : 'Public'}</td>
                      </tr>
                      <tr>
                        <td>Salary Symbol</td>
                        <td>{gameDetails?.default_salary_symbol}</td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td>
                          {moment(roundStartDetails?.start_date).format('D.MM.YYYY')}{' '}
                          {moment(roundStartDetails?.start_date).format('h:mm A')}
                        </td>
                      </tr>
                      <tr>
                        <td>End Date</td>
                        <td>
                          {moment(roundEndDetails?.end_date).format('D.MM.YYYY')}{' '}
                          {moment(roundEndDetails?.end_date).format('h:mm A')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default GameInfo
