import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormFeedback,
  CFormSelect,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BracketBattleService from 'src/service/BracketBattleService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ToastComponent from 'src/components/common/TaostComponent'
import FinalizeResult from './FinalizeResult'
import WinnerListing from './WinnerListing'
import TieMatch from './TieMatch'

const BattleListing = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const param = useParams()
  const [bracketListing, setBracketListing] = useState([])
  const [userList, setUserList] = useState([])
  const [getWinners, setGetWinners] = useState([])
  const [bracketStructure, setBracketStructure] = useState({})
  const [bracketRoundStatus, setBracketRoundStatus] = useState(0)
  const [tieMatchList, setTieMatchedList] = useState([])

  useEffect(() => {
    setLoader(true)
    BracketBattleService.battleListing(param.id).then((result) => {
      if (result.success) {
        const array1 = result.user_list
        const array2 = result.byeUsersBattle
        const mergedArray = array1.concat(array2)

        setBracketListing(result.getBattles)
        setUserList(mergedArray)
        setGetWinners(result.getWinners)
        setBracketStructure(result.getBracketStructure)
        setBracketRoundStatus(result.getBracketStructure?.status)
        setTieMatchedList(result?.getTieMatch)
      } else {
        ToastComponent(result.message, 'error')
        navigate('/bracket-round')
      }

      setLoader(false)
    })
  }, [param.id, navigate])
  // const validationSchema = Yup.object({
  //   data: Yup.array().of(
  //     Yup.object().shape({
  //       first_opponent: Yup.number().required('Please selct first opponent'),
  //       second_opponent: Yup.number().required('Please selct second opponent'),
  //     }),
  //   ),
  // })

  // })
  // const validationSchema = object().shape({
  //   data: array()
  //     .of(
  //       object().shape({
  //         first_opponent: string().ensure().required('Please selct first opponent'),
  //         second_opponent: string().ensure().required('Please selct second opponent'),
  //       }),
  //     )
  //     .required('Please select opponent.'),
  // })

  const validationSchema = Yup.object({
    data: Yup.array().of(
      Yup.object({
        first_opponent: Yup.string().required('Required'),
        second_opponent: Yup.string().required('Required'),
      }),
    ),
  })

  const formik = useFormik({
    initialValues: {
      data: bracketListing,
      roundId: param.id,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      setLoader(true)
      BracketBattleService.updateRoundBattle(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoader(false)
          }
        })
        .catch((e) => {
          console.log('e=>', e)
          ToastComponent('Something Went wrong.Please try again', 'error')
          setLoader(false)
        })
    },
  })

  const [selectOptions, setSelectOptions] = useState({
    first_opponent: '',
    second_opponent: '',
  })
  const handleChange = (e) => {
    const id = e.target.value
    // console.log('id', id)
    // setUserList((current) => current.filter((fruit) => fruit.user_id !== id))
  }
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Round {bracketStructure?.round} Bracket Battles</strong>
            </CCardHeader>
            <form className="" onSubmit={formik.handleSubmit}>
              <CCardBody>
                <table className="main-table table innertable">
                  <thead>
                    <tr>
                      <th>Matches</th>
                      <th>First Opponent</th>
                      <th>Second Opponent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bracketListing &&
                      bracketListing?.map((item, key) => (
                        <tr key={key}>
                          <th>{item.battle}</th>
                          <td>
                            <CFormSelect
                              id="first_opponent"
                              defaultValue={item.first_opponent}
                              name={`data[${key}]first_opponent`}
                              // onChange={formik.handleChange}
                              onChange={(event) => {
                                formik.setTouched({
                                  ...formik.touched,
                                  first_opponent: true,
                                })
                                formik.setFieldValue(
                                  `data[${key}].first_opponent`,
                                  event.target.value,
                                )
                                handleChange(event)
                              }}
                            >
                              <option value={0}>Please select opponent</option>
                              {userList.map((index, key) => (
                                <option value={index.user_id} key={key}>
                                  {index.username}
                                </option>
                              ))}
                            </CFormSelect>

                            {/* {formik.errors?.data[key]?.first_opponent &&
                            formik.touched?.data[key]?.first_opponent && (
                              <CFormFeedback invalid>
                                {formik.errors?.data[key]?.first_opponent}
                              </CFormFeedback>
                            )} */}
                            {formik.errors?.data?.[key]?.first_opponent && (
                              <p>{formik.errors?.data?.[key]?.first_opponent.message}</p>
                            )}
                          </td>
                          <td>
                            <CFormSelect
                              id="second_opponent"
                              defaultValue={item.second_opponent}
                              name={`data[${key}]second_opponent`}
                              onChange={formik.handleChange}
                            >
                              <option value={0}>Please select opponent</option>
                              {userList.map((index, key) => (
                                <option value={index.user_id} key={key}>
                                  {index.username}
                                </option>
                              ))}
                            </CFormSelect>
                            {/* {formik.errors?.data[key]?.second_opponent &&
                            formik.touched?.data[key]?.second_opponent && (
                              <CFormFeedback invalid>
                                {formik.errors?.data[key]?.second_opponent}
                              </CFormFeedback>
                            )} */}
                          </td>
                        </tr>
                      ))}
                    {bracketListing.length === 0 && (
                      <tr>
                        <td colSpan={3}>No record yet available.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {bracketStructure?.status !== 2 && (
                  <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
                    Submit
                  </CLoadingButton>
                )}
                {bracketStructure?.status === 2 && <CBadge color={'success'}>{'Completed'}</CBadge>}
              </CCardBody>
            </form>
          </CCard>
        </CCol>
      </CRow>
      <FinalizeResult
        roundId={param.id}
        bracketRoundStatus={bracketRoundStatus}
        setBracketRoundStatus={setBracketRoundStatus}
        bracketStructure={bracketStructure}
        setTieMatchedList={setTieMatchedList}
      />
      {tieMatchList.length > 0 && (
        <TieMatch
          tieMatchList={tieMatchList}
          bracketStructure={bracketStructure}
          setGetWinners={setGetWinners}
          setTieMatchedList={setTieMatchedList}
        />
      )}
      {getWinners.length > 0 && (
        <WinnerListing
          getWinners={getWinners}
          bracketStructure={bracketStructure}
          setTieMatchedList={setTieMatchedList}
        />
      )}
    </>
  )
}

export default BattleListing
