import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FeatureService from 'src/service/FeatureService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(50, '50 Character Limit is allowed.'),
    type: Yup.string().required('Type is required'),
    short_description: Yup.string().required('Short description is required'),
    long_description: Yup.string().required('Long description is required'),
  })
  const formik = useFormik({
    initialValues: {
      title: '',
      type: '',
      short_description: '',
      long_description: '',
      primary_image: null,
      secondary_image: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      var formData = new FormData()
      formData.append('title', data.title)
      formData.append('type', data.type)
      formData.append('short_description', data.short_description)
      formData.append('long_description', data.long_description)
      formData.append('primary_image', data.primary_image)
      // Append each secondary image to formData
      data.secondary_image.forEach((image) => {
        formData.append('secondary_image[]', image)
      })

      actions.resetForm()
      FeatureService.saveFeature(formData)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            props.setLoading(false)
            setLoader(false)
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.asdasdasd', 'error')
          props.setLoading(false)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="grade_name">
            Type *
          </CFormLabel>
          <CFormSelect
            aria-label="Select Comp"
            name="type"
            className={
              'form-control' + (formik.errors.type && formik.touched.type ? ' is-invalid' : '')
            }
            value={formik.values.type}
            onChange={formik.handleChange}
            id="type"
          >
            <option value={0}>Select type</option>
            <option value={1}>Feature (Admin)</option>
            <option value={2}>Feature (Member)</option>
            <option value={3}>What&apos;s New</option>
            <option value={4}>Press Release</option>
            <option value={5}>Tour</option>
          </CFormSelect>
          {formik.errors.type && formik.touched.type && (
            <CFormFeedback invalid>{formik.errors.type}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Title *
          </CFormLabel>
          <CFormInput
            placeholder="Title"
            className={
              'form-control' + (formik.errors.title && formik.touched.title ? ' is-invalid' : '')
            }
            value={formik.values.title}
            onChange={formik.handleChange}
            aria-label="title"
            id="title"
          />
          {formik.errors.title && formik.touched.title && (
            <CFormFeedback invalid>{formik.errors.title}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Short Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={formik.values.short_description}
            onChange={(content) => {
              formik.setFieldValue('short_description', content)
            }}
          />
          {formik.errors.short_description && formik.touched.short_description && (
            <CFormFeedback invalid>{formik.errors.short_description}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Long Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={formik.values.long_description}
            onChange={(content) => {
              formik.setFieldValue('long_description', content)
            }}
          />
          {formik.errors.long_description && formik.touched.long_description && (
            <CFormFeedback invalid>{formik.errors.long_description}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Primary Image
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="primary_image"
            className={
              formik.touched.primary_image
                ? formik.errors.primary_image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                primary_image: true,
              })
              formik.setFieldValue('primary_image', event.target.files[0])
            }}
          />
          {formik.touched.primary_image && formik.errors.primary_image ? (
            <CFormFeedback invalid>{formik.errors.primary_image}</CFormFeedback>
          ) : null}
          <CRow>
            <CCol md={4}>
              <CCol md={4}>
                {' '}
                {formik.values.primary_image ? (
                  <PreviewImage
                    className={{ margin: 'auto' }}
                    width={100}
                    height={100}
                    file={formik.values.primary_image}
                  />
                ) : null}
              </CCol>
            </CCol>
          </CRow>
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Secondary Images
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="secondary_image"
            className={
              formik.touched.secondary_image
                ? formik.errors.secondary_image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            multiple // Add this attribute to allow selecting multiple files
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                secondary_image: true,
              })

              const files = event.target.files
              const fileArray = Array.from(files) // Convert FileList to an array

              formik.setFieldValue('secondary_image', fileArray)
            }}
          />
          {formik.touched.secondary_image && formik.errors.secondary_image ? (
            <CFormFeedback invalid>{formik.errors.secondary_image}</CFormFeedback>
          ) : null}
          <CRow>
            {' '}
            {/* Displaying preview for multiple selected images is more complex and might require additional logic */}
            {formik.values.secondary_image ? (
              <>
                {Array.from(formik.values.secondary_image).map((file, index) => (
                  <PreviewImage
                    key={index}
                    className={{ margin: 'auto' }}
                    width={100}
                    height={100}
                    file={file}
                  />
                ))}
              </>
            ) : null}
          </CRow>
        </CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
