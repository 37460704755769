import { CSmartTable } from '@coreui/react-pro'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
import PlayerValueTable from './PlayerValueTable'

const PlayerValue = () => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [users, setUsers] = useState({})
  const columns = [
    { label: 'PID', filter: true, sorter: true, key: 'id', _style: { width: '10%' } },
    { label: 'Full Name', filter: true, sorter: true, key: 'full_name' },
    { label: 'Starting Value', filter: false, sorter: true, key: 'initial_value' },
    { label: 'Current Value', filter: false, sorter: true, key: 'svalue' },
    { label: 'Value Change', filter: false, sorter: true, key: 'value_change' },
    { label: 'Value Change(%)', filter: false, sorter: true, key: 'value_change_in_percent' },
  ]
  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.playerValues(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }
  return (
    <>
      <PlayerValueTable
        users={users}
        activePage={activePage}
        setActivePage={setActivePage}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        columnSorter={columnSorter}
        setColumnSorter={setColumnSorter}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        columns={columns}
        fetchData={fetchData}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  )
}

export default PlayerValue
