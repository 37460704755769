import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CTooltip,
  CListGroup,
  CListGroupItem,
  CBadge,
  CButton,
} from '@coreui/react-pro'
import { cilCheck, cilInfo, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import CommonService from 'src/service/CommonService'
import HeaderInfoContentModal from './HeaderInfoContentModal'
import Loader from 'src/components/Loader'
const HeaderTextHelp = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [infoTextListing, setInfoTextListing] = useState([])
  const [catCounts, setCatCounts] = useState({})
  const headerText = [
    'Settings',
    'Controls',
    'Players',
    'Fixtures',
    'Members',
    'Trades',
    'Analytics',
    'Bonus Cards',
    'Bracket Battle',
    'Head-2-Head',
    'Timeline',
    'Team of the Round',
    'Votes',
    'Pricing',
  ]
  const fetchData = async () => {
    try {
      const infoTextResponse = await CommonService.getInfoTextListing()
      if (infoTextResponse.status === 200) {
        setInfoTextListing(infoTextResponse.data)
      }

      const catCountsResponse = await CommonService.getCatCounts(headerText)
      if (catCountsResponse.status === 200) {
        setIsLoading(false)
        setCatCounts(catCountsResponse.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [pageName, setPageName] = useState('')

  const showInfoContentModal = (pageName, id) => {
    setModalIsOpen(false)
    setPageName(pageName)
    CommonService.getHeaderInfoDetailsAndRead(id).then((res) => {
      if (res.status === 200) {
        setModalIsOpen(true)
        setModalContent(res.data?.info_text)
        fetchData() // Call fetchData here
      }
    })
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CRow>
        {headerText.map((header, index) => (
          <React.Fragment key={index}>
            <CAccordion alwaysOpen activeItemKey={1}>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>
                  {' '}
                  <strong>
                    {' '}
                    {header}&nbsp;({catCounts[header]?.readCount || 0}/
                    {catCounts[header]?.catCount || 0})
                  </strong>
                </CAccordionHeader>
                <CAccordionBody>
                  {/* Other code */}
                  {infoTextListing &&
                    infoTextListing
                      .filter(
                        (currentData) =>
                          currentData.header_text !== null && currentData.header_text === header,
                      )
                      .map((item, innerKey) => (
                        <CListGroupItem
                          className="d-flex justify-content-between align-items-center"
                          key={`${index}-${innerKey}`} // Concatenate index with innerKey to ensure unique key
                        >
                          <p style={{ margin: 0 }}>
                            <CButton
                              color={'warning'}
                              size="sm"
                              onClick={() => showInfoContentModal(item.label_name, item.id)}
                              title={item.tooltip}
                            >
                              <CIcon icon={cilInfo} />
                            </CButton>{' '}
                            &nbsp;
                            <Link to={`/${item.page_name}`} title={item.tooltip}>
                              {item.label_name}
                            </Link>
                          </p>
                          {item.read_by_club?.is_read === 1 ? (
                            <CBadge color="success" shape="rounded-pill" size={'sm'}>
                              <CIcon icon={cilCheck} />
                            </CBadge>
                          ) : (
                            <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                              <CIcon icon={cilX} />
                            </CBadge>
                          )}
                        </CListGroupItem>
                      ))}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </React.Fragment>
        ))}
      </CRow>
      <HeaderInfoContentModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        modalContent={modalContent}
        pageName={pageName}
      />
    </>
  )
}

export default HeaderTextHelp
