import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import BonusTable from './BonusTable'
import CommonService from 'src/service/CommonService'

const BonusCard = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    setLoading(true)
    CommonService.roundListing().then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Bonus Cards</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <BonusTable users={users} loading={loading} fetchData={fetchData} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default BonusCard
