import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
import ShowCaseService from 'src/service/ShowCaseService'
const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(50, '50 Character Limit is allowed.'),
  })
  const formik = useFormik({
    initialValues: {
      title: '',
      short_description: '',
      point1: '',
      point2: '',
      point3: '',
      point1_description: '',
      point2_description: '',
      point3_description: '',
      feature_images: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      var formData = new FormData()
      formData.append('title', data.title)
      formData.append('short_description', data.short_description)
      formData.append('point1', data.point1)
      formData.append('point2', data.point2)
      formData.append('point3', data.point3)
      formData.append('point1_description', data.point1_description)
      formData.append('point2_description', data.point2_description)
      formData.append('point3_description', data.point3_description)
      // Append each secondary image to formData
      data.feature_images.forEach((image) => {
        formData.append('feature_images[]', image)
      })

      actions.resetForm()
      ShowCaseService.saveShowCase(formData)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            props.setLoading(false)
            setLoader(false)
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.asdasdasd', 'error')
          props.setLoading(false)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Title *
          </CFormLabel>
          <CFormInput
            placeholder="Title"
            className={
              'form-control' + (formik.errors.title && formik.touched.title ? ' is-invalid' : '')
            }
            value={formik.values.title}
            onChange={formik.handleChange}
            aria-label="title"
            id="title"
          />
          {formik.errors.title && formik.touched.title && (
            <CFormFeedback invalid>{formik.errors.title}</CFormFeedback>
          )}
        </CCol>

        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Short Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={formik.values.short_description}
            onChange={(content) => {
              formik.setFieldValue('short_description', content)
            }}
          />
          {formik.errors.short_description && formik.touched.short_description && (
            <CFormFeedback invalid>{formik.errors.short_description}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Point1 *
          </CFormLabel>
          <CFormInput
            placeholder="Point 1"
            className={
              'form-control' + (formik.errors.point1 && formik.touched.point1 ? ' is-invalid' : '')
            }
            value={formik.values.point1}
            onChange={formik.handleChange}
            aria-label="point1"
            id="point1"
          />
          {formik.errors.point1 && formik.touched.point1 && (
            <CFormFeedback invalid>{formik.errors.point1}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Point2 *
          </CFormLabel>
          <CFormInput
            placeholder="Point 2"
            className={
              'form-control' + (formik.errors.point2 && formik.touched.point2 ? ' is-invalid' : '')
            }
            value={formik.values.point2}
            onChange={formik.handleChange}
            aria-label="point2"
            id="point2"
          />
          {formik.errors.point2 && formik.touched.point2 && (
            <CFormFeedback invalid>{formik.errors.point2}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Point3 *
          </CFormLabel>
          <CFormInput
            placeholder="Point 3"
            className={
              'form-control' + (formik.errors.point3 && formik.touched.point3 ? ' is-invalid' : '')
            }
            value={formik.values.point3}
            onChange={formik.handleChange}
            aria-label="point3"
            id="point3"
          />
          {formik.errors.point3 && formik.touched.point3 && (
            <CFormFeedback invalid>{formik.errors.point3}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="description">
            Point 1 Description
          </CFormLabel>
          <textarea
            name="point1_description"
            id="point1_description"
            className={
              'form-control' +
              (formik.errors.point1_description && formik.touched.point1_description
                ? ' is-invalid'
                : '')
            }
            placeholder="point 1 description"
            defaultValue={props.bonusCardDetail?.point1_description}
            onChange={formik.handleChange}
          />
          {formik.errors.point1_description && formik.touched.point1_description && (
            <CFormFeedback invalid>{formik.errors.point1_description}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="description">
            Point 2 Description
          </CFormLabel>
          <textarea
            name="point2_description"
            id="point2_description"
            className={
              'form-control' +
              (formik.errors.point2_description && formik.touched.point2_description
                ? ' is-invalid'
                : '')
            }
            placeholder="point 2 description"
            defaultValue={props.bonusCardDetail?.point2_description}
            onChange={formik.handleChange}
          />
          {formik.errors.point2_description && formik.touched.point2_description && (
            <CFormFeedback invalid>{formik.errors.point2_description}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="description">
            Point 3 Description
          </CFormLabel>
          <textarea
            name="point3_description"
            id="point 3 description"
            className={
              'form-control' +
              (formik.errors.point3_description && formik.touched.point3_description
                ? ' is-invalid'
                : '')
            }
            placeholder="point 3 description"
            defaultValue={props.bonusCardDetail?.point3_description}
            onChange={formik.handleChange}
          />
          {formik.errors.point3_description && formik.touched.point3_description && (
            <CFormFeedback invalid>{formik.errors.point3_description}</CFormFeedback>
          )}
        </CCol>

        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Feature Images
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="feature_images"
            className={
              formik.touched.feature_images
                ? formik.errors.feature_images
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            multiple
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                feature_images: true,
              })

              const files = event.target.files
              const fileArray = Array.from(files) // Convert FileList to an array

              formik.setFieldValue('feature_images', fileArray)
            }}
          />
          {formik.touched.feature_images && formik.errors.feature_images ? (
            <CFormFeedback invalid>{formik.errors.feature_images}</CFormFeedback>
          ) : null}
          <CRow>
            {formik.values.feature_images ? (
              <>
                {Array.from(formik.values.feature_images).map((file, index) => (
                  <PreviewImage
                    key={index}
                    className={{ margin: 'auto' }}
                    width={100}
                    height={100}
                    file={file}
                  />
                ))}
              </>
            ) : null}
          </CRow>
        </CCol>
        <CCol md={4}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
