import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getPlayers = async (offset, itemsPerPage, activePage, params) => {
  if (offset) {
    const response = await agent.get(
      `${API_URL}players/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
    )
    return response.data
  } else {
    const response = await agent.get(
      `${API_URL}players/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
    )
    return response.data
  }
}
const savePlayer = async (data) => {
  const response = await agent.post(API_URL + 'players/save-player', data)
  return response.data
}
const editPlayer = async (data) => {
  const response = await agent.post(API_URL + 'players/edit-player', data)
  return response.data
}

const editPlayerImage = async (data) => {
  const response = await agent.post(API_URL + 'players/editPlayerImage', data)
  return response.data
}
const updateTeamPlayerImage = async (data) => {
  const response = await agent.post(API_URL + 'players/updateTeamPlayerImage', data)
  return response.data
}
const saveBulkPlayerImage = async (data) => {
  const response = await agent.post(API_URL + 'players/saveBulkPlayerImage', data)
  return response.data
}
const saveBulkPlayerPositionImage = async (data) => {
  const response = await agent.post(API_URL + 'players/saveBulkPlayerPositionImage', data)
  return response.data
}
const getPlayerDetail = async (id) => {
  const response = await agent.get(`${API_URL}players/player-details/${id}`)
  return response.data
}
const getTeamPositionValueBatBowlStyle = async (id) => {
  const response = await agent.get(`${API_URL}players/get-position-team-val-bat-bowl-style`)
  return response.data
}
const deletePlayer = async (data) => {
  const response = await agent.post(API_URL + 'players/delete-player', data)
  return response.data
}

const getPlayerFantasyValue = async (id) => {
  const response = await agent.get(`${API_URL}players/fantasy-values`)
  return response.data
}

const updatePlayerFantasyValue = async (data) => {
  const response = await agent.post(API_URL + 'players/update-fantasy-values', data)
  return response.data
}
const savePlayerPrice = async (data) => {
  const response = await agent.post(API_URL + 'players/save-player-prices', data)
  return response.data
}
const saveDefaultPriceStructure = async (data) => {
  const response = await agent.post(API_URL + 'players/save-default-price-structure', data)
  return response.data
}
const savePlayerStructure = async (data) => {
  const response = await agent.post(API_URL + 'players/save-player-structure', data)
  return response.data
}
const getPlayerStructureInfo = async () => {
  const response = await agent.get(API_URL + 'players/get-player-structure-info')
  return response.data
}
const playerProfileClaimListing = async () => {
  const response = await agent.get(API_URL + 'players/player-profile-claim-listing')
  return response.data
}
const verifyPlayerRequest = async (data) => {
  const response = await agent.post(API_URL + 'players/verify-player-request', data)
  return response.data
}
const playerImport = async (data) => {
  const response = await agent.post(API_URL + 'player-import', data)
  return response.data
}
const saveVerifyUser = async (data) => {
  const response = await agent.post(API_URL + '/common/save-verify-user', data)
  return response.data
}
const updateStatus = async (id, status) => {
  const response = await agent.get(`${API_URL}players/update-status/${id}/${status}`)
  return response.data
}
const playerStats = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}playerStats?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const playerValues = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}playerValues?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const userTrade = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}userTrade?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const tradeLog = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}tradeLog?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const playerRound = async (searchPlayerName = '', sortOrder = 'asc', currentPage = '') => {
  const response = await agent.get(
    `${API_URL}playerRound?searchPlayerName=${searchPlayerName}&sortOrder=${sortOrder}&page=${currentPage}&perPage=20`,
  )
  return response.data
}
const deletePlayerAdvanceValue = async () => {
  const response = await agent.get(`${API_URL}deletePlayerAdvanceValue`)
  return response.data
}
const inActivePlayer = async () => {
  const response = await agent.get(`${API_URL}inActivePlayer`)
  return response.data
}
const getPlayerChartValue = async () => {
  const response = await agent.get(`${API_URL}players/getPlayerChartValue`)
  return response.data
}
const getPlayerPieChartValue = async () => {
  const response = await agent.get(`${API_URL}players/getPlayerPieChartValue`)
  return response.data
}
const getAllPlayer = async () => {
  const response = await agent.get(`${API_URL}getAllPlayer`)
  return response.data
}
const playerChart = async (playerId) => {
  const response = await agent.get(`${API_URL}playerChart/${playerId}`)
  return response.data
}
const deleteAllPlayer = async (data) => {
  const response = await agent.post(API_URL + 'players/deleteAllPlayer', data)
  return response.data
}
const changeDefaultPlayerView = async (data) => {
  const response = await agent.post(`${API_URL}players/changeDefaultPlayerView`, data)
  return response.data
}
const getCopiedPlayers = async (params) => {
  const response = await agent.get(`${API_URL}getCopiedPlayers`, { params })
  return response.data
}

const saveCopiedPlayers = async (data) => {
  const response = await agent.post(`${API_URL}saveCopiedPlayers`, data)
  return response.data
}
const playerCsvStats = async () => {
  const response = await agent.get(`${API_URL}playerCsvStats`)
  return response.data
}
const playerCsvValues = async () => {
  const response = await agent.get(`${API_URL}playerCsvValues`)
  return response.data
}

const playerStatsByRound = async (playerId) => {
  const response = await agent.get(`${API_URL}playerStatsByRound?player_id=${playerId}`)
  return response.data
}
const playerStatsByFixture = async (playerId) => {
  const response = await agent.get(`${API_URL}playerStatsByFixture?player_id=${playerId}`)
  return response.data
}

const PlayerService = {
  playerStats,
  saveCopiedPlayers,
  getCopiedPlayers,
  playerValues,
  getPlayers,
  savePlayer,
  deletePlayer,
  getPlayerDetail,
  editPlayer,
  getTeamPositionValueBatBowlStyle,
  getPlayerFantasyValue,
  updatePlayerFantasyValue,
  savePlayerPrice,
  saveDefaultPriceStructure,
  savePlayerStructure,
  getPlayerStructureInfo,
  playerProfileClaimListing,
  verifyPlayerRequest,
  updateStatus,
  playerImport,
  editPlayerImage,
  updateTeamPlayerImage,
  saveBulkPlayerImage,
  saveBulkPlayerPositionImage,
  playerRound,
  userTrade,
  tradeLog,
  saveVerifyUser,
  deletePlayerAdvanceValue,
  inActivePlayer,
  deleteAllPlayer,
  getPlayerChartValue,
  getPlayerPieChartValue,
  getAllPlayer,
  playerChart,
  changeDefaultPlayerView,
  playerCsvStats,
  playerCsvValues,
  playerStatsByRound,
  playerStatsByFixture,
}

export default PlayerService
