import { CChartBar, CChartPie } from '@coreui/react-chartjs'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import Loader from 'src/components/Loader'
import PlayerService from 'src/service/PlayerService'

const PieChart = () => {
  const [data, setData] = useState([])
  const [teamCount, setTeamCount] = useState([])
  const [teamCountTotal, setTeamCountTotal] = useState(0)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    PlayerService.getPlayerChartValue().then((res) => {
      setLoading(true)
      if (res.status === 200) {
        setData(res.data)
        setTeamCount(res.teamCounts)
        setTeamCountTotal(res.totalCount)

        setLoading(false)
      }
    })
  }, [])
  function getRandomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const teamData = teamCount.map((team) => {
    const percentage = ((team.team_count / teamCountTotal) * 100).toFixed(2)
    return {
      // label: `${team.team_name} (${team.team_count} - ${percentage}%)`,
      label: `${team.team_name} (${percentage}%)`,
      backgroundColor: getRandomColor(),
      data: team.team_count,
      percentage: percentage,
    }
  })

  const chartData = {
    labels: teamData.map((team) => team.label),
    datasets: [
      {
        data: teamData.map((team) => team.data),
        backgroundColor: teamData.map((team) => team.backgroundColor),
        hoverBackgroundColor: teamData.map((team) => team.backgroundColor),
        percentage: teamData.map((team) => team.percentage),
      },
    ],
  }

  return loading ? (
    <Loader />
  ) : (
    <CRow>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Player Categories</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartPie
                data={{
                  labels: [
                    `PG (${data?.pgCountPercent}%)`,
                    `SG(${data?.sgCountPercent}%)`,
                    `FWD (${data?.fwdCountPercent}%)`,
                    `CEN (${data?.cenCountPercent}%)`,
                  ],

                  datasets: [
                    {
                      data: [
                        `${data?.pgCount}`,
                        `${data?.sgCount}`,
                        `${data?.fwdCount}`,
                        `${data?.cenCount}`,
                      ],
                      backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                      hoverBackgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Fixtures Per Team</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartPie data={chartData} />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Players Used</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartPie
                data={{
                  labels: [`Yes (${data?.inUsePercent}%)`, `No(${data?.notInUsePercent}%)`],
                  datasets: [
                    {
                      data: [`${data?.playerInUse}`, `${data?.playerNotInUse}`],
                      backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                      hoverBackgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Teams Picked</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartPie
                data={{
                  labels: [
                    `Picked Team (${data?.membersWithTeamPercent}%)`,
                    `Not Picked Team(${data?.membersWithoutPercent}%)`,
                  ],
                  datasets: [
                    {
                      data: [`${data?.membersWithTeamCount}`, `${data?.membersWithoutTeamCount}`],
                      backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                      hoverBackgroundColor: ['#4BC0C0', '#36A2EB', '#FFCE56'],
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default PieChart
