import React, { useCallback, useEffect, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import Table from './Table'
import AddForm from './AddForm'
import CommonService from 'src/service/CommonService'
import PlayerAvailabilityService from 'src/service/PlayerAvailabilityService'
const PlayerAvailability = () => {
  const [multiOption, setMultiOption] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const fetchUsers = useCallback(async () => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await PlayerAvailabilityService.getAvailability(
        offset,
        itemsPerPage,
        activePage,
        params,
      )
      setUsers(result.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])
  useEffect(() => {
    CommonService.clubPlayers().then((result) => {
      setMultiOption(result.data)
    })
  }, [])
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Availability</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm multiOption={multiOption} fetchUsers={fetchUsers} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Availability</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table
              users={users}
              setUsers={setUsers}
              fetchUsers={fetchUsers}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setColumnFilter={setColumnFilter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
              multiOption={multiOption}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default PlayerAvailability
