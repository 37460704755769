import { CChartBar } from '@coreui/react-chartjs'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import Loader from 'src/components/Loader'
import useChartData from './useChartData'

const BarChart = () => {
  const {
    loading,
    formattedPtData,
    formattedStData,
    formattedRebData,
    formattedAstData,
    formattedBksData,
    formattedTovData,
    formattedThreePData,
    formattedPfsData,
    formattedFpData,
  } = useChartData()

  return loading ? (
    <Loader />
  ) : (
    <CRow>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Point</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedPtData} labels="Points Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Steals</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedStData} labels="Steals Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Rebounds</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedRebData} labels="Rebounds Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Assists</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedAstData} labels="Assists Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Blocks</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedBksData} labels="Blocks Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Turnover</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedTovData} labels="Turnover Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Turnover</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedThreePData} labels="3-Pointers" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Personal Fouls</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedPfsData} labels="Personal Fouls Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol md={6}>
        <CCard className="mb-4">
          <CCardHeader>Fantasy Points</CCardHeader>
          <CCardBody>
            <CCardBody>
              <CChartBar data={formattedFpData} labels="Fantasy Range" />
            </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default BarChart
