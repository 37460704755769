import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import { CButton, CSmartTable } from '@coreui/react-pro'
const PaidUserRequest = () => {
  const [loading1, setLoading1] = useState()
  const [activePage1, setActivePage1] = useState(1)
  const [columnFilter1, setColumnFilter1] = useState([])
  const [columnSorter1, setColumnSorter1] = useState(null)
  const [itemsPerPage1, setItemsPerPage1] = useState(50)
  const [users1, setUsers1] = useState({})
  const columns1 = [
    { label: 'Member', filter: true, sorter: true, key: 'full_name' },
    { label: 'Email', filter: true, sorter: true, key: 'email' },
    { label: 'Date', filter: false, sorter: true, key: 'created_date' },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  useEffect(() => {
    fetchData(activePage1, columnFilter1, columnSorter1, itemsPerPage1)
  }, [activePage1, columnFilter1, columnSorter1, itemsPerPage1])

  const fetchData = (activePage1, columnFilter1, columnSorter1, itemsPerPage1) => {
    const offset = itemsPerPage1 * activePage1 - itemsPerPage1
    let params = new URLSearchParams()
    Object.keys(columnFilter1).forEach((key) => {
      params.append(key, columnFilter1[key])
    })
    columnSorter1 &&
      columnSorter1.column !== undefined &&
      params.append('sort', `${columnSorter1.column}%${columnSorter1.state}`)

    CommonService.getMemberPaidRequest(offset, itemsPerPage1, activePage1, params).then(
      (result) => {
        setUsers1(result.data)
        setLoading1(false)
      },
    )
  }

  const handleApprove = (user_id, status) => {
    CommonService.markToPaidRequest(user_id, status).then((res) => {
      if (res.success) {
        fetchData(activePage1, columnFilter1, columnSorter1, itemsPerPage1)
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <div>
      <CSmartTable
        columns={columns1}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          created_date: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      handleApprove(item.user_id, 1)
                    }}
                  >
                    Approve
                  </CButton>{' '}
                  &nbsp;
                  <CButton
                    color="danger"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      handleApprove(item.user_id, 2)
                    }}
                  >
                    Reject
                  </CButton>
                </td>
              </>
            )
          },
        }}
        items={users1?.data}
        itemsPerPage={itemsPerPage1}
        itemsPerPageSelect
        loading={loading1}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage1,
          pages: Math.ceil(users1?.total / itemsPerPage1) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage1(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage1(1)
          setColumnFilter1(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage1(1)
          setItemsPerPage1(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter1(sorter)}
      />
    </div>
  )
}

export default PaidUserRequest
