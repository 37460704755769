import React, { useEffect, useState } from 'react'
import { CBadge, CSmartTable } from '@coreui/react-pro'

import moment from 'moment'
import CommonService from 'src/service/CommonService'
import { useParams } from 'react-router-dom'

const Table = (props) => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState(props.users)

  const columns = [
    {
      key: 'timeline_id',
      label: '#',
      _style: { width: '3%' },
      filter: false,
      sorter: false,
    },
    {
      key: 'full_name',
      filter: true,
      sorter: true,
    },
    { key: 'team_name', filter: false, sorter: false },
    { key: 'total_like', filter: false, sorter: false },
    { key: `${props.keyName}`, filter: false, sorter: false, label: `${props.typeName}` },
    { key: 'created_at', label: 'Timeline Date', filter: false, sorter: false },
  ]

  const { type } = useParams()

  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    CommonService.getTimelineData(type, offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)

        setLoading(false)
      })
  }, [type, activePage, columnFilter, columnSorter, itemsPerPage, props])

  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
    </>
  )
}

export default Table
