import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import Bootbox from 'bootbox-react'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import RoundService from 'src/service/RoundService'
import { Link } from 'react-router-dom'

const SetGroupPlayOffThird = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    round: Yup.string().required('Select round.'),
  })
  const formik = useFormik({
    initialValues: {
      round: props.playeOffFinal?.round_id,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      data.playOffNumber = 3
      HeadToHeadService.savePlayOffRound(data).then((res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })

  const [showConfirm, setShowConfirm] = useState(false)
  const showCofirmBox = () => {
    setShowConfirm(true)
  }

  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const changeStatus = (type) => {
    const data = {}
    data.roundId = props.playeOffFinal?.round_id
    HeadToHeadService.finalizeGroupPlayOffFinal(data).then((res) => {
      if (res.success) {
        props.fetchData()
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormSelect
            aria-label="select round"
            name="round"
            className={
              'form-control' + (formik.errors.round && formik.touched.round ? ' is-invalid' : '')
            }
            defaultValue={formik.values.round}
            onChange={formik.handleChange}
            id="round"
          >
            <option value={0}>Select Round</option>
            {props.roundData &&
              props.roundData.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.round}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.round && formik.touched.round && (
            <CFormFeedback invalid>{formik.errors.round}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Save
          </CLoadingButton>
        </CCol>
      </CForm>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Matches</strong>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">Team Ranking Based matches</p>

              <CTable striped className="table-responsive">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell className="text-center">Match</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">First Opponent</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Second Opponent</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Winner</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {!props.playOffMatch5 && (
                    <CTableRow>
                      <CTableDataCell className="text-center">Match 5</CTableDataCell>
                      <CTableDataCell className="text-center">
                        {props.playOffMatch3?.winner ?? 'Match 3 Winner'}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {props.playOffMatch4?.winner ?? 'Match 4 Winner'}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">-</CTableDataCell>
                    </CTableRow>
                  )}
                  {props.playOffMatch5 && (
                    <CTableRow>
                      <CTableDataCell className="text-center">Match 4</CTableDataCell>
                      <CTableDataCell className="text-center">
                        {props.playOffMatch5.fm_name} (
                        {props.playOffMatch5.total_points_first_member})
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {' '}
                        {props.playOffMatch5.sm_name} (
                        {props.playOffMatch5.total_points_second_member})
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {' '}
                        {props.playOffMatch5.winner}
                      </CTableDataCell>
                    </CTableRow>
                  )}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
          <Link size="lg" className="btn btn-info btn-sm ms-1" onClick={showCofirmBox}>
            Finalize
          </Link>
        </CCol>
      </CRow>
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to finalize this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default SetGroupPlayOffThird
