import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import BracketBattleService from 'src/service/BracketBattleService'

const BracketBattlePoint = () => {
  const [rounData, setRounData] = useState([])
  const [searchPlayerName, setSearchPlayerName] = useState('')
  const [sortOrder, setSortOrder] = useState('asc') // Initial sorting order
  const [sortColumn, setSortColumn] = useState(null)
  const [playersData, setPlayersData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    BracketBattleService.brackeBattleCumulativePoint(searchPlayerName, sortOrder, currentPage)
      // .then((response) => response.json())
      .then((result) => {
        setPlayersData(result.data)
        setRounData(result.rounData)
      })
  }, [searchPlayerName, sortOrder, currentPage])
  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked, reverse the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      // If a different column is clicked, set the new column and sorting order
      setSortColumn(column)
      setSortOrder('asc') // Default to ascending
    }
  }

  const sortedPlayersData = [...playersData] // Create a copy to avoid modifying the original data

  // Sort the player data based on the selected column and sorting order
  if (sortColumn) {
    sortedPlayersData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.round[sortColumn - 1].points - b.round[sortColumn - 1].points
      } else {
        return b.round[sortColumn - 1].points - a.round[sortColumn - 1].points
      }
    })
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Round Points</strong>
            </CCardHeader>
            <CCardBody>
              <CRow className="mb-3">
                <CCol md={3}>
                  {' '}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by username"
                    value={searchPlayerName}
                    onChange={(e) => setSearchPlayerName(e.target.value)} // Update searchPlayerName state
                  />
                </CCol>
              </CRow>

              <table className="main-table table innertable">
                <thead className="table-light">
                  <tr>
                    <th>Username</th>
                    {rounData.map((item, key1) => (
                      <th key={key1} onClick={() => handleSort(item.round)}>
                        R{item.round}
                        {sortColumn === item.round && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayersData &&
                    sortedPlayersData.map((item, key) => (
                      <tr key={key}>
                        <td>{item.username}</td>
                        {item.round &&
                          item.round.map((item, key1) => <td key={key1}>{item?.points}</td>)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default BracketBattlePoint
