import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import ScorecardService from 'src/service/ScorecardService'
const RevertScorecard = (props) => {
  const param = useParams()
  useEffect(() => {
    ScorecardService.revertScorcard(param.fixtureId).then((res) => {
      if (res.success) {
        ToastComponent(res.message, 'success')
      } else {
        ToastComponent(res.message, 'error')
      }
    })
  }, [param.fixtureId])

  return <>Working on it....</>
}

export default RevertScorecard
