import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import ExamplePdf from '../../assets/MemberJoinCode.csv'
import CommonService from 'src/service/CommonService'
import { Link } from 'react-router-dom'
import PreviewEmailTemplete from './PreviewEmailTemplete'
import GeneralService from 'src/service/GeneralService'

const ImportMemberJoinCode = (props) => {
  const [file, setFile] = useState()
  const [array, setArray] = useState([])
  const fileReader = new FileReader()

  useEffect(() => {
    if (array.length > 0) {
      const MemberCSVData = {}
      MemberCSVData.memberData = array
      CommonService.importGameGuestCode(MemberCSVData).then((res) => {
        if (res.success) {
          props.fetchData()
          ToastComponent(res.message, 'success')
        } else {
          ToastComponent(res.message, 'error')
        }
      })
    }
  }, [array])
  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',')
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i) => {
      const values = i.split(',')
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleImportJoinCodeMembers = (e) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }
      fileReader.readAsText(file)
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  const [templeteDetails, setTempleteDetails] = useState('')
  const handleCloseModal = (data) => {
    setIsOpen(false)
  }
  const previewTemplete = () => {
    GeneralService.getEmailTemplateDetail(72).then((res) => {
      if (res.status === 200) {
        setTempleteDetails(res.data)
      }
    })
    setIsOpen(true)
  }
  return (
    <>
      <CRow className="pt-2">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Bulk Import</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={6}>
                  <CFormInput
                    type="file"
                    id="playerFileId"
                    name="playerFileName"
                    accept={'.csv'}
                    onChange={handleOnChange}
                  />
                </CCol>
                <CCol md={6}>
                  <CLoadingButton
                    color="success"
                    variant="outline"
                    onClick={(e) => {
                      handleImportJoinCodeMembers(e)
                    }}
                  >
                    Run Import
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Sample</strong>
              <br></br>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <p>
                    {' '}
                    <a
                      href={ExamplePdf}
                      download="Member-Join-Code-File"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CButton color={'primary'} variant="outline">
                        Download Sample Member CSV
                      </CButton>
                    </a>{' '}
                    &nbsp;
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        previewTemplete()
                      }}
                    >
                      <CButton color={'primary'} variant="outline">
                        Preview Email Templete
                      </CButton>
                    </Link>
                  </p>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <PreviewEmailTemplete
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal}
        templeteDetails={templeteDetails}
      />
    </>
  )
}

export default ImportMemberJoinCode
