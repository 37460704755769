import React, { useCallback, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import AddForm from './AddForm'
import Table from './Table'
import { useEffect } from 'react'
import TeamService from 'src/service/TeamService'
import ToastComponent from 'src/components/common/TaostComponent'
import ActivateTeam from './ActivateTeam'
const Team = () => {
  const [gradeList, setGradeList] = useState([])
  const [teamCategoryList, setTeamCategoryList] = useState([])
  const [users, setUsers] = useState([])
  const [show, setShow] = useState(false)
  useEffect(() => {
    TeamService.getAddTeamData()
      .then((res) => {
        if (res.status === 200) {
          setGradeList(res.grade_list)
          setTeamCategoryList(res.team_category)
          setShow(res.is_team_activate)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
      })
  }, [])
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const fetchUsers = useCallback(async () => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await TeamService.getTeam(offset, itemsPerPage, activePage, params)
      setUsers(result.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Teams</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm
              gradeList={gradeList}
              teamCategoryList={teamCategoryList}
              fetchUsers={fetchUsers}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Teams</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table
              gradeList={gradeList}
              teamCategoryList={teamCategoryList}
              users={users}
              setUsers={setUsers}
              fetchUsers={fetchUsers}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setColumnFilter={setColumnFilter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Activate Team</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <ActivateTeam show={show} setShow={setShow} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default Team
