import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import BracketBattleService from 'src/service/BracketBattleService'
import ToastComponent from 'src/components/common/TaostComponent'
const TieMatch = (props) => {
  const declareWinner = (user_id, table_id) => {
    const winnerData = {}
    winnerData.user_id = user_id
    winnerData.table_id = table_id
    winnerData.round_id = props.bracketStructure?.id
    BracketBattleService.setWinner(winnerData).then((res) => {
      props.setGetWinners(res.getWinners)
      if (res.getTieMatch.length <= 0) {
        props.setTieMatchedList([])
      }
      ToastComponent(res.message, 'success')
    })
  }
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Round {props.bracketStructure?.round} Tie Match List</strong>
        </CCardHeader>
        <CCardBody>
          <table className="main-table table innertable">
            <thead>
              <tr>
                <th>BATTLE</th>
                <th>USERNAME</th>
                <th>Choose Winner</th>
              </tr>
            </thead>
            <tbody>
              {props.tieMatchList &&
                props.tieMatchList?.map((item, key) => (
                  <tr key={key}>
                    <th>{item?.battle}</th>
                    <td>
                      {item?.fo_full_name} - {item?.so_full_name}
                    </td>
                    <td>
                      <CFormSelect
                        id="first_opponent"
                        name={`data[${key}]first_opponent`}
                        onChange={(e) => declareWinner(e.target.value, item?.id)}
                      >
                        <option value={0}>Select winner</option>
                        <option value={item.first_opponent} key={key}>
                          {item.fo_full_name}
                        </option>
                        <option value={item.second_opponent} key={key}>
                          {item.so_full_name}
                        </option>
                      </CFormSelect>
                    </td>
                  </tr>
                ))}
              {props.tieMatchList.length === 0 && (
                <tr>
                  <td colSpan={3}>No record yet available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCardBody>
      </CCard>
    </>
  )
}

export default TieMatch
