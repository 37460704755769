import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'
const WhatsNew = () => {
  const [cmsData, setCmsData] = useState({})
  useEffect(() => {
    const data = {}
    data.type = 6
    CommonService.getCMSData(data)
      .then((res) => {
        if (res.success) {
          setCmsData(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
        ToastComponent('Something went wrong. Please try again', 'error')
      })
  }, [])
  return (
    <>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>{cmsData?.text1}</CCardHeader>
            <CCardBody>{ReactHtmlParser(cmsData?.text2)} </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default WhatsNew
