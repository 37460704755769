import { CChartLine } from '@coreui/react-chartjs'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import Loader from 'src/components/Loader'
import PlayerService from 'src/service/PlayerService'

const PlayerChart = () => {
  const [labelNames, setLabelNames] = useState([])
  const [playerList, setPlayerList] = useState([])
  const [priceChange, setPriceChange] = useState([])
  const [data, setData] = useState([])

  const [ptData, setPtData] = useState([])
  const [stData, setStData] = useState([])
  const [rebData, setRebData] = useState([])
  const [astData, setAstData] = useState([])
  const [bksData, setBksData] = useState([])
  const [tovData, setTovData] = useState([])
  const [threePData, setThreePData] = useState([])
  const [pfsData, setPfsData] = useState([])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    PlayerService.getAllPlayer().then((res) => {
      setLoading(true)
      if (res.status === 200) {
        setPlayerList(res.data)
        setLoading(false)
      }
    })
  }, [])

  const handleSearchPlayer = (e) => {
    PlayerService.playerChart(e.target.value).then((res) => {
      setLoading(true)
      if (res.status === 200) {
        setData(res.data.datasets)
        setLabelNames(res.data.labels)
        setPriceChange(res.data.priceChange)
        setPtData(res.data.ptData)
        setStData(res.data.stData)
        setRebData(res.data.rebData)
        setAstData(res.data.astData)
        setBksData(res.data.bksData)
        setTovData(res.data.tovData)
        setThreePData(res.data.threePData)
        setPfsData(res.data.pfsData)
        setLoading(false)
      }
    })
  }

  const labelNamesa = Object.keys(labelNames)
  const dataValues = Object.values(data).map((value) => parseFloat(value, 10))
  const dataPriceChangeValues = Object.values(priceChange).map((value) => parseFloat(value, 10))

  const dataPtValues = Object.values(ptData).map((value) => parseInt(value, 10))
  const dataStValues = Object.values(stData).map((value) => parseInt(value, 10))
  const dataRebValues = Object.values(rebData).map((value) => parseInt(value, 10))
  const dataAstValues = Object.values(astData).map((value) => parseInt(value, 10))
  const dataBksValues = Object.values(bksData).map((value) => parseInt(value, 10))
  const dataTovValues = Object.values(tovData).map((value) => parseInt(value, 10))
  const dataThreepValues = Object.values(threePData).map((value) => parseInt(value, 10))
  const dataPfsValues = Object.values(pfsData).map((value) => parseInt(value, 10))

  const formattedData = {
    labels: labelNamesa,
    datasets: [
      {
        label: 'Fantasy Points',
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        pointBackgroundColor: '#36A2EB',
        pointBorderColor: '#36A2EB',
        data: dataValues,
      },
    ],
  }
  const formattedPriceChangeData = {
    labels: labelNamesa,
    datasets: [
      {
        label: 'Fantasy Value',
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        pointBackgroundColor: '#36A2EB',
        pointBorderColor: '#36A2EB',
        data: dataPriceChangeValues,
      },
    ],
  }

  const formattedPtData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Points Per match',
        backgroundColor: '#36A2EB',
        data: dataPtValues,
      },
    ],
  }

  const formattedStData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Steals Match',
        backgroundColor: '#4BC0C0',
        data: dataStValues,
      },
    ],
  }

  const formattedRebData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Rebounds Per Match',
        backgroundColor: '#36A2EB',
        data: dataRebValues,
      },
    ],
  }

  const formattedAstData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Assists Per Match',
        backgroundColor: '#4BC0C0',
        data: dataAstValues,
      },
    ],
  }

  const formattedBksData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Blocks',
        backgroundColor: '#4BC0C0',
        data: dataBksValues,
      },
    ],
  }
  const formattedTovData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Turnovers Per Match',
        backgroundColor: '#4BC0C0',
        data: dataTovValues,
      },
    ],
  }
  const formattedThreepData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: '3-Pointers Per Match',
        backgroundColor: '#4BC0C0',
        data: dataThreepValues,
      },
    ],
  }
  const formattedPfsData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8+ '],
    datasets: [
      {
        label: 'Personal Fouls Per Match',
        backgroundColor: '#4BC0C0',
        data: dataPfsValues,
      },
    ],
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <CRow>
        <CCol xs={12} sm={12} lg={12}>
          <CCard className="mb-4">
            <CCardHeader>Select Player</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={6}>
                  <CFormSelect
                    aria-label="Select player"
                    name="player"
                    className={'form-control'}
                    onChange={handleSearchPlayer}
                    id="player"
                  >
                    <option value={0}>Select Player</option>
                    {playerList &&
                      playerList.map((item, key) => (
                        <option value={item?.id} key={key}>
                          {item?.full_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol md={6}>&nbsp;</CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Fantasy Points</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Fantasy Value</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedPriceChangeData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Point</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedPtData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Steals</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedStData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Rebounds</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedRebData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Assists</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedAstData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Blocks</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedBksData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Turnovers </CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedTovData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By 3-Pointers</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedThreepData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={6} sm={12}>
          <CCard className="mb-4">
            <CCardHeader>By Personal Fouls</CCardHeader>
            <CCardBody>
              <CCardBody>
                <CChartLine data={formattedPfsData} />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default PlayerChart
