import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CForm,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import ToastComponent from 'src/components/common/TaostComponent'
import ClubService from 'src/service/ClubService'
import SponsorService from 'src/service/SponsorService'
import { useMemo } from 'react'
import ReactQuill from 'react-quill'
import { useRef } from 'react'
import { useEffect } from 'react'
const TermCondition = (props) => {
  const [loading, setLoading] = useState(false)
  const [gameDetail, setGameDetail] = useState({})
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    ClubService.getClubDetails()
      .then((res) => {
        if (res.status === 200) {
          setGameDetail(res.game_details)
        } else {
          setLoader(false)
        }
      })
      .catch((e) => {
        setLoader(false)
      })
  }, [])
  const formik = useFormik({
    initialValues: {
      entry_price: gameDetail?.entry_price,
      entry_fee_info: gameDetail?.entry_fee_info,
      message: gameDetail?.message,
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (data) => {
      data.termcondition = props.termcondition
      setLoading(true)
      ClubService.updateTermCondition(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoading(false)
          } else {
            setLoading(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.', 'error')
          setLoading(false)
        })
    },
  })

  // Editor code here.
  const quillRef = useRef()
  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (/^image\//.test(file.type)) {
        const formData = new FormData()
        formData.append('image', file)
        const res = await SponsorService.imageUplaod(formData) // upload data into server or aws or cloudinary
        const url = res?.url
        editor.insertEmbed(editor.getSelection(), 'image', url)
      } else {
        ToastComponent('You could only upload images.', 'error')
      }
    }
  }

  // Finish here

  return (
    <CForm className="row g-3" onSubmit={formik.handleSubmit}>
      <CCol md={12}>
        <CFormLabel className="fw-bold" htmlFor="About Game">
          Terms & Conditions
        </CFormLabel>
        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={props.termcondition}
          onChange={props.setTermCondition}
          // style={{ border: '1px solid' }}
        />
      </CCol>

      <CCol md={6}>
        <CLoadingButton type="submit" color="success" variant="outline" loading={loading}>
          Submit
        </CLoadingButton>
      </CCol>
    </CForm>
  )
}

export default TermCondition
