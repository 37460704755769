import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getFeature = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/features/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const saveFeature = async (data) => {
  const response = await agent.post(API_URL + 'features/save-feature', data)
  return response.data
}
const editFeature = async (data) => {
  const response = await agent.post(API_URL + 'features/edit-feature', data)
  return response.data
}
const getFeatureDetail = async (id) => {
  const response = await agent.get(`${API_URL}features/feature-details/${id}`)
  return response.data
}

const deleteFeature = async (data) => {
  const response = await agent.post(API_URL + 'features/delete-feature', data)
  return response.data
}

const FeatureService = {
  getFeature,
  saveFeature,
  deleteFeature,
  getFeatureDetail,
  editFeature,
}

export default FeatureService
