import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import PlayerService from 'src/service/PlayerService'
import ExamplePdf from '../../assets/PlayerSampleFile.csv'

const ImportPlayer = () => {
  const [file, setFile] = useState()
  const [array, setArray] = useState([])
  const fileReader = new FileReader()

  useEffect(() => {
    if (array.length > 0) {
      const playerCSVData = {}
      playerCSVData.playerData = array
      PlayerService.playerImport(playerCSVData).then((res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
        } else {
          ToastComponent(res.message, 'error')
        }
      })
    }
  }, [array])
  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',')
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i) => {
      const values = i.split(',')
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleImoprtPlayer = (e) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }
      fileReader.readAsText(file)
    }
  }

  return (
    <>
      <CRow className="pt-2">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Player Import</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={6}>
                  <CFormInput
                    type="file"
                    id="playerFileId"
                    name="playerFileName"
                    accept={'.csv'}
                    onChange={handleOnChange}
                  />
                </CCol>
                <CCol md={6}>
                  <CLoadingButton
                    color="success"
                    variant="outline"
                    onClick={(e) => {
                      handleImoprtPlayer(e)
                    }}
                  >
                    Run Import
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Download Sample File</strong>
              <br></br>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <p>
                    Easily add players by using the player import tool. Simply create your player
                    list in a CSV (Comma Separated Values) format using the format defined below.
                    Once your file is ready, upload your CSV file and click {'Run Import'} button.
                  </p>
                  <p>
                    {' '}
                    <a
                      href={ExamplePdf}
                      download="Player-Sample-CSV"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CButton color={'primary'} variant="outline">
                        Download Sample CSV
                      </CButton>
                    </a>
                  </p>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Key Points</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <>
                    <div>
                      <ul>
                        <li>
                          Player Values should not contain a comma (,) or any other special
                          character
                        </li>
                        <li>Player Values are&nbsp;in Millions (i.e. $7.50m)</li>
                        <li>
                          Valid Player Values are:
                          <ul>
                            <li>$6.00m</li>
                            <li>$6.50m</li>
                            <li>$7.00m</li>
                            <li>$7.50m</li>
                            <li>$8.00m</li>
                            <li>$8.50m</li>
                            <li>$9.00m</li>
                            <li>$9.50m</li>
                            <li>$10.00m</li>
                            <li>$10.50m</li>
                            <li>$11.00m</li>
                            <li>$11.50m</li>
                            <li>$12.00m</li>
                            <li>$12.50m</li>
                            <li>$13.00m</li>
                            <li>$13.50m</li>
                            <li>$14.00m</li>
                            <li>$14.50m</li>
                            <li>$15.00m</li>
                          </ul>
                        </li>
                        <li>
                          Valid Player Positions are:
                          <ul>
                            <li>PG</li>
                            <li>SG</li>
                            <li>FWD</li>
                            <li>CEN</li>
                          </ul>
                        </li>
                        <li>
                          Required fields are&nbsp;Firstname, Lastname,&nbsp;Position, Player Value
                        </li>
                        <li>Team Code is an optional field</li>
                        <li>There must be no blank spaces in the 4 required fields</li>
                        <li>
                          Import function will not process in case of error in the information on
                          the import file
                        </li>
                      </ul>

                      <p>
                        Once the Players list has been imported, you can edit and change all player
                        information by going to the Players section (under Games tab).
                      </p>

                      <p>
                        <strong>File Format</strong>
                      </p>

                      <p>
                        HEADINGS REQUIRED in the first row of your CSV file. Each&nbsp;row of your
                        file MUST start with player information in each row (for example below,
                        Columns are separated by a comma (,)):
                      </p>

                      <ul>
                        <li>first_name, last_name, position, value, team_code (optional)</li>
                        <li>Joe, Wills, PG, $14.00m, T00250</li>
                        <li>Alex, Brown, SG, $8.50m, T00251</li>
                      </ul>
                    </div>
                  </>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ImportPlayer
