import {
  CBadge,
  CCol,
  CForm,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CMultiSelect,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CRow,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BracketBattleService from 'src/service/BracketBattleService'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import H2HAllBattle from './H2HAllBattle'
import H2HGroupBattle from './H2HGroupBattle'

const ManageHeadToHeadBattle = () => {
  const [roundListing, setRoundListing] = useState([])
  const [battleType, setBattleType] = useState({})
  const fetchData = () => {
    HeadToHeadService.headToHeadSetting().then((result) => {
      setRoundListing(result.data)
      setBattleType(result.battleType)
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  const [showConfirm, setShowConfirm] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const handleConfirm = () => {
    handleDeleteBracketRound()
    return setShowConfirm(false)
  }
  const showCofirmBox = (recordId, status) => {
    setRecordId(recordId)
    setShowConfirm(true)
  }

  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }
  const handleDeleteBracketRound = () => {
    const data = {}
    data.id = recordId
    BracketBattleService.deleteBracketRound(data).then((result) => {
      ToastComponent(result.message, 'success')
      fetchData()
    })
  }

  return (
    <>
      <CRow>
        <CCol xs={12} md={12}>
          {battleType?.type === 'all' && (
            <H2HAllBattle fetchData={fetchData} roundListing={roundListing} />
          )}
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} md={12} className="mt-2">
          {battleType?.type === 'group' && (
            <H2HGroupBattle fetchData={fetchData} roundListing={roundListing} />
          )}
        </CCol>
      </CRow>
    </>
  )
}

export default ManageHeadToHeadBattle
