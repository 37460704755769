import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import ScorecardService from 'src/service/ScorecardService'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageGallary from '../ImageGallary/ImageGallary'
const ManageScorcard = () => {
  const [fixtureDetails, setFixtureDetails] = useState({})
  const [loader, setLoader] = useState(false)
  const param = useParams()
  const [fallWicketValue, setFallWicketValue] = useState()
  const [matchReportValue, setMatchReportValue] = useState()
  useEffect(() => {
    setLoader(true)
    ScorecardService.manageScorecard(param.fixtureId)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          setFixtureDetails(res.data)
          setFallWicketValue(res.data?.fall_of_wickets)
          setMatchReportValue(res.data?.match_report)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong. Please try again.', 'error')
        setLoader(false)
      })
    setLoader(false)
  }, [param.fixtureId])
  const [imgArray, setImgArray] = useState([])
  const formik = useFormik({
    initialValues: {
      scorcard_link_name: fixtureDetails?.scorcard_link_name,
      scorcard_link_url: fixtureDetails?.scorcard_link_url,
      fall_of_wickets: fixtureDetails?.fall_of_wickets,
      match_report: fixtureDetails?.match_report,
    },
    enableReinitialize: true,
    //validationSchema,
    onSubmit: (data) => {
      console.log(imgArray)
      var formData = new FormData()
      Array.from(imgArray).forEach((image) => {
        formData.append('player_card[]', image)
      })
      formData.append('scorcard_link_name', data.scorcard_link_name ?? '')
      formData.append('scorcard_link_url', data.scorcard_link_url ?? '')
      formData.append('fixtureId', param.fixtureId)
      formData.append('fall_of_wickets', fallWicketValue)
      formData.append('match_report', matchReportValue)
      setLoader(true)
      ScorecardService.manageUpdateScorecard(formData)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          console.log('Here is Error', e)
          ToastComponent('Something Went wront. Please try again.', 'error')
        })
    },
  })
  const quillRef = useRef()
  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor()
    console.log(editor)
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (/^image\//.test(file.type)) {
        const formData = new FormData()
        formData.append('image', file)
        const res = await ScorecardService.imageUplaod(formData) // upload data into server or aws or cloudinary
        const url = res?.url
        editor.insertEmbed(editor.getSelection(), 'image', url)
      } else {
        ToastComponent('You could only upload images.', 'error')
      }
    }
  }
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
          [
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  )

  const [images, setImages] = useState([])

  const handleMultipleImages = (evnt) => {
    const selectedFIles = []
    const selectedUploadFIles = []
    const targetFiles = evnt.target.files
    const targetFilesObject = [...targetFiles]
    const targetFilesUploadObject = [...targetFiles]
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file))
    })
    setImages(selectedFIles)
    targetFilesUploadObject.map((file, i) => {
      return selectedUploadFIles.push(file)
    })
    setImgArray(selectedUploadFIles)
  }
  return (
    <>
      <CRow>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> Round</b>: {fixtureDetails.round}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> Team</b>: {fixtureDetails.team_name}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            {' '}
            <b> Start Date/Time</b>: {moment(fixtureDetails.start_date).format('D.MM.YYYY')}
            {', '}
            {moment(fixtureDetails.start_time, ['HH:mm']).format('h:mma')}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> End Date/Time</b>: {moment(fixtureDetails.end_date).format('D.MM.YYYY')}
            {', '}
            {moment(fixtureDetails.end_time, ['HH:mm']).format('h:mma')}
          </span>
        </CCol>

        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CRow>
                <CCol xs={10}>
                  <strong>Manage Scorecard</strong>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                <CCol md={6}>
                  <CFormLabel htmlFor="scorcard_link_name">Scorecard Source</CFormLabel>
                  <CFormInput
                    placeholder="Scorecard Source"
                    className={
                      'form-control' +
                      (formik.errors.scorcard_link_name && formik.touched.scorcard_link_name
                        ? ' is-invalid'
                        : '')
                    }
                    defaultValue={formik.values.scorcard_link_name}
                    onChange={formik.handleChange}
                    aria-label="scorcard_link_name"
                    id="scorcard_link_name"
                  />
                  {formik.errors.scorcard_link_name && formik.touched.scorcard_link_name && (
                    <CFormFeedback invalid>{formik.errors.scorcard_link_name}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel htmlFor="scorcard_link_url">Scorecard Link</CFormLabel>
                  <CFormInput
                    placeholder="Scorecard Link"
                    className={
                      'form-control' +
                      (formik.errors.scorcard_link_url && formik.touched.scorcard_link_url
                        ? ' is-invalid'
                        : '')
                    }
                    defaultValue={formik.values.scorcard_link_url}
                    onChange={formik.handleChange}
                    aria-label="scorcard_link_url"
                    id="scorcard_link_url"
                  />
                  {formik.errors.scorcard_link_url && formik.touched.scorcard_link_url && (
                    <CFormFeedback invalid>{formik.errors.scorcard_link_url}</CFormFeedback>
                  )}
                </CCol>

                <CCol md={6}>
                  <CLoadingButton
                    type="submit"
                    color="success"
                    variant="outline"
                    loading={loader}
                    id="submit"
                  >
                    Submit
                  </CLoadingButton>
                </CCol>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ManageScorcard
