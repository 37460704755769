import React, { useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import FeatureService from 'src/service/FeatureService'
import EditForm from './EditForm'
import Notify from '../Notify'
const Table = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [details, setDetails] = useState([])
  const [selectedFeatureId, setSelectedFeatureId] = useState(0)

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 2:
        return 'primary'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedFeatureId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const deleteFeature = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    FeatureService.deleteFeature(data).then((res) => {
      if (res.status === 200) {
        props.fetchData(
          props.activePage,
          props.columnFilter,
          props.columnSorter,
          props.itemsPerPage,
        )
        ToastComponent(res.message, 'success')
        setLoading(false)
      }
    })
  }

  // Are you sure want modal
  const [handleYes, setHandleYes] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)
  const [tableId, setTableId] = useState(false)
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const handleConfirm = () => {
    deleteFeature(tableId)
    return setShowConfirm(false)
  }

  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          type: (item) => (
            <td>
              {item.type === 1 && <CBadge color={'success'}> Admin</CBadge>}
              {item.type === 2 && <CBadge color={'primary'}> Member</CBadge>}
              {item.type === 3 && <CBadge color={'danger'}>What&apos;s New</CBadge>}
              {item.type === 4 && <CBadge color={'warning'}>Press Release</CBadge>}
              {item.type === 5 && <CBadge color={'info'}>Tour</CBadge>}
            </td>
          ),
          short_description: (item) => <td>{ReactHtmlParser(item.short_description)}</td>,
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(item.id)
                    }}
                  >
                    {details.includes(item.id) ? 'Hide' : 'Manage'}
                  </CButton>
                </td>
              </>
            )
          },
          details: (item) => {
            return (
              <CCollapse visible={details.includes(item.id)}>
                <CCardBody>
                  <CButton
                    size="sm"
                    color="success"
                    className="ml-1"
                    onClick={() => setVisibleHorizontal(!visibleHorizontal)}
                    aria-expanded={visibleHorizontal}
                    aria-controls={`collapseEdit-${item.id}`}
                  >
                    Edit
                  </CButton>

                  <CCollapse id={`collapseEdit-${item.id}`} horizontal visible={visibleHorizontal}>
                    <CCard className="mb-4">
                      <CCardHeader>
                        <strong>Edit Template</strong>
                      </CCardHeader>
                      <CCardBody>
                        <EditForm
                          featureId={item.id}
                          activePage={props.activePage}
                          setActivePage={props.setActivePage}
                          columnFilter={props.columnFilter}
                          setColumnFilter={props.setColumnFilter}
                          columnSorter={props.columnSorter}
                          setColumnSorter={props.setColumnSorter}
                          itemsPerPage={props.itemsPerPage}
                          setItemsPerPage={props.setItemsPerPage}
                          selectedFeatureId={selectedFeatureId}
                          fetchData={props.fetchData}
                        />
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </CCardBody>
              </CCollapse>
            )
          },
        }}
        items={props.users?.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleYes={handleYes}
        setHandleYes={setHandleYes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are you sure you want to delete this?"
      />
    </>
  )
}

export default Table
