import React, { useState } from 'react'
import moment from 'moment'
import { CBadge, CButton, CSmartTable } from '@coreui/react-pro'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import Notify from '../Notify'
const MemberCodeListing = (props) => {
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  const sendCode = (id) => {
    CommonService.sendInviteCode(id).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        props.fetchData()
      }
    })
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)
  const [handleYes, setHandleYes] = useState(false)
  const [tableId, setTableId] = useState(false)
  const [status, setStatus] = useState(false)
  const handleAreYouSureStatus = (id, status) => {
    setTableId(id)
    setStatus(status)
    setShowConfirm(true)
  }
  const handleConfirm = () => {
    CommonService.changeGameCodeStatus(tableId, status).then((res) => {
      if (res.status === 200) {
        props.fetchData()
        ToastComponent(res.message, 'success')
      }
    })
    return setShowConfirm(false)
  }

  const handleCancel = () => {
    return setShowConfirm(false)
  }
  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          created_at: (item) => <td>{moment(item.updated_at).format('D.MM.YYYY')}</td>,
          is_sent: (item) => (
            <td>
              <CBadge color={getBadge(item.is_sent)}> {item.is_sent === 1 ? 'Yes' : 'No'}</CBadge>
            </td>
          ),
          actions: (item) => (
            <td>
              {item?.is_sent === 0 && (
                <CButton color={'success'} size="sm" onClick={() => sendCode(item.id)}>
                  Send
                </CButton>
              )}
              {item?.is_sent === 1 && (
                <CButton color={'success'} size="sm" onClick={() => sendCode(item.id)}>
                  Re-send
                </CButton>
              )}
              {item?.is_sent === 2 && (
                <CButton
                  color={'success'}
                  size="sm"
                  // onClick={() => props.handleDeleteRequest(item.id)}
                >
                  Joined
                </CButton>
              )}
              {item.is_active === 1 ? (
                <CButton
                  size="sm"
                  color="danger"
                  className="ml-1"
                  onClick={() => handleAreYouSureStatus(item.id, 0)}
                >
                  Deactivate
                </CButton>
              ) : (
                <CButton
                  size="sm"
                  color="success"
                  className="ml-1"
                  onClick={() => handleAreYouSureStatus(item.id, 1)}
                >
                  Activate
                </CButton>
              )}
            </td>
          ),
        }}
        items={props.users.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect={false}
        loading={props.loading}
        pagination={true}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleYes={handleYes}
        setHandleYes={setHandleYes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are You Sure Want to Change Status"
      />
    </>
  )
}

export default MemberCodeListing
