import {
  CCol,
  CForm,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    round: Yup.string().required('Round is required'),
    first_member: Yup.string().required('First Member is required'),
    second_member: Yup.string().required('Second Member is required'),
  })
  const formik = useFormik({
    initialValues: {
      round: '',
      first_member: '',
      second_member: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      HeadToHeadService.saveHeadToHead(data).then((res) => {
        if (res.success) {
          props.setUsers(res.data)
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="position">
            Select Round *
          </CFormLabel>
          <CFormSelect
            aria-label="select position"
            name="round"
            className={
              'form-control' + (formik.errors.round && formik.touched.round ? ' is-invalid' : '')
            }
            defaultValue={formik.values.round}
            onChange={formik.handleChange}
            id="round"
          >
            <option value={0}>Select Round</option>
            {props.roundList &&
              props.roundList.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.round}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.round && formik.touched.round && (
            <CFormFeedback invalid>{formik.errors.round}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="position">
            First Member *
          </CFormLabel>
          <CFormSelect
            aria-label="select position"
            name="first_member"
            className={
              'form-control' +
              (formik.errors.first_member && formik.touched.first_member ? ' is-invalid' : '')
            }
            defaultValue={formik.values.first_member}
            onChange={formik.handleChange}
            id="first_member"
          >
            <option value={0}>Select First Member</option>
            {props.userList &&
              props.userList.map((item, key) => (
                <option value={item?.user_id} key={key}>
                  {item?.username}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.first_member && formik.touched.first_member && (
            <CFormFeedback invalid>{formik.errors.first_member}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="position">
            Second Member *
          </CFormLabel>
          <CFormSelect
            aria-label="select position"
            name="second_member"
            className={
              'form-control' +
              (formik.errors.second_member && formik.touched.second_member ? ' is-invalid' : '')
            }
            defaultValue={formik.values.second_member}
            onChange={formik.handleChange}
            id="second_member"
          >
            <option value={0}>Select Second Member</option>
            {props.userList &&
              props.userList.map((item, key) => (
                <option value={item?.user_id} key={key}>
                  {item?.username}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.second_member && formik.touched.second_member && (
            <CFormFeedback invalid>{formik.errors.second_member}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Save
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
