import axios from 'axios'
import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL

const roundListing = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(`${API_URL}/common/bonus-cards`)
  return response.data
}
const clubPlayers = async () => {
  const response = await agent.get(`${API_URL}/common/club-players`)
  return response.data
}

const saveBonusCard = async (data) => {
  const response = await agent.post(API_URL + 'common/save-bonus-card', data)
  return response.data
}
const saveBonusCardPlayerPoint = async (data) => {
  const response = await agent.post(API_URL + 'common/save-bonus-card-player-point', data)
  return response.data
}

const editPowerControl = async (data) => {
  const response = await agent.post(API_URL + 'common/edit-power-control', data)
  return response.data
}

const bonusCardPlayer = async (teamPowerId, roundId) => {
  const response = await agent.get(`${API_URL}common/bonus-card-players/${teamPowerId}/${roundId}`)
  return response.data
}

const bonusCardSelectedPlayer = async (roundNumber) => {
  const response = await agent.get(`${API_URL}common/bonus-card-selected-players/${roundNumber}`)
  return response.data
}

const getBonusCardDetail = async (roundNumber) => {
  const response = await agent.get(`${API_URL}common/get-bonus-card-details/${roundNumber}`)
  return response.data
}

const powerControl = async () => {
  const response = await agent.get(`${API_URL}power-control`)
  return response.data
}
const updateGameSpot = async (data) => {
  const response = await agent.post(API_URL + 'common/update-game-spot', data)
  return response.data
}

const updateTrade = async (data) => {
  const response = await agent.post(API_URL + 'common/update-trades', data)
  return response.data
}

const branding = async () => {
  const response = await agent.get(`${API_URL}common/branding`)
  return response.data
}
const editBranding = async (data) => {
  const response = await agent.post(API_URL + 'common/edit-branding', data)
  return response.data
}
const updateShowBranding = async (data) => {
  const response = await agent.post(API_URL + 'common/updateShowBranding', data)
  return response.data
}

const changePowerControlStatus = async (data) => {
  const response = await agent.post(API_URL + 'common/change-power-control-status', data)
  return response.data
}

const updateBonusPoint = async (data) => {
  const response = await agent.post(API_URL + 'common/update-bonus-point', data)
  return response.data
}

const getBonusPoint = async () => {
  const response = await agent.get(`${API_URL}common/get-bonus-point`)
  return response.data
}

const getVerifyUser = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}common/verify-users?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getMemberPaidRequest = async (
  offset = '',
  itemsPerPage = '',
  activePage = '',
  params = '',
) => {
  const response = await agent.get(
    `${API_URL}common/memberPaidRequest?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getJoinCodeMember = async (offset = '', itemsPerPage = '', activePage = '', params = '') => {
  const response = await agent.get(
    `${API_URL}common/getJoinCodeMember?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}

const saveVerifyUser = async (data) => {
  const response = await agent.post(API_URL + 'common/save-verify-user', data)
  return response.data
}
const saveJoinCodeMember = async (data) => {
  const response = await agent.post(API_URL + 'common/saveJoinCodeMember', data)
  return response.data
}
const importGameGuestCode = async (data) => {
  const response = await agent.post(API_URL + 'common/importGameGuestCode', data)
  return response.data
}
const sendInviteCode = async (id) => {
  const response = await agent.get(API_URL + 'common/sendInviteCode/' + id)
  return response.data
}

const getTotwData = async () => {
  const response = await agent.get(API_URL + 'common/get-totw-listing')
  return response.data
}
const saveTotw = async (data) => {
  const response = await agent.post(API_URL + 'common/save-totw', data)
  return response.data
}

const totwSelectedPlayer = async (roundNumber) => {
  const response = await agent.get(`${API_URL}common/totw-selected-players/${roundNumber}`)
  return response.data
}
const totwPlayerList = async (round) => {
  const response = await agent.get(`${API_URL}common/totw-player-list/${round}`)
  return response.data
}
const updateTotwPlayerPoint = async (data) => {
  const response = await agent.post(API_URL + 'common/update-totw-player-points', data)
  return response.data
}

const editGameStructure = async (data) => {
  const response = await agent.post(API_URL + 'common/edit-game-structure', data)
  return response.data
}
const deleteVerifyUser = async (data) => {
  const response = await agent.post(API_URL + 'common/delete-verify-user', data)
  return response.data
}
const gameStructureInfo = async () => {
  const response = await agent.get(API_URL + 'common/get-game-structure-info')
  return response.data
}
const updateGamePrivacy = async (data) => {
  const response = await agent.post(API_URL + 'common/update-game-privacy', data)
  return response.data
}
const updatePlayerAlert = async (data) => {
  const response = await agent.post(API_URL + 'common/updatePlayerAlert', data)
  return response.data
}
const getGameActivateInfo = async (type) => {
  const response = await agent.get(API_URL + 'common/getGameActivateInfo?type=' + type)
  return response.data
}
const activateGame = async (data) => {
  const response = await agent.post(API_URL + 'common/activateGame', data)
  return response.data
}
const getCMSData = async (data) => {
  const response = await agent.post(API_URL + 'common/getCMSData', data)
  return response.data
}
const updateSetFantasyValueLimit = async (data) => {
  const response = await agent.post(API_URL + 'updateSetFantasyValueLimit', data)
  return response.data
}
const updateDefaultSalarySymbol = async (data) => {
  const response = await agent.post(API_URL + 'updateDefaultSalarySymbol', data)
  return response.data
}

const gameAccount = async (
  offset,
  itemsPerPage,
  activePage,
  params,
  runningActive,
  completedActive,
) => {
  const response = await agent.get(
    `${API_URL}/common/gameAccount?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&running_game=${runningActive}&complete_game=${completedActive}&${params}`,
  )
  return response.data
}
const dashboardData = async () => {
  const response = await agent.get(API_URL + 'common/dashboardData')
  return response.data
}
const getGameList = async () => {
  const response = await agent.get(API_URL + 'common/getGameList')
  return response.data
}
const getTop5Player = async () => {
  const response = await agent.get(API_URL + 'common/getTop5Player')
  return response.data
}
const getFantasyScoreTotal = async () => {
  const response = await agent.get(API_URL + 'common/getFantasyScoreTotal')
  return response.data
}
const dashboardUser = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/common/dashboardUser?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}

const gameLogin = async (data) => {
  const response = await axios.post(API_URL + 'gameLogin', data)
  return response.data
}
const checkItemExists = async (data) => {
  const response = await agent.post(`${API_URL}common/checkItemExists`, data)
  return response.data
}
const savePayment = async (data) => {
  const response = await agent.post(API_URL + 'savePayment', data)
  return response.data
}
const getHeaderInfoDetails = async (pathName) => {
  const response = await agent.get(API_URL + 'getHeaderInfoDetails/' + pathName)
  return response.data
}
const getHeaderInfoDetailsFromPathName = async (pathName) => {
  const encodedPathName = encodeURIComponent(pathName)
  const response = await agent.get(
    API_URL + `getHeaderInfoDetailsFromPathName?page_name=${encodedPathName}`,
  )
  return response.data
}
const getHeaderInfoDetailsAndRead = async (pathName) => {
  const response = await agent.get(API_URL + 'getHeaderInfoDetailsAndRead/' + pathName)
  return response.data
}
const getGameName = async (uri) => {
  const encodedPathName = encodeURIComponent(uri)
  const response = await agent.get(API_URL + `getGameName?page_name=${encodedPathName}`)
  return response.data
}
const saveHeaderInfo = async (data) => {
  const response = await agent.post(API_URL + 'saveHeaderInfo', data)
  return response.data
}
const editHeaderInfo = async (data) => {
  const response = await agent.post(API_URL + 'editHeaderInfo', data)
  return response.data
}
const deleteHeaderInfoText = async (data) => {
  const response = await agent.post(API_URL + '/deleteHeaderInfoText', data)
  return response.data
}

const getHeaderIinfo = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/getHeaderInfoListing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getInfoTextListing = async () => {
  const response = await agent.get(`${API_URL}/getInfoTextListing`)
  return response.data
}
const getTimelineData = async (timelineType, offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/getTimelineData/${timelineType}?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getTimelineSetting = async () => {
  const response = await agent.get(API_URL + 'timelineSetting')
  return response.data
}
const setTimelineSetting = async (data) => {
  const response = await agent.post(API_URL + 'timelineSetting', data)
  return response.data
}
const verifyPlayerRequest = async (data) => {
  const response = await agent.post(API_URL + 'players/verify-player-request', data)
  return response.data
}
const revertBonusCard = async (data) => {
  const response = await agent.post(API_URL + 'revertBonusCard', data)
  return response.data
}
const revertTotw = async (data) => {
  const response = await agent.post(API_URL + 'revertTotw', data)
  return response.data
}
const getBonusCardActivation = async (fixtureId, userId) => {
  const response = await agent.get(`${API_URL}fixture-voting/getBonusCardActivation`)
  return response.data
}
const changeBonusCardStatus = async (status) => {
  const response = await agent.get(`${API_URL}fixture-voting/changeBonusCardStatus/${status}`)
  return response.data
}
const usedPowerHistory = async () => {
  const response = await agent.get(`${API_URL}usedPowerHistory`)
  return response.data
}
const getCatCounts = async (headerText) => {
  const params = new URLSearchParams()
  headerText.forEach((header) => {
    params.append('header_text[]', header)
  })

  try {
    const response = await agent.get(`${API_URL}getCatCounts`, { params })
    return response.data
  } catch (error) {
    // Handle error
    console.error('Error fetching category counts:', error)
    throw error
  }
}
const getGameType = async () => {
  const response = await agent.get(`${API_URL}getGameType`)
  return response.data
}

const changeGameCodeStatus = async (id, status) => {
  const response = await agent.get(`${API_URL}common/changeGameCodeStatus/${id}/${status}`)
  return response.data
}
const markToPaidRequest = async (id, status) => {
  const response = await agent.get(`${API_URL}common/markToPaidRequest/${id}/${status}`)
  return response.data
}

const getEmailNotficationSetting = async () => {
  const response = await agent.get(`${API_URL}getEmailNotficationSetting`)
  return response.data
}
const saveEmailNotificationSetting = async (data) => {
  const response = await agent.post(`${API_URL}saveEmailNotificationSetting`, data)
  return response.data
}
const changeEmailNotificationEnableDisable = async (data) => {
  const response = await agent.post(`${API_URL}/changeEmailNotificationEnableDisable`, data)
  return response.data
}
const copyGameSettingFromType = async (formData) => {
  const response = await agent.post(`${API_URL}/copyGameSettingFromType`, formData)
  return response.data
}

const getBrandingViews = async () => {
  const response = await agent.get(API_URL + 'getBrandingViews')
  return response.data
}
const getSponsorClickViews = async () => {
  const response = await agent.get(API_URL + 'getSponsorClickViews')
  return response.data
}
const saveCopyBranding = async (data) => {
  const response = await agent.post(API_URL + 'saveCopyBranding', data)
  return response.data
}
const saveCopySponsor = async (data) => {
  const response = await agent.post(API_URL + 'saveCopySponsor', data)
  return response.data
}
const updateThemeSetting = async (data) => {
  const response = await agent.post(API_URL + 'updateThemeSetting', data)
  return response.data
}

const getThemeSettingData = async (clubId) => {
  const response = await agent.get(API_URL + 'getThemeSettingData?clubId=' + clubId)
  return response.data
}
const updateHeaderThemeSetting = async (data) => {
  const response = await agent.post(API_URL + 'updateHeaderThemeSetting', data)
  return response.data
}
const updateSideBarThemeSetting = async (data) => {
  const response = await agent.post(API_URL + 'updateSideBarThemeSetting', data)
  return response.data
}
const updateTeamSelectionLimit = async (data) => {
  const response = await agent.post(API_URL + 'updateTeamSelectionLimit', data)
  return response.data
}
const getGamesTeamSize = async (gameId) => {
  const response = await agent.get(API_URL + 'common/getGamesTeamSize?game_id=' + gameId)
  return response.data
}
const getGameProData = async (gameId) => {
  const response = await agent.get(API_URL + 'common/getGameProData?game_id=' + gameId)
  return response.data
}
const getMctTop5Player = async () => {
  const response = await agent.get(API_URL + 'common/getMctTop5Player')
  return response.data
}
const mctDashboardData = async () => {
  const response = await agent.get(API_URL + 'common/mctDashboardData')
  return response.data
}

const CommonService = {
  saveCopyBranding,
  saveCopySponsor,
  getBrandingViews,
  getSponsorClickViews,
  copyGameSettingFromType,
  getTimelineData,
  getHeaderIinfo,
  getGameName,
  getCMSData,
  deleteHeaderInfoText,
  saveHeaderInfo,
  roundListing,
  bonusCardPlayer,
  saveBonusCard,
  saveBonusCardPlayerPoint,
  clubPlayers,
  powerControl,
  editPowerControl,
  changePowerControlStatus,
  updateGameSpot,
  updateTrade,
  branding,
  editBranding,
  getBonusPoint,
  updateBonusPoint,
  getVerifyUser,
  getJoinCodeMember,
  saveVerifyUser,
  getBonusCardDetail,
  bonusCardSelectedPlayer,
  getTotwData,
  saveTotw,
  totwSelectedPlayer,
  totwPlayerList,
  updateTotwPlayerPoint,
  editGameStructure,
  getGameActivateInfo,
  gameStructureInfo,
  deleteVerifyUser,
  updateGamePrivacy,
  updatePlayerAlert,
  activateGame,
  gameAccount,
  getGameList,
  dashboardData,
  dashboardUser,
  gameLogin,
  checkItemExists,
  savePayment,
  getHeaderInfoDetails,
  getTimelineSetting,
  setTimelineSetting,
  verifyPlayerRequest,
  updateSetFantasyValueLimit,
  revertBonusCard,
  revertTotw,
  getBonusCardActivation,
  changeBonusCardStatus,
  getTop5Player,
  getFantasyScoreTotal,
  usedPowerHistory,
  editHeaderInfo,
  getInfoTextListing,
  getHeaderInfoDetailsAndRead,
  getHeaderInfoDetailsFromPathName,
  getCatCounts,
  updateDefaultSalarySymbol,
  getGameType,
  saveJoinCodeMember,
  importGameGuestCode,
  sendInviteCode,
  changeGameCodeStatus,
  updateShowBranding,
  markToPaidRequest,
  getMemberPaidRequest,
  saveEmailNotificationSetting,
  changeEmailNotificationEnableDisable,
  getEmailNotficationSetting,
  getThemeSettingData,
  updateThemeSetting,
  updateHeaderThemeSetting,
  updateSideBarThemeSetting,
  updateTeamSelectionLimit,
  getGamesTeamSize,
  getGameProData,
  mctDashboardData,
  getMctTop5Player,
}

export default CommonService
