import React, { useState, useEffect } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import PlayerService from 'src/service/PlayerService'
import PlayerClaimRequest from './PlayerClaimRequest'
import AddVerifyPlayer from './AddVerifyPlayer'
import ManageVerifiedPlayer from './ManageVerifiedPlayer'
import CommonService from 'src/service/CommonService'
const PlayerClaimProfile = () => {
  const [requestList, setRequestList] = useState([])
  const [userList, setUserList] = useState([])
  const [playerList, setPlayerList] = useState([])

  const fetchData = () => {
    PlayerService.playerProfileClaimListing()
      .then((res) => {
        if (res.status === 200) {
          setRequestList(res.data)
          setUserList(res.userList)
          setPlayerList(res.playerlist)
        }
      })
      .catch((e) => {
        console.log('Error =>', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Get player listing
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [users, setUsers] = useState({})
  const columns = [
    { label: 'User Account', filter: true, sorter: true, key: 'username' },
    { label: 'Player Account', filter: false, sorter: true, key: 'player_name' },
    { label: 'Approved', filter: false, sorter: true, key: 'created_at' },
    {
      key: 'actions',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  useEffect(() => {
    fetchPlayerData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchPlayerData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    CommonService.getVerifyUser(offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
    setLoading(true)
  }

  const handleDeleteRequest = (tableId) => {
    const data = {}
    data.id = tableId
    CommonService.deleteVerifyUser(data).then((res) => {
      if (res.status === 200) {
        fetchPlayerData(activePage, columnFilter, columnSorter, itemsPerPage)
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Player Verification Requests</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <PlayerClaimRequest
              setRequestList={setRequestList}
              fetchData={fetchData}
              requestList={requestList}
              fetchPlayerData={fetchPlayerData}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
              itemsPerPage={itemsPerPage}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={2}>
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>
            {' '}
            <strong>Verify as Player</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddVerifyPlayer
              userList={userList}
              playerList={playerList}
              fetchPlayerData={fetchPlayerData}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
              itemsPerPage={itemsPerPage}
              fetchData={fetchData}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={2}>
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Verified Player</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <ManageVerifiedPlayer
              users={users}
              columns={columns}
              itemsPerPage={itemsPerPage}
              loading={loading}
              activePage={activePage}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setColumnSorter={setColumnSorter}
              setColumnFilter={setColumnFilter}
              handleDeleteRequest={handleDeleteRequest}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default PlayerClaimProfile
