import React from 'react'
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro'
import '../../assets/css/stripe_css.css'
import Stripe from '../Stripe/Stripe'
const PaymentModal = (props) => {
  return (
    <>
      <CModal fullscreen="lg" visible={props.visible} onClose={() => props.setVisible(false)}>
        <CModalHeader>
          <CModalTitle>Payment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {' '}
          <Stripe
            setVisible={props.setVisible}
            activationInfo={props.activationInfo}
            setActivationInfo={props.setActivationInfo}
            amount={props.amount}
            type={props.type}
            noOfFixture={props.noOfFixture}
          />
        </CModalBody>
      </CModal>
    </>
  )
}

export default PaymentModal
