import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getShowCase = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/show-case/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const saveShowCase = async (data) => {
  const response = await agent.post(API_URL + 'show-case/saveShowCase', data)
  return response.data
}
const editShowCase = async (data) => {
  const response = await agent.post(API_URL + 'show-case/editShowCase', data)
  return response.data
}
const getShowCaseDetail = async (id) => {
  const response = await agent.get(`${API_URL}show-case/getShowCaseDetail/${id}`)
  return response.data
}

const deleteShowCase = async (data) => {
  const response = await agent.post(API_URL + 'show-case/deleteShowCase', data)
  return response.data
}

const ShowCaseService = {
  getShowCase,
  saveShowCase,
  deleteShowCase,
  getShowCaseDetail,
  editShowCase,
}

export default ShowCaseService
