import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useEffect } from 'react'
import HeadToHeadService from 'src/service/HeadToHeadService'
import RoundResult from './RoundResult'
import { useParams } from 'react-router-dom'
const HeadToHeadAction = () => {
  const param = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (param.type) {
      HeadToHeadService.showGroupHeadToHeadRound(param.type, param.roundId).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          setData(res.h2hData)
        }
      })
    } else {
      HeadToHeadService.showHeadToHeadRound(param.roundId).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          setData(res.h2hData)
        }
      })
    }
  }, [])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>H2H Round Results</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <RoundResult data={data} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default HeadToHeadAction
