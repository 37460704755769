import React, { useEffect, useState } from 'react'
import {
  CFormLabel,
  CForm,
  CLoadingButton,
  CRow,
  CCol,
  CFormSelect,
  CListGroup,
  CListGroupItem,
  CBadge,
  CAccordion,
  CAccordionItem,
  CAccordionBody,
  CAccordionHeader,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse,
  CButton,
  CFormInput,
  CFormCheck,
  CCardTitle,
  CCardText,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import 'react-input-range/lib/css/index.css'
import CIcon from '@coreui/icons-react'
import { cilCheck, cilX } from '@coreui/icons'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Stripe from '../Stripe/Stripe'
import PaymentModal from './PaymentModal'
const ActivateGame = (props) => {
  const [loader, setLoader] = useState(false)
  const [disabledBtn, setdisabledBtn] = useState(true)
  const [gameInfo, setGameInfo] = useState({})
  const [roundInfo, setRoundInfo] = useState({})
  const [activateGameText, setActivateGameText] = useState('')
  const [activationInfo, setActivationInfo] = useState({})
  const [amount, setAmount] = useState(99)
  const [partnerPlanPrice, setPartnerPlanPrice] = useState()
  const [showStarterPlan, setShowStarterPlan] = useState(true)
  const [showPartnerPlan, setShowPartnerPlan] = useState(true)
  const [saveButton, setSaveButton] = useState(true)
  useEffect(() => {
    CommonService.getGameActivateInfo(1)
      .then((res) => {
        if (res.status === 200) {
          setGameInfo(res.game_info)
          setRoundInfo(res.round_info)
          setActivationInfo(res.getGameActivationInfo)
          if (
            (res.getGameActivationInfo || res.getGameActivationInfo?.type === 1) &&
            res.getGameActivationInfo?.is_paid === 1
          ) {
            setShowStarterPlan(false)
          }
          if (res.getGameActivationInfo || res.getGameActivationInfo?.type === 2) {
            setShowPartnerPlan(false)
          }
          if (!res.getGameActivationInfo) {
            setShowStarterPlan(true)
            setShowPartnerPlan(true)
          }
          if (
            res.game_info?.game_name &&
            res.game_info?.game_name &&
            res.game_info?.timezone &&
            res?.round_info &&
            res?.getGameActivationInfo
          ) {
            setdisabledBtn(false)
            setActivateGameText('Activate Game')
          }
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }, [activateGameText])
  const navigate = useNavigate()
  const activateGame = (id, gameStatus) => {
    setLoader(true)
    const data = {}
    data.gameStatus = gameStatus
    data.gameId = id
    CommonService.activateGame(data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.game_status === 0) {
            setActivateGameText('Deactivate Game')
            console.log('activated')
          }
          if (res.data.game_status === 1) {
            setActivateGameText('Activate Game')
            console.log('Deactivate')
          }
          window.location.href = '/activate-game'
          setLoader(false)
          ToastComponent(res.message, 'success')
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }

  const [visibleA, setVisibleA] = useState(false)
  const [visibleB, setVisibleB] = useState(false)
  const [dNoneClass, setDNoneClass] = useState('d-none')
  const [isSubscribed, setIsSubscribed] = useState(false)

  const handlePromoCode = (event) => {
    if (event.target.checked) {
      setDNoneClass('')
    } else {
      setDNoneClass('d-none')
    }
    setIsSubscribed((current) => !current)
  }
  const [showForm, setShowForm] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleAgree = (e) => {
    if (e.target.checked) {
      setSaveButton(false)
    } else {
      setSaveButton(true)
    }
  }
  const handlePriceChange = (e) => {
    setPartnerPlanPrice(e.target.value)
  }
  const handleSave = (e) => {
    setSaveButton(false)
    e.preventDefault()
    const data = {}
    data.amount = partnerPlanPrice
    data.type = 2
    CommonService.savePayment(data)
      .then((res) => {
        if (res.status === 200) {
          ToastComponent('Plan has been save.', 'success')
          window.href.location = '/activate-game'
        }
      })
      .catch((e) => {
        console.log('Error', e)
      })
  }

  return (
    <>
      <CRow>
        <CCol xs={12} sm={12}>
          <CAccordion activeItemKey={1}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                {' '}
                <strong>Game Activation</strong>
              </CAccordionHeader>
              <CAccordionBody>
                <p>
                  Minimum pre-requisites / conditions for you to be able to &apos;{`Activate Game`}
                  &apos;.
                </p>
                <CListGroup>
                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    <Link to={'/club-about-game'}>Create Club/League Name</Link>
                    {gameInfo?.game_name ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>

                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    <Link to="/club-about-game">Create Game Name</Link>
                    {gameInfo?.game_name ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>
                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    <Link to="/club-about-game">Select Timezone </Link>
                    {gameInfo?.timezone ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>
                  {/* <CListGroupItem className="d-flex justify-content-between align-items-center">
                    Select Country
                    {gameInfo.country ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>
                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    Select State
                    {gameInfo.state ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem> */}
                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    <Link to="/rounds">Create 1 Round </Link>
                    {roundInfo ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>
                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                    <Link to={'/plan'}> Starter Plan Paid</Link>
                    {activationInfo?.is_paid === 1 ? (
                      <CBadge color="success" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilCheck} />
                      </CBadge>
                    ) : (
                      <CBadge color="danger" shape="rounded-pill" size={'sm'}>
                        <CIcon icon={cilX} />
                      </CBadge>
                    )}
                  </CListGroupItem>
                </CListGroup>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </CCol>

        {gameInfo?.game_status === 0 && (
          <CCol sm={6} xs={12}>
            <CLoadingButton
              type="submit"
              color="success"
              className="mt-3 mb-3"
              variant="outline"
              loading={loader}
              disabled={disabledBtn}
              onClick={() => activateGame(gameInfo?.id, 1)}
            >
              Activate Game
            </CLoadingButton>
          </CCol>
        )}
        {gameInfo?.game_status === 1 && (
          <CCol sm={6} md={12} xs={12}>
            <CLoadingButton
              type="submit"
              color="danger"
              className="mt-3 mb-3"
              variant="outline"
              loading={loader}
              disabled={disabledBtn}
              onClick={() => activateGame(gameInfo?.id, 0)}
            >
              Deactivate Game
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </>
  )
}

export default ActivateGame
