import React from 'react'
import moment from 'moment'

const PowerUsedPlayer = (props) => {
  return (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>User</th>
            <th>Player</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {props.powerUsers &&
            props.powerUsers.map((item, key) => (
              <tr key={key}>
                <th>{item.username}</th>
                <th>{item.player_name}</th>
                <th>{moment(item.created_at).format('D.MM.YYYY')}</th>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default PowerUsedPlayer
