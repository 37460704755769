import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCol,
  CSmartTable,
} from '@coreui/react-pro'
import moment from 'moment'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
const UserTrade = () => {
  const [loading, setLoading] = useState()

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState([])
  const columns = [
    // {
    //   key: 'created_at',
    //   label: 'Date',
    // },
    {
      key: 'full_name',
      label: 'Member',
    },
    { key: 'totalTrades', label: 'Trades Used', filter: false },
    { key: 'trade_left', label: 'Trades Left', filter: false },
  ]
  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    console.log(offset)
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.userTrade(offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }, [activePage, columnFilter, columnSorter, itemsPerPage])
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Trades Used</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol xs={12}>
                <CSmartTable
                  columns={columns}
                  columnFilter={{
                    external: true,
                  }}
                  columnSorter={{
                    external: true,
                  }}
                  scopedColumns={{
                    created_at: (item) => (
                      <td>
                        {moment(item.created_at).format('D.MM.YYYY')}&nbsp;{' '}
                        {moment(item.created_at).format('h:mm A')}
                      </td>
                    ),
                  }}
                  items={users?.data}
                  itemsPerPage={itemsPerPage}
                  itemsPerPageSelect
                  loading={loading}
                  pagination={{
                    external: true,
                  }}
                  paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(users?.total / itemsPerPage) || 1,
                  }}
                  tableProps={{
                    hover: true,
                    responsive: true,
                  }}
                  onActivePageChange={(activePage) => setActivePage(activePage)}
                  onColumnFilterChange={(filter) => {
                    setActivePage(1)
                    setColumnFilter(filter)
                  }}
                  onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                  }}
                  onSorterChange={(sorter) => setColumnSorter(sorter)}
                />
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default UserTrade
