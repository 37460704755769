import React from 'react'
import PieChart from './PieChart'
import { CRow } from '@coreui/react-pro'

const GameChart = () => {
  return (
    <>
      {' '}
      <CRow>
        <PieChart />
      </CRow>
    </>
  )
}

export default GameChart
