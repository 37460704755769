import React from 'react'
import moment from 'moment'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import { CButton } from '@coreui/react-pro'
const PlayerClaimRequest = (props) => {
  const handleApprove = (verifyUserid) => {
    const data = {}
    data.recordId = verifyUserid
    data.status = 1
    PlayerService.verifyPlayerRequest(data)
      .then((res) => {
        if (res.status === 200) {
          props.fetchData()
          props.fetchPlayerData(
            props.activePage,
            props.columnFilter,
            props.columnSorter,
            props.itemsPerPage,
          )
          ToastComponent(res.message, 'success')
        }
      })
      .catch((e) => {
        console.log('Error =>', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }
  const handleDecline = (verifyUserid) => {
    const data = {}
    data.recordId = verifyUserid
    data.status = 2
    PlayerService.verifyPlayerRequest(data)
      .then((res) => {
        if (res.status === 200) {
          props.setRequestList((current) => current.filter((fruit) => fruit.id !== verifyUserid))
          ToastComponent(res.message, 'success')
        }
      })
      .catch((e) => {
        console.log('Error =>', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }
  return (
    <div>
      {' '}
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>Player</th>
            <th>Position</th>
            <th>Value</th>
            <th>Date</th>
            <th>Member</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.requestList &&
            props.requestList.map((item, key) => (
              <tr key={key}>
                <th>{item?.player_data?.full_name}</th>
                <th>{item?.player_data?.position_name}</th>
                <th>${item?.player_data?.svalue}m</th>
                <th> {moment(item?.created_at).format('D.MM.YYYY')}</th>
                <th>{item?.user_full_name}</th>
                <td>
                  <CButton color={'success'} size="sm" onClick={() => handleApprove(item.id)}>
                    Approve
                  </CButton>
                  <CButton
                    color={'danger'}
                    className={'ml-2'}
                    size="sm"
                    onClick={() => handleDecline(item.id)}
                  >
                    Decline
                  </CButton>
                </td>
              </tr>
            ))}
          {props.requestList.length === 0 && (
            <tr>
              <td colSpan={7}>No record yet available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PlayerClaimRequest
