import React from 'react'
import { useState } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'

const PageInfoModal = (props) => {
  return (
    <CModal
      alignment="center"
      scrollable
      visible={props.infoPageModal}
      onClose={() => props.setInfoPageModal(false)}
    >
      <CModalHeader>
        <CModalTitle>Information</CModalTitle>
      </CModalHeader>
      <CModalBody> {ReactHtmlParser(props.infoText?.info_text)}</CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={() => props.setInfoPageModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default PageInfoModal
