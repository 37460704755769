import { CSmartTable } from '@coreui/react-pro'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
import PlayerStatTable from './PlayerStatTable'
import ClubService from 'src/service/ClubService'

const PlayerStats = () => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [users, setUsers] = useState({})
  const columns = [
    {
      label: 'Player Name',
      filter: true,
      sorter: true,
      key: 'player_name',
      _props: { color: 'dark' },
    },
    { label: 'POS', filter: false, sorter: true, key: 'position_name', _props: { color: 'dark' } },
    { label: 'PT', filter: false, sorter: true, key: 'sum_pt', _props: { color: 'dark' } },
    { label: 'ST', filter: false, sorter: true, key: 'sum_st', _props: { color: 'dark' } },
    { label: 'REB', filter: false, sorter: true, key: 'sum_reb', _props: { color: 'dark' } },
    { label: 'AST', filter: false, sorter: true, key: 'sum_ast', _props: { color: 'dark' } },
    { label: 'BKS', filter: false, sorter: true, key: 'sum_bks', _props: { color: 'dark' } },
    { label: 'TOV', filter: false, sorter: true, key: 'sum_tov', _props: { color: 'dark' } },
    { label: '3P', filter: false, sorter: true, key: 'sum_threep', _props: { color: 'dark' } },
    { label: 'PFS', filter: false, sorter: true, key: 'sum_pfs', _props: { color: 'dark' } },
    { label: 'FP', filter: false, sorter: true, key: 'sum_fp', _props: { color: 'dark' } },
  ]

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.playerStats(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }

  const [playerList, setPlayerList] = useState([])
  const [gameDetails, setGameDetails] = useState({})
  useEffect(() => {
    const fetchGameAndPlayerData = async () => {
      try {
        setLoading(true)

        const [clubDetailsResponse, playerResponse] = await Promise.all([
          ClubService.getClubDetails(),
          PlayerService.getAllPlayer(),
        ])

        if (clubDetailsResponse) {
          setGameDetails(clubDetailsResponse.game_details)
        }

        if (playerResponse?.status === 200) {
          setPlayerList(playerResponse.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchGameAndPlayerData()
  }, [])
  return (
    <>
      <PlayerStatTable
        users={users}
        activePage={activePage}
        setActivePage={setActivePage}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        columnSorter={columnSorter}
        setColumnSorter={setColumnSorter}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        columns={columns}
        fetchData={fetchData}
        setLoading={setLoading}
        loading={loading}
        playerList={playerList}
      />
    </>
  )
}

export default PlayerStats
