import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CButton,
  CFormCheck,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import Loader from 'src/components/Loader'
import PlayerService from 'src/service/PlayerService'
import Bootbox from 'bootbox-react'
import ToastComponent from 'src/components/common/TaostComponent'
const InactivePlayer = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    setLoading(true)
    PlayerService.inActivePlayer().then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }

  // Delete start
  const [showConfirm, setShowConfirm] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const showCofirmBox = (recordId) => {
    setRecordId(recordId)
    setShowConfirm(true)
  }
  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }

  const changeStatus = () => {
    setShowConfirm(false)
    const data = {}
    data.id = recordId
    PlayerService.deletePlayer(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        setLoading(false)
        fetchData()
      }
    })
  }
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const toggleCheckbox = (id) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const toggleAllCheckboxes = () => {
    const allIds = users.map((user) => user.id)
    setSelectedCheckboxes((prevSelected) => (prevSelected.length === allIds.length ? [] : allIds))
  }

  // Delete All confirm box
  const [showDeleteAllConfirm, showShowDeleteAllConfirm] = useState(false)
  const showDeleteAllCofirmBox = () => {
    showShowDeleteAllConfirm(true)
  }
  const handleDeleteAllConfirm = () => {
    handleDeleteAllPlayer()
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllCancel = () => {
    console.log('You clicked No!')
    return showShowDeleteAllConfirm(false)
  }

  const handleDeleteAllPlayer = () => {
    // Call your API to delete players using selectedCheckboxes array
    console.log('Selected player IDs:', selectedCheckboxes)
    showShowDeleteAllConfirm(false)
    const data = {
      playerIds: selectedCheckboxes, // Include the array of IDs here
    }
    PlayerService.deleteAllPlayer(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        setLoading(false)
        fetchData()
      }
    })
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <CButton
        color="danger"
        className="mb-3"
        onClick={showDeleteAllCofirmBox}
        disabled={selectedCheckboxes.length === 0 ? 'true' : false}
      >
        Delete All
      </CButton>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Inactive Players</strong>
              {/* <input
                type="checkbox"
                name="delete_all"
                checked={selectedCheckboxes.length === users.length}
                onChange={toggleAllCheckboxes}
              /> */}
            </CAccordionHeader>
            <CAccordionBody>
              <table className="main-table table innertable">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        name="delete_all"
                        checked={selectedCheckboxes.length === users.length}
                        onChange={toggleAllCheckboxes}
                      />
                    </th>
                    <th>Player</th>
                    <th>Value</th>
                    <th>Position</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, key) => (
                      <tr key={key}>
                        <th>
                          <input
                            type="checkbox"
                            name="delete"
                            value={item.id}
                            checked={selectedCheckboxes.includes(item.id)}
                            onChange={() => toggleCheckbox(item.id)}
                          />
                        </th>
                        <th>{item.full_name}</th>
                        <th>${item.svalue}m</th>
                        <td>{item?.position_name}</td>
                        <td>
                          <CButton
                            size="sm"
                            color="danger"
                            className="ml-1"
                            onClick={() => showCofirmBox(item.id)}
                          >
                            Delete
                          </CButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
      <Bootbox
        show={showDeleteAllConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete all players?'}
        onSuccess={handleDeleteAllConfirm}
        onCancel={handleDeleteAllCancel}
        onClose={handleDeleteAllCancel}
      />
    </>
  )
}

export default InactivePlayer
