import React from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro'

const ShowTooltipText = (props) => {
  const formatString = (str) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const formattedString = formatString(props.infoType)

  return (
    <>
      <CModal visible={props.modalIsOpen} size="lg">
        <CModalHeader>
          <strong> {formattedString} </strong>
        </CModalHeader>
        <CModalBody>
          {props.infoType === 'about_game' && (
            <ul>
              <li>Game Name</li>
              <li>Game Logo</li>
              <li>Timezone</li>
              <li>Welcome Message</li>
              <li>Terms & Conditions</li>
              <li>Game Admin Details</li>
              <li>Social Links</li>
            </ul>
          )}
          {props.infoType === 'game_structure' && (
            <ul>
              <li>Team Size</li>
              <li>Selection Mode & Limits</li>
              <li>Salary Cap</li>
              <li>Trades</li>
              <li>Game Privacy</li>
            </ul>
          )}
          {props.infoType === 'fantasy_value' && (
            <ul>
              <li>Symbol</li>
              <li>Fantasy Values</li>
              <li>Advanced Settings</li>
            </ul>
          )}
          {props.infoType === 'comps' && (
            <ul>
              <li>Comp Names</li>
              <li>Point System</li>
            </ul>
          )}
          {props.infoType === 'teams' && (
            <ul>
              <li>Team Names</li>
              <li>Team Settings</li>
              <li>Bonus Points</li>
            </ul>
          )}
          {props.infoType === 'game_spots' && (
            <ul>
              <li>Game Spots Allowed</li>
            </ul>
          )}
          {props.infoType === 'branding' && (
            <ul>
              <li>Name</li>
              <li>Website</li>
              <li>Social Links</li>
              <li>Website</li>
              <li>Logo</li>
              <li>About</li>
            </ul>
          )}
          {props.infoType === 'sponsors' && (
            <ul>
              <li>Name</li>
              <li>Website</li>
              <li>Social Links</li>
              <li>Website</li>
              <li>Logo</li>
              <li>About</li>
            </ul>
          )}
          {props.infoType === 'players' && (
            <ul>
              <li>Game Name</li>
              <li>Game Logo</li>
              <li>Timezone</li>
              <li>Welcome Message</li>
              <li>Terms & Conditions</li>
              <li>Game Admin Details</li>
              <li>Social Links</li>
            </ul>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={(e) => props.setModalIsOpen(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ShowTooltipText
