import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL

const getMemberAnalyticData = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}getMemberAnalyticData?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getPlayerAnalyticData = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}getPlayerAnalyticData?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getGameAnalyticData = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(`${API_URL}getGameAnalyticData`)
  return response.data
}

const AnalyticService = {
  getMemberAnalyticData,
  getPlayerAnalyticData,
  getGameAnalyticData,
}

export default AnalyticService
