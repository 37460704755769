import React, { useState } from 'react'
import { CBadge, CButton, CSmartTable } from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import ShowEmailModal from './ShowEmailModal'
import GeneralService from 'src/service/GeneralService'
const Table = (props) => {
  const [loading, setLoading] = useState()

  const [infoPageModal, setInfoPageModal] = useState(false)
  const [emailMessage, setEmailMessage] = useState({})

  const showEmail = (id) => {
    setInfoPageModal(true)
    GeneralService.getEmailMessage(id).then((res) => {
      if (res.status === 200) {
        setEmailMessage(res.data)
      }
    })
  }

  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          type: (item) => (
            <td>
              {item.type === 1 && <CBadge color={'success'}> Admin</CBadge>}
              {item.type === 2 && <CBadge color={'primary'}> Member</CBadge>}
              {item.type === 3 && <CBadge color={'danger'}>What&apos;s New</CBadge>}
              {item.type === 4 && <CBadge color={'warning'}>Press Release</CBadge>}
              {item.type === 5 && <CBadge color={'info'}>Tour</CBadge>}
            </td>
          ),
          short_description: (item) => <td>{ReactHtmlParser(item.short_description)}</td>,
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      showEmail(item.id)
                    }}
                  >
                    View
                  </CButton>
                </td>
              </>
            )
          },
        }}
        items={props.users?.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
      <ShowEmailModal
        infoPageModal={infoPageModal}
        setInfoPageModal={setInfoPageModal}
        emailMessage={emailMessage}
      />
    </>
  )
}

export default Table
