import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CListGroupItem,
  CButton,
  CCol,
  CFormLabel,
  CFormSelect,
  CHeader,
  CContainer,
  CHeaderNav,
} from '@coreui/react-pro'
import logo from '../../assets/brand/logo.jpg'
import { cilInfo } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import Notify from '../Notify'
import CommonService from 'src/service/CommonService'
import HeaderInfoContentModal from './HeaderInfoContentModal'
import Loader from 'src/components/Loader'
import { AppHeaderDropdown } from 'src/components/header'
import { AppFooter } from 'src/components'
import ToastComponent from 'src/components/common/TaostComponent'
import ShowTooltipText from './ShowTooltipText'
import PlayerService from 'src/service/PlayerService'

const CopyBranding = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [gameDropdown, setGameDropdown] = useState([])
  const [users, setUsers] = useState([])

  const fetchData = async () => {
    try {
      const infoTextResponse = await CommonService.getGameList()
      if (infoTextResponse.status === 200) {
        setIsLoading(false)
        setGameDropdown(infoTextResponse.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [fromGameId, setFromGameId] = useState(0)
  const [toGameId, setToGameId] = useState(0)
  const [settingType, setSettingType] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)

  const handleFromGameChange = (value) => {
    setFromGameId(value)
  }
  const handleToGameChange = (value) => {
    setToGameId(value)
  }

  const handleCancel = () => {
    setShowConfirm(false)
  }

  const handleSubmit = () => {
    setIsLoading(true)
    const data = {}
    data.fromGameId = fromGameId
    data.toGameId = toGameId
    CommonService.saveCopyBranding(data).then((res) => {
      if (res.status === 200) {
        setShowConfirm(false)
        setIsLoading(false)
        ToastComponent(res.message, 'success')
      }
    })
  }

  const handleCopyPlayersClick = () => {
    setShowConfirm(true)
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CRow>
        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Copy Branding</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <CCol md={6}>
                  <CFormLabel className="fw-bold" htmlFor="gameId">
                    Copy Branding From Game*
                  </CFormLabel>
                  <CFormSelect
                    aria-label="select Game"
                    name="fromGameId"
                    className={'form-control'}
                    value={fromGameId}
                    onChange={(e) => handleFromGameChange(e.target.value)}
                    id="fromGameId"
                  >
                    <option value={0}>Select Game</option>
                    {gameDropdown &&
                      gameDropdown.map((item, key) => (
                        <option value={item?.id} key={key}>
                          {item?.game_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol md={6}>
                  <CFormLabel className="fw-bold" htmlFor="gameId">
                    Copy Branding To Game*
                  </CFormLabel>
                  <CFormSelect
                    aria-label="select Game"
                    name="toGameId"
                    className={'form-control'}
                    value={toGameId}
                    onChange={(e) => handleToGameChange(e.target.value)}
                    id="toGameId"
                  >
                    <option value={0}>Select Game</option>
                    {gameDropdown &&
                      gameDropdown.map((item, key) => (
                        <option value={item?.id} key={key}>
                          {item?.game_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md={6} className="d-grid">
                  <CButton
                    onClick={handleCopyPlayersClick}
                    color="success"
                    variant="outline"
                    disabled={fromGameId === 0 || toGameId === 0}
                  >
                    Copy Branding
                  </CButton>
                </CCol>
                <CCol md={6} className="d-grid">
                  {' '}
                  <CButton component="a" color="primary" href="/account" role="button">
                    Back
                  </CButton>
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>

      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleConfirm={handleSubmit}
        handleCancel={handleCancel}
        text="Are you sure you want to Copy this?"
      />
    </>
  )
}

export default CopyBranding
