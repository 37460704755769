import {
  CBadge,
  CCol,
  CForm,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CMultiSelect,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import Bootbox from 'bootbox-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BracketBattleService from 'src/service/BracketBattleService'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import SetHeadToHeadPoint from './SetHeadToHeadPoint'
import ChooseMember from './ChooseMember'
import Loader from 'src/components/Loader'
import ChooseHeadToHeadType from './ChooseHeadToHeadType'

const HeadToHeadSetting = () => {
  const [data, setData] = useState([])
  const [memberList, setMemberList] = useState([])
  const [memberList2, setMemberList2] = useState([])
  const [userList, setUserList] = useState([])
  const [group1Count, setGroup1Count] = useState(0)
  const [group2Count, setGroup2Count] = useState(0)

  const fetchData = () => {
    setLoader(true)
    HeadToHeadService.getHeadToHeadSetting().then((result) => {
      setData(result.data)
      setMemberList(result.memberList)
      setMemberList2(result.memberList2)
      setUserList(result.userList)
      setGroup1Count(result.group1Count)
      setGroup2Count(result.group2Count)
      setLoader(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [loader, setLoader] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [roundId, setRoundId] = useState(false)
  const showCofirmBox = () => {
    setShowConfirm(true)
  }

  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const changeStatus = (type) => {
    setLoader(true)
    HeadToHeadService.resetAllH2H().then((result) => {
      setLoader(false)
    })
  }
  return (
    <>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Choose Head to Head Type</strong>
          </CAccordionHeader>
          <CAccordionBody>
            {loader ? (
              <Loader />
            ) : (
              <ChooseHeadToHeadType data={data} setLoader={setLoader} fetchData={fetchData} />
            )}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      {data?.type === 'group' && (
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Choose Member For Group ({userList.length})</strong>
            </CAccordionHeader>
            <CAccordionBody className="mt-2">
              {loader ? (
                <Loader />
              ) : (
                <ChooseMember
                  data={data}
                  memberList={memberList}
                  setMemberList={setMemberList}
                  memberList2={memberList2}
                  setMemberList2={setMemberList2}
                  fetchData={fetchData}
                  group1Count={group1Count}
                  group2Count={group2Count}
                  setGroup1Count={setGroup1Count}
                  setGroup2Count={setGroup2Count}
                />
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      )}

      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Head-2-Head Settings</strong>
          </CAccordionHeader>
          <CAccordionBody>
            {loader ? <Loader /> : <SetHeadToHeadPoint data={data} />}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Reset All Head-2-Head</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CLoadingButton
              type="submit"
              color="success"
              variant="outline"
              loading={loader}
              onClick={showCofirmBox}
            >
              Reset All
            </CLoadingButton>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to reset all setting and delete all head to head?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default HeadToHeadSetting
