import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSwitch,
} from '@coreui/react-pro'
import ToastComponent from 'src/components/common/TaostComponent'
import GeneralService from 'src/service/GeneralService'
const ActivateTotr = (props) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    GeneralService.getUserGameInfo().then((res) => {
      if (res.status === 200) {
        setShow(res.data?.is_totr_activate)
      }
    })
  }, [])
  const handleChangeStatus = (event) => {
    setShow(event.target.checked)
    const data = {}
    data.addOnType = 'is_totr_activate'
    data.status = event.target.checked

    GeneralService.changeUserGameAddOnStatus(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Activate TOTR</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CFormSwitch
              size="lg"
              label="Show/Hide TOTR"
              id="formSwitchCheckDefaultLg"
              // checked={isRadio === item.id}
              checked={show ? true : false}
              onChange={handleChangeStatus}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default ActivateTotr
