import React from 'react'

const Top5WksPlayer = (props) => {
  return (
    <div>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>Top 5 Players</th>
            <th>Assists</th>
          </tr>
        </thead>
        <tbody>
          {props.top5MostWksPlayers &&
            props.top5MostWksPlayers.map((item, key) => (
              <tr key={key}>
                <td>{item?.full_name}</td>
                <td>{item?.totalHw}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default Top5WksPlayer
