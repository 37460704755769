import React, { useState } from 'react'

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js'

import CommonService from 'src/service/CommonService'
import CardSection from './CardSection'
import ToastComponent from 'src/components/common/TaostComponent'
import { useNavigate } from 'react-router-dom'

const PaymentForm = (props) => {
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const handleSubmit = async (e) => {
    e.preventDefault()
    setDisabled(true)
    if (elements == null) {
      return
    }
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }
    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)
    if (result.error) {
      setErrorMessage(result.error.message)
    } else {
      const data = {}
      data.token = result.token
      data.amount = props.amount
      data.noOfFixture = props.noOfFixture
      data.type = props.type
      CommonService.savePayment(data)
        .then((res) => {
          if (res.status === 200) {
            props.setActivationInfo(res.data)
            ToastComponent('Thanks for the payment!', 'success')
            setSuccess(true)
            if (res.is_saved) {
              setDisabled(false)
            }

            props.setVisible(false)
            if (props.type === 3) {
              navigate('/scorer-plus')
            }
            if (props.type === 1) {
              navigate('/activate-game')
            }
          }
        })
        .catch((e) => {
          console.log('Error', e)
        })
    }
  }
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: 'sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  }
  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <button type="submit" className="button_custom_class" disabled={disabled}>
            Pay (${props.amount})
          </button>
        </form>
      ) : (
        <div className="payment-success">
          <h2>Payment successful</h2>
          <h3 className="Thank-you ">Thank you for your payment</h3>
        </div>
      )}
    </>
  )
}

export default PaymentForm
