import { useState, useEffect } from 'react'

const useImageLoader = (imageUrl, alternativeImageUrl) => {
  const [imageSrc, setImageSrc] = useState(imageUrl)

  useEffect(() => {
    const checkImageExists = async () => {
      try {
        const response = await fetch(imageUrl)
        if (!response.ok) {
          setImageSrc(alternativeImageUrl)
        }
      } catch (error) {
        setImageSrc(alternativeImageUrl)
      }
    }

    checkImageExists()
  }, [imageUrl, alternativeImageUrl])

  return imageSrc
}

export default useImageLoader
