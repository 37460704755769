import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AboutService from 'src/service/AboutService'
import ToastComponent from 'src/components/common/TaostComponent'

const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    text1: Yup.string().required('Text1 is required'),
    text2: Yup.string().required('Text2 is required'),
  })
  const formik = useFormik({
    initialValues: {
      type: '',
      text1: '',
      text2: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      var formData = new FormData()
      formData.append('type', data.type)
      formData.append('text1', data.text1)
      formData.append('text2', data.text2)

      actions.resetForm()
      AboutService.saveAbout(formData)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            props.setLoading(false)
            setLoader(false)
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.asdasdasd', 'error')
          props.setLoading(false)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="grade_name">
            Type *
          </CFormLabel>
          <CFormSelect
            aria-label="Select Comp"
            name="type"
            className={
              'form-control' + (formik.errors.type && formik.touched.type ? ' is-invalid' : '')
            }
            value={formik.values.type}
            onChange={formik.handleChange}
            id="type"
          >
            <option value={0}>Select type</option>
            <option value={1}>Pricing Feature</option>
            <option value={2}>Rules</option>
            <option value={3}>Faqs</option>
            <option value={4}>How To Play</option>
            <option value={5}>How It Works</option>
            <option value={6}>Whats new</option>
          </CFormSelect>
          {formik.errors.type && formik.touched.type && (
            <CFormFeedback invalid>{formik.errors.type}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Text1 *
          </CFormLabel>
          <CFormInput
            placeholder="Text1"
            className={
              'form-control' + (formik.errors.text1 && formik.touched.text1 ? ' is-invalid' : '')
            }
            value={formik.values.text1}
            onChange={formik.handleChange}
            aria-label="text1"
            id="text1"
          />
          {formik.errors.text1 && formik.touched.text1 && (
            <CFormFeedback invalid>{formik.errors.text1}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Text2
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={formik.values.text2}
            onChange={(content) => {
              formik.setFieldValue('text2', content)
            }}
          />
          {formik.errors.text2 && formik.touched.text2 && (
            <CFormFeedback invalid>{formik.errors.text2}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}></CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
