import {
  CCol,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import HeadToHeadService from 'src/service/HeadToHeadService'
import RoundService from 'src/service/RoundService'
import SetPlayOffFirst from './SetPlayOffFirst'
import Loader from 'src/components/Loader'
import SetPlayOffSecond from './SetPlayOffSecond'
import SetPlayOffThird from './SetPlayOffThird'
import SetGroupPlayOffFirst from './SetGroupPlayOffFirst'
import SetGroupPlayOffSecond from './SetGroupPlayOffSecond'
import SetGroupPlayOffThird from './SetGroupPlayOffThird'

const HeadToHeadPlayOff = () => {
  const [roundData, setRoundData] = useState([])
  const [h2hSetting, setH2hSetting] = useState({})
  const [playOffDataFirst, setPlayOffDataFirst] = useState({})
  const [playeOffSecond, setPlayeOffSecond] = useState({})
  const [playeOffFinal, setPlayeOffFinal] = useState({})
  const [playOffMatch1, setPlayOffMatch1] = useState(false)
  const [playOffMatch2, setPlayOffMatch2] = useState(false)

  const [playOffMatch3, setPlayOffMatch3] = useState(false)
  const [playOffMatch4, setPlayOffMatch4] = useState(false)
  const [playOffMatch5, setPlayOffMatch5] = useState(false)

  const [group1Rank2Data, setGroup1Rank2Data] = useState({})
  const [group1Rank3Data, setGroup1Rank3Data] = useState({})

  const [group2Rank3Data, setGroup2Rank3Data] = useState({})
  const [group2Rank2Data, setGroup2Rank2Data] = useState({})

  const [rankingData, setRankingData] = useState({})

  const [loader, setLoader] = useState(false)

  const fetchData = () => {
    setLoader(true)
    RoundService.getRoundDropDown()
      .then((result) => {
        setRoundData(result.data)
      })
      .then(() => {
        HeadToHeadService.getPlayOffdata().then((result) => {
          setPlayOffDataFirst(result.playeOffFirst)
          setPlayeOffSecond(result.playeOffSecond)
          setPlayeOffFinal(result.playeOffFinal)
          setPlayOffMatch1(result?.playerOffOneData.playOffMatch1)
          setPlayOffMatch2(result?.playerOffOneData.playOffMatch2)
          setPlayOffMatch3(result?.playerOffOneData.playOffMatch3)
          setPlayOffMatch4(result?.playerOffOneData.playOffMatch4)
          setPlayOffMatch5(result?.playerOffOneData.playOffMatch5)
          setGroup1Rank2Data(result?.group1Rank2Data)
          setGroup1Rank3Data(result?.group1Rank3Data)
          setGroup2Rank3Data(result?.group2Rank3Data)
          setGroup2Rank2Data(result?.group2Rank2Data)
          setRankingData(result?.rankingData)
          setLoader(false)
        })
      })
      .then(() => {
        HeadToHeadService.headToHeadSetting().then((result) => {
          setH2hSetting(result.battleType)
        })
      })
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <CRow>
      <CCol xs={12} md={12} className="mt-2">
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Set Playoff 1</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {loader ? (
                <Loader />
              ) : h2hSetting?.type === 'all' ? (
                <SetPlayOffFirst
                  roundData={roundData}
                  playOffDataFirst={playOffDataFirst}
                  playOffMatch1={playOffMatch1}
                  playOffMatch2={playOffMatch2}
                  fetchData={fetchData}
                  rankingData={rankingData}
                />
              ) : h2hSetting?.type === 'group' ? (
                <SetGroupPlayOffFirst
                  roundData={roundData}
                  playOffDataFirst={playOffDataFirst}
                  playOffMatch1={playOffMatch1}
                  playOffMatch2={playOffMatch2}
                  group1Rank2Data={group1Rank2Data}
                  group1Rank3Data={group1Rank3Data}
                  group2Rank3Data={group2Rank3Data}
                  group2Rank2Data={group2Rank2Data}
                  fetchData={fetchData}
                />
              ) : null}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCol>
      <CCol xs={12} md={12} className="mt-2">
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Set Playoff 2</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {loader ? (
                <Loader />
              ) : h2hSetting?.type === 'all' ? (
                <SetPlayOffSecond
                  roundData={roundData}
                  playeOffSecond={playeOffSecond}
                  playOffMatch1={playOffMatch1}
                  playOffMatch2={playOffMatch2}
                  playOffMatch3={playOffMatch3}
                  playOffMatch4={playOffMatch4}
                  rankingData={rankingData}
                  fetchData={fetchData}
                />
              ) : h2hSetting?.type === 'group' ? (
                <SetGroupPlayOffSecond
                  roundData={roundData}
                  playeOffSecond={playeOffSecond}
                  playOffMatch1={playOffMatch1}
                  playOffMatch2={playOffMatch2}
                  playOffMatch3={playOffMatch3}
                  playOffMatch4={playOffMatch4}
                  fetchData={fetchData}
                />
              ) : null}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCol>
      <CCol xs={12} md={12} className="mt-3">
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Set Final</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {loader ? (
                <Loader />
              ) : h2hSetting?.type === 'all' ? (
                <SetPlayOffThird
                  roundData={roundData}
                  playOffMatch3={playOffMatch3}
                  playOffMatch4={playOffMatch4}
                  playeOffFinal={playeOffFinal}
                  playOffMatch5={playOffMatch5}
                  fetchData={fetchData}
                />
              ) : h2hSetting?.type === 'group' ? (
                <SetGroupPlayOffThird
                  roundData={roundData}
                  playOffMatch3={playOffMatch3}
                  playOffMatch4={playOffMatch4}
                  playOffMatch5={playOffMatch5}
                  playeOffFinal={playeOffFinal}
                  fetchData={fetchData}
                />
              ) : null}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCol>
    </CRow>
  )
}

export default HeadToHeadPlayOff
