import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
} from '@coreui/react-pro'
import React from 'react'
import Bootbox from 'bootbox-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import PlayerService from 'src/service/PlayerService'
import { useEffect } from 'react'
import GeneralService from 'src/service/GeneralService'

const PlayerStructure = (props) => {
  const [showPosBased, setShowPosBased] = useState(false)
  const [showPosConfirm, setShowPosConfirm] = useState(false)
  const [modalText, setModalText] = useState('')
  const [labelText, setLabelText] = useState('')
  useEffect(() => {
    PlayerService.getPlayerStructureInfo()
      .then((res) => {
        if (res.status === 200) {
          props.setPlayerStructureDetails(res.data)
        }
      })
      .then(() => {
        GeneralService.getUserGameInfo().then((res) => {
          if (res.status === 200) {
            if (res.data?.team_view == 0) {
              setLabelText('Switch to Position Free Selection Mode')
            }
            if (res.data?.team_view == 1) {
              setLabelText('Switch to Position Based Selection Mode')
            }
            setShowPosBased(res.data?.team_view)
            if (res.data?.team_view == 1) {
              setModalText(
                'Are you sure you want to set Position Free Selection Mode for the game?',
              )
            } else {
              setModalText(
                'Are you sure you want to set Position Based Selection Mode for the game?',
              )
            }
          }
        })
      })
  }, [])
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    min_pg: Yup.number().min(1, 'Minimum value of 1 is required').required('Min PG is required'),
    max_pg: Yup.number().max(5, 'Maximum cannot be greater than 5').required('Max PG is required'),

    min_sg: Yup.number().min(1, 'Minimum value of 1 is required').required('Min SG is required'),
    max_sg: Yup.number().max(5, 'Maximum cannot be greater than 5').required('Max SG is required'),
    min_fwd: Yup.number().min(1, 'Minimum value of 1 is required').required('Min fwd is required'),
    max_fwd: Yup.number()
      .max(5, 'Maximum cannot be greater than 5')
      .required('Max fwd is required'),
    min_cen: Yup.number().min(1, 'Minimum value of 1 is required').required('Min CEN is required'),
    max_cen: Yup.number()
      .max(5, 'Maximum cannot be greater than 5')
      .required('Max CEN is required'),
  })
  const formik = useFormik({
    initialValues: {
      min_pg: props.playerStructuredetails?.min_pg,
      max_pg: props.playerStructuredetails?.max_pg,
      min_sg: props.playerStructuredetails?.min_sg,
      max_sg: props.playerStructuredetails?.max_sg,
      min_fwd: props.playerStructuredetails?.min_fwd,
      max_fwd: props.playerStructuredetails?.max_fwd,
      min_cen: props.playerStructuredetails?.min_cen,
      max_cen: props.playerStructuredetails?.max_cen,
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (data) => {
      setLoader(true)
      PlayerService.savePlayerStructure(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoader(false)
          }
        })
        .then(() => {
          const data = {}
          data.addOnType = 'team_view'
          data.status = showPosBased
          GeneralService.changeUserGameAddOnStatus(data)
        })
    },
  })

  const handlePosConfirm = () => {
    savePosFree()
    return setShowPosConfirm(false)
  }
  const handlePosCancel = () => {
    return setShowPosConfirm(false)
  }
  const handleChangeStatus = (event) => {
    if (event.target.checked === true) {
      setModalText('Are you sure you want to set Position Free Selection Mode for the game?')
    } else {
      setModalText('Are you sure you want to set Position Based Selection Mode for the game?')
    }
    setShowPosConfirm(true)
    setShowPosBased(event.target.checked)
  }

  const savePosFree = () => {
    const data = {}
    data.addOnType = 'team_view'
    data.status = showPosBased ? true : false
    GeneralService.changeUserGameAddOnStatus(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <>
      <CCol xs={12}>
        <CForm className="row g-3" onSubmit={formik.handleSubmit}>
          <strong>
            <CFormSwitch
              size="lg"
              label={labelText}
              id="formSwitchCheckDefaultLg"
              checked={showPosBased ? true : false}
              onChange={handleChangeStatus}
              disabled={props.isTeamExists}
            />
          </strong>
          {!showPosBased && (
            <>
              <h6>Mode: Position Based Selection (Default)</h6>
              <CCol xs={12}>
                <ul>
                  <li>
                    In this mode, your game requires members to pick players based on the set player
                    limits for each position.
                  </li>
                  <li>You can set your own player selection limits for each position here.</li>
                  <li>
                    If no change is required, you can leave the default limits as currently set
                    below.
                  </li>
                </ul>
              </CCol>
            </>
          )}
          {showPosBased == 1 && (
            <>
              <h6>Mode: Position Free Selection Mode</h6>
              <CCol xs={12}>
                <ul>
                  <li>
                    In this mode, your game does not require members to pick players based on the
                    set player limits for each position.
                  </li>
                  <li>
                    There is no requirement for you to set player selection limits for each
                    position.
                  </li>
                  <li>
                    Members can select unlimited players from a certain position to pick their
                    fantasy team.
                  </li>
                  <li>This mode makes your game independent of position based restrictions.</li>
                </ul>
              </CCol>
            </>
          )}

          {!showPosBased && (
            <>
              <CInputGroup className="mb-3 fw-bold">
                <CInputGroupText className="fw-bold w-23" style={{ width: '170px' }}>
                  Min PG
                </CInputGroupText>
                <CFormInput
                  placeholder="Min PG"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.min_pg && formik.touched.min_pg ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.min_pg}
                  onChange={formik.handleChange}
                  aria-label="min_pg"
                  id="min_pg"
                />

                <CInputGroupText className="fw-bold">-</CInputGroupText>
                <CFormInput
                  placeholder="Max PG"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.max_pg && formik.touched.max_pg ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.max_pg}
                  onChange={formik.handleChange}
                  aria-label="max_pg"
                  id="max_pg"
                />
                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Max PG
                </CInputGroupText>
                {formik.errors.max_pg && formik.touched.max_pg && (
                  <CFormFeedback invalid>{formik.errors.max_pg}</CFormFeedback>
                )}
                {formik.errors.max_pg && formik.touched.max_pg && (
                  <CFormFeedback invalid className="d-inline-block">
                    {formik.errors.max_pg}
                  </CFormFeedback>
                )}
              </CInputGroup>

              <CInputGroup className="mb-3 fw-bold">
                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Min SG
                </CInputGroupText>
                <CFormInput
                  placeholder="Min SG"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.min_sg && formik.touched.min_sg ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.min_sg}
                  onChange={formik.handleChange}
                  aria-label="min_sg"
                  id="min_sg"
                />

                <CInputGroupText className="fw-bold">-</CInputGroupText>
                <CFormInput
                  placeholder="Max SG"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.max_sg && formik.touched.max_sg ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.max_sg}
                  onChange={formik.handleChange}
                  aria-label="max_sg"
                  id="max_sg"
                />

                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Max SG
                </CInputGroupText>
                {formik.errors.min_sg && formik.touched.min_sg && (
                  <CFormFeedback invalid>{formik.errors.min_sg}</CFormFeedback>
                )}
                {formik.errors.max_sg && formik.touched.max_sg && (
                  <CFormFeedback invalid>{formik.errors.max_sg}</CFormFeedback>
                )}
              </CInputGroup>

              <CInputGroup className="mb-3 fw-bold">
                <CInputGroupText className="fw-bold w-22" style={{ width: '170px' }}>
                  Min fwd
                </CInputGroupText>
                <CFormInput
                  placeholder="Min fwd"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.min_fwd && formik.touched.min_fwd ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.min_fwd}
                  onChange={formik.handleChange}
                  aria-label="min_fwd"
                  id="min_fwd"
                />

                <CInputGroupText className="fw-bold">-</CInputGroupText>
                <CFormInput
                  placeholder="Max fwd"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.max_fwd && formik.touched.max_fwd ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.max_fwd}
                  onChange={formik.handleChange}
                  aria-label="max_fwd"
                  id="max_fwd"
                />

                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Max fwd
                </CInputGroupText>
                {formik.errors.min_fwd && formik.touched.min_fwd && (
                  <CFormFeedback invalid>{formik.errors.min_fwd}</CFormFeedback>
                )}
                {formik.errors.max_fwd && formik.touched.max_fwd && (
                  <CFormFeedback invalid>{formik.errors.max_fwd}</CFormFeedback>
                )}
              </CInputGroup>

              <CInputGroup className="mb-3 fw-bold">
                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Min CEN
                </CInputGroupText>
                <CFormInput
                  placeholder="Min CEN"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.min_cen && formik.touched.min_cen ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.min_cen}
                  onChange={formik.handleChange}
                  aria-label="min_cen"
                  id="min_cen"
                />

                <CInputGroupText className="fw-bold">-</CInputGroupText>
                <CFormInput
                  placeholder="Max CEN"
                  disabled={props.isTeamExists}
                  className={
                    'form-control' +
                    (formik.errors.max_cen && formik.touched.max_cen ? ' is-invalid' : '')
                  }
                  defaultValue={formik.values.max_cen}
                  onChange={formik.handleChange}
                  aria-label="max_cen"
                  id="max_cen"
                />

                <CInputGroupText className="fw-bold" style={{ width: '170px' }}>
                  Max CEN
                </CInputGroupText>
                {formik.errors.min_cen && formik.touched.min_cen && (
                  <CFormFeedback invalid>{formik.errors.min_cen}</CFormFeedback>
                )}
                {formik.errors.max_cen && formik.touched.max_cen && (
                  <CFormFeedback invalid>{formik.errors.max_cen}</CFormFeedback>
                )}
              </CInputGroup>
              <CCol md={6}>
                <CLoadingButton
                  type="submit"
                  color="success"
                  variant="outline"
                  loading={loader}
                  disabled={props.isTeamExists}
                  id="submit"
                >
                  Submit
                </CLoadingButton>
              </CCol>
            </>
          )}
        </CForm>
      </CCol>
      <Bootbox
        show={showPosConfirm}
        type={'confirm'}
        message={modalText}
        onSuccess={handlePosConfirm}
        onCancel={handlePosCancel}
        onClose={handlePosCancel}
      />
    </>
  )
}

export default PlayerStructure
