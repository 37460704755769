import React from 'react'
import { CCard, CCardBody, CCardHeader, CFormSelect, CLoadingButton } from '@coreui/react-pro'

const WinnerListing = (props) => {
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Round {props.bracketStructure?.round} Winners</strong>
        </CCardHeader>
        <CCardBody>
          <table className="main-table table innertable">
            <thead>
              <tr>
                <th>BATTLE</th>
                <th>USERNAME</th>
              </tr>
            </thead>
            <tbody>
              {props.getWinners &&
                props.getWinners?.map((item, key) => (
                  <tr key={key}>
                    <th>{item?.battle}</th>
                    <td>{item?.full_name}</td>
                  </tr>
                ))}
              {props.getWinners.length === 0 && (
                <tr>
                  <td colSpan={2}>No record yet available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCardBody>
      </CCard>
    </>
  )
}

export default WinnerListing
