import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CFormLabel,
  CNav,
  CNavLink,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent.js'
import UserService from 'src/service/UserService'
import GameListingModal from './GameListingModal'
const AdminUserList = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [selectedId, setSelectedId] = useState(0)
  const [users, setUsers] = useState(props.users)
  const navigate = useNavigate()

  const [details, setDetails] = useState([])
  const columns = [
    {
      label: 'Member',
      key: 'full_name',
    },
    { key: 'club_name', label: 'Hub Name' },
    { key: 'email' },
    // { label: 'Team Name', key: 'my_team_name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Registered', key: 'created_at' },
    { label: 'Status', filter: false, key: 'is_verified' },
    {
      key: 'action',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const deleteUser = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    UserService.deleteUser(data).then((res) => {
      if (res.status === 200) {
        toast.dismiss()
        setUsers(res.data)
        ToastComponent(res.message, 'success')
        setLoading(false)
        navigate('/grades')
      }
    })
  }
  const [adminTabActive, setAdminTabActive] = useState(true)
  const [userTabActive, setUserTabActive] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetchData(
      activePage,
      columnFilter,
      columnSorter,
      itemsPerPage,
      props,
      adminTabActive,
      userTabActive,
    )
  }, [activePage, columnFilter, columnSorter, itemsPerPage, props, adminTabActive, userTabActive])
  const fetchData = (
    activePage,
    columnFilter,
    columnSorter,
    itemsPerPage,
    props,
    adminTabActive,
    userTabActive,
  ) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      setLoading(true)
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    UserService.getAdminUserListing(
      offset,
      itemsPerPage,
      activePage,
      params,
      adminTabActive,
      userTabActive,
    )
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }
  const handleAdminTabActive = () => {
    setAdminTabActive(true)
    setUserTabActive(false)
  }
  const handleUserTabActive = () => {
    setUserTabActive(true)
    setAdminTabActive(false)
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  const [userDataListing, setUserDataListing] = useState([])
  function openFromParent(accountId) {
    UserService.getGameUser(accountId).then((result) => {
      setUserDataListing(result.data)
    })
    setIsOpen(true)
  }
  const changeAccountStatus = (userId, status) => {
    const data = {}
    data.userId = userId
    data.status = status
    UserService.changeAccountStatus(data).then((result) => {
      ToastComponent(result.message, 'success')
      fetchData(
        activePage,
        columnFilter,
        columnSorter,
        itemsPerPage,
        props,
        adminTabActive,
        userTabActive,
      )
    })
  }
  const changeVerifiedStatus = (userId, status) => {
    const data = {}
    data.userId = userId
    data.status = status
    UserService.changeVerifiedStatus(data).then((result) => {
      ToastComponent(result.message, 'success')
      fetchData(
        activePage,
        columnFilter,
        columnSorter,
        itemsPerPage,
        props,
        adminTabActive,
        userTabActive,
      )
    })
  }
  return (
    <>
      <CNav component="nav" variant="pills" className="flex-column flex-sm-row">
        <CNavLink href="#" active={adminTabActive} onClick={handleAdminTabActive}>
          Game Admin
        </CNavLink>
        <CNavLink active={userTabActive} onClick={handleUserTabActive}>
          Users
        </CNavLink>
      </CNav>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          is_verified: (item) => (
            <td>
              <CBadge color={getBadge(item.is_verified)}>
                {' '}
                {item.is_verified === 1 ? 'Verified' : 'Not-Verified'}
              </CBadge>
            </td>
          ),
          action: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(item.id)
                    }}
                  >
                    {details.includes(item.id) ? 'Hide' : 'Info'}
                  </CButton>{' '}
                </td>
              </>
            )
          },
          details: (item) => {
            return (
              <CCollapse visible={details.includes(item.id)}>
                <CCardBody>
                  <CRow>
                    <CCol xs="auto">
                      <span className="px-2">
                        {' '}
                        {/* <Link
                          to={`${process.env.REACT_APP_FRONT_URL}adminLogin/${item.id}`}
                          target="_blank"
                        >
                          Login
                        </Link>{' '} */}
                        <CButton
                          component="a"
                          color="primary"
                          href={`${process.env.REACT_APP_FRONT_URL}adminLogin/${item.id}`}
                          target="_blank"
                          role="button"
                          size="sm"
                        >
                          Login
                        </CButton>
                        {item.user_role_id === 3 && (
                          <>
                            <CButton
                              className="ml-1"
                              color={'success'}
                              size="sm"
                              onClick={(event) => {
                                event.preventDefault()
                                openFromParent(item.id)
                              }}
                            >
                              Games
                            </CButton>

                            {item.is_active === 1 && (
                              <CButton
                                className="ml-1"
                                color={'info'}
                                size="sm"
                                onClick={() => changeAccountStatus(item?.id, 0)}
                              >
                                Hide Hub
                              </CButton>
                            )}

                            {item.is_active === 0 && (
                              <CButton
                                className="ml-1"
                                color={'info'}
                                size="sm"
                                onClick={() => changeAccountStatus(item?.id, 1)}
                              >
                                Show Hub
                              </CButton>
                            )}
                          </>
                        )}
                        {item.is_verified === 1 && (
                          <CButton
                            className="ml-1"
                            color={'success'}
                            size="sm"
                            onClick={() => changeVerifiedStatus(item?.id, 0)}
                          >
                            Verified
                          </CButton>
                        )}
                        {item.is_verified === 0 && (
                          <CButton
                            className="ml-1"
                            color={'danger'}
                            size="sm"
                            onClick={() => changeVerifiedStatus(item?.id, 1)}
                          >
                            Unverifed
                          </CButton>
                        )}
                      </span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            )
          },
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />

      <GameListingModal
        IsModalOpened={modalIsOpen}
        onCloseModal={setIsOpen}
        userDataListing={userDataListing}
      />
    </>
  )
}

export default AdminUserList
