import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent.js'
import PlayerService from 'src/service/PlayerService'
import EditForm from './EditForm'
import Notify from '../Notify'
import CommonService from 'src/service/CommonService'
import HeadToHeadService from 'src/service/HeadToHeadService'
const Table = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [selectedPlayerId, setSelectedPlayerId] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState(props.users)
  const navigate = useNavigate()

  const [details, setDetails] = useState([])
  const columns = [
    {
      label: 'Round',
      key: 'round',
    },
    {
      label: 'First Member',
      key: 'fm_name',
    },
    {
      label: 'First Member PTS',
      key: 'fm_points',
    },
    { label: 'Second First Member', key: 'sm_name' },
    {
      label: 'Second Member PTS',
      key: 'sm_points',
    },
    {
      label: 'Winner',
      key: 'winner',
    },
  ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'danger'
      default:
        return 'danger'
    }
  }

  const checkInUse = (user_team_player_exists, team_player_exists, player_id) => {
    if (user_team_player_exists === player_id) {
      return <CBadge color={'danger'}>In-Use </CBadge>
    }
    if (team_player_exists === player_id) {
      return <CBadge color={'danger'}>In-Use </CBadge>
    }
    return <CBadge color={'success'}>Not In-Use </CBadge>
  }
  const toggleDetails = (index) => {
    setSelectedPlayerId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const deletePlayer = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    // setUsers((previousEmployeeData) => previousEmployeeData.data.filter((data) => data.id !== id))
    PlayerService.deletePlayer(data).then((res) => {
      if (res.status === 200) {
        props.setUsers((current) => current.filter((fruit) => fruit.id !== id))
        ToastComponent(res.message, 'success')
        setLoading(false)
      }
    })
  }

  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    HeadToHeadService.getHeadToHead(offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }, [activePage, columnFilter, columnSorter, itemsPerPage, props])

  // Are you sure want modal
  const [handleYes, setHandleYes] = useState(false)
  const [statusHandleYes, setStatusHandleYes] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [statusShowConfirm, setStatusShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)
  const [statusHandleNo, setStatusHandleNo] = useState(false)
  const [tableId, setTableId] = useState(false)
  const [playerStatus, setPlayerStatus] = useState(false)

  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }
  const handleStatusCancel = () => {
    console.log('You clicked No!')
    return setStatusShowConfirm(false)
  }

  const handleConfirm = () => {
    deletePlayer(tableId)
    return setShowConfirm(false)
  }
  const handleStatusConfirm = () => {
    handleStatus(tableId, playerStatus)
    return setStatusShowConfirm(false)
  }
  const areYouSureModal = (id) => {
    const data = {}
    data.id = id
    data.type = 'player'
    CommonService.checkItemExists(data).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          ToastComponent(res.message, 'error')
        } else {
          setShowConfirm(true)
          setTableId(id)
        }
      }
    })
  }
  const handleAreYouSureStatus = (id, status) => {
    const data = {}
    data.id = id
    data.type = 'player_status'
    CommonService.checkItemExists(data).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          ToastComponent(res.message, 'error')
        } else {
          setStatusShowConfirm(true)
          setTableId(id)
          setPlayerStatus(status)
        }
      }
    })
  }

  const handleStatus = (id, status) => {
    PlayerService.updateStatus(id, status)
      .then((res) => {
        const newApiData = res.data
        if (res.status === 200) {
          const newState = users.data.map((obj) => {
            // 👇️ if id equals 2, update country property
            if (obj.id === id) {
              return { ...obj, newApiData }
            }

            // 👇️ otherwise return the object as is
            return obj
          })

          props.setUsers(newState)
          ToastComponent(res.message, 'success')
        }
      })
      .catch((e) => {
        console.log('Catch Block', e)
      })
  }
  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          svalue: (item) => <td>${item.svalue}m</td>,
          is_active: (item) => (
            <td>{checkInUse(item?.user_team_player_exists, item?.team_player_exists, item.id)}</td>
          ),
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(item.id)
                    }}
                  >
                    {details.includes(item.id) ? 'Hide' : 'Manage'}
                  </CButton>
                </td>
              </>
            )
          },
          details: (item) => {
            return (
              <CCollapse visible={details.includes(item.id)}>
                <CCardBody>
                  <CButton
                    size="sm"
                    color="success"
                    className="ml-1"
                    onClick={() => setVisibleHorizontal(!visibleHorizontal)}
                    aria-expanded={visibleHorizontal}
                    aria-controls="collapseEdit"
                  >
                    Edit
                  </CButton>
                  <CButton
                    size="sm"
                    color="danger"
                    className="ml-1"
                    onClick={() => areYouSureModal(item.id)}
                  >
                    Delete
                  </CButton>
                  {item.is_active === 1 ? (
                    <CButton
                      size="sm"
                      color="dark"
                      className="ml-1"
                      onClick={() => handleAreYouSureStatus(item.id, 0)}
                    >
                      Deactivate
                    </CButton>
                  ) : (
                    <CButton
                      size="sm"
                      color="dark"
                      className="ml-1"
                      onClick={() => handleAreYouSureStatus(item.id, 1)}
                    >
                      Activate
                    </CButton>
                  )}
                  <CCollapse id="collapseEdit" horizontal visible={visibleHorizontal}>
                    <CCard className="mb-4">
                      <CCardHeader>
                        <strong>Edit Player</strong>
                      </CCardHeader>
                      <CCardBody>
                        <EditForm
                          playerId={item.id}
                          selectedPlayerId={selectedPlayerId}
                          setUsers={setUsers}
                        />
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </CCardBody>
              </CCollapse>
            )
          },
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleYes={handleYes}
        setHandleYes={setHandleYes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are you sure you want to delete this?"
      />
      <Notify
        setShowConfirm={setStatusShowConfirm}
        showConfirm={statusShowConfirm}
        setHandleNo={setStatusHandleNo}
        handleNo={statusHandleNo}
        handleYes={statusHandleYes}
        setHandleYes={setStatusHandleYes}
        handleConfirm={handleStatusConfirm}
        handleCancel={handleStatusCancel}
        text="Are you sure you want to change player status?"
      />
    </>
  )
}

export default Table
