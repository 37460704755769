import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSwitch,
} from '@coreui/react-pro'
import Table from './Table'
import AddForm from './AddForm'
import GameNotificationService from 'src/service/GameNotificationService'
import { useEffect } from 'react'
const GameNotification = () => {
  const [users, setUsers] = useState([])
  const [completedFixtureShow, setCompletedFixtureShow] = useState(false)
  const [tradingShow, setTradingShow] = useState(false)
  const [lockoutShow, setLockoutShow] = useState(false)

  useEffect(() => {
    GameNotificationService.getAutoNotificationSetting().then((res) => {
      if (res.status === 200) {
        setCompletedFixtureShow(res.data?.fixture_notification)
        setTradingShow(res.data?.trading_notification)
        setLockoutShow(res.data?.lockout_notification)
      }
    })
  }, [])
  const handleChangeStatus = (event, type) => {
    if (type === 'fixture') {
      setCompletedFixtureShow(event.target.checked)
    }
    if (type === 'trading') {
      setTradingShow(event.target.checked)
    }
    if (type === 'lockout') {
      setLockoutShow(event.target.checked)
    }
    const data = {}
    data.value = event.target.checked
    data.type = type
    GameNotificationService.changeAutoNotificationSetting(data).then((res) => {
      if (res.status === 200) {
        // setShow(res.data)
      }
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Notification</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm setUsers={setUsers} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Notifications</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table users={users} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Game Notifications</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CFormSwitch
              size="lg"
              label="Completed Fixtures"
              id="fixture"
              // checked={isRadio === item.id}
              checked={completedFixtureShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'fixture')}
            />
            <br></br>
            <CFormSwitch
              size="lg"
              label="Trading Window Open"
              id="trading"
              // checked={isRadio === item.id}
              checked={tradingShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'trading')}
            />
            <br></br>
            <CFormSwitch
              size="lg"
              label="Game Lockout"
              id="lockout"
              // checked={isRadio === item.id}
              checked={lockoutShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'lockout')}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default GameNotification
