import React from 'react'
import moment from 'moment'
import { CButton, CSmartTable } from '@coreui/react-pro'
const ManageVerifiedPlayer = (props) => {
  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          created_at: (item) => <td>{moment(item.updated_at).format('D.MM.YYYY')}</td>,
          actions: (item) => (
            <td>
              <CButton
                color={'danger'}
                size="sm"
                onClick={() => props.handleDeleteRequest(item.id)}
              >
                Delete
              </CButton>
            </td>
          ),
        }}
        items={props.users.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect={false}
        loading={props.loading}
        pagination={true}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
    </>
  )
}

export default ManageVerifiedPlayer
