import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ShowCaseService from 'src/service/ShowCaseService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
const EditForm = (props) => {
  const [showCaseDetail, setShowCaseDetail] = useState()
  const [shortDescription, setShortDescription] = useState()
  useEffect(() => {
    if (props.selectedShowCaseId === props.showCaseId) {
      ShowCaseService.getShowCaseDetail(props.showCaseId).then((res) => {
        if (res.status === 200) {
          setShowCaseDetail(res.data)
          setShortDescription(res?.data.short_description)
        }
      })
    }
  }, [props])

  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(50, '50 Character Limit is allowed.'),
  })
  const formik = useFormik({
    initialValues: {
      title: showCaseDetail?.title,
      short_description: showCaseDetail?.short_description,
      point1: showCaseDetail?.point1,
      point2: showCaseDetail?.point2,
      point3: showCaseDetail?.point3,
      point1_description: showCaseDetail?.point1_description,
      point2_description: showCaseDetail?.point2_description,
      point3_description: showCaseDetail?.point3_description,
      feature_images: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      var formData = new FormData()
      formData.append('showCaseId', props.showCaseId)
      formData.append('title', data.title)
      formData.append('short_description', shortDescription)
      formData.append('point1', data.point1)
      formData.append('point2', data.point2)
      formData.append('point3', data.point3)
      formData.append('point1_description', data.point1_description)
      formData.append('point2_description', data.point2_description)
      formData.append('point3_description', data.point3_description)

      data.feature_images.forEach((image) => {
        formData.append('feature_images[]', image)
      })

      setLoader(true)
      ShowCaseService.editShowCase(formData)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent('something went wrong.', 'error')
          }
        })
        .catch((e) => {
          console.log(e)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Title
          </CFormLabel>
          <input
            type="text"
            name="title"
            className={
              'form-control' + (formik.errors.title && formik.touched.title ? ' is-invalid' : '')
            }
            id="title"
            placeholder="title"
            defaultValue={showCaseDetail?.title}
            onChange={formik.handleChange}
          />
          {formik.errors.title && formik.touched.title && (
            <CFormFeedback invalid>{formik.errors.title}</CFormFeedback>
          )}
        </CCol>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Short Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={shortDescription}
            onChange={setShortDescription}
          />
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Point 1
          </CFormLabel>
          <input
            type="text"
            name="point1"
            className={
              'form-control' + (formik.errors.point1 && formik.touched.point1 ? ' is-invalid' : '')
            }
            id="point1"
            placeholder="point1"
            defaultValue={showCaseDetail?.point1}
            onChange={formik.handleChange}
          />
          {formik.errors.point1 && formik.touched.point1 && (
            <CFormFeedback invalid>{formik.errors.point1}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Point 2
          </CFormLabel>
          <input
            type="text"
            name="point2"
            className={
              'form-control' + (formik.errors.point2 && formik.touched.point2 ? ' is-invalid' : '')
            }
            id="point2"
            placeholder="point2"
            defaultValue={showCaseDetail?.point2}
            onChange={formik.handleChange}
          />
          {formik.errors.point2 && formik.touched.point2 && (
            <CFormFeedback invalid>{formik.errors.point2}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Point 3
          </CFormLabel>
          <input
            type="text"
            name="point3"
            className={
              'form-control' + (formik.errors.point3 && formik.touched.point3 ? ' is-invalid' : '')
            }
            id="point3"
            placeholder="point3"
            defaultValue={showCaseDetail?.point3}
            onChange={formik.handleChange}
          />
          {formik.errors.point3 && formik.touched.point1 && (
            <CFormFeedback invalid>{formik.errors.point1}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="message">
            Point 1 Description
          </CFormLabel>
          <CFormTextarea
            rows="2"
            placeholder="Point 1 Description"
            className={
              'form-control' +
              (formik.errors.point1_description && formik.touched.point1_description
                ? ' is-invalid'
                : '')
            }
            defaultValue={formik.values.point1_description}
            onChange={formik.handleChange}
            aria-label="point1_description"
            id="point1_description"
          ></CFormTextarea>
          {formik.errors.point1_description && formik.touched.point1_description && (
            <CFormFeedback invalid>{formik.errors.point1_description}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="message">
            Point 2 Description
          </CFormLabel>
          <CFormTextarea
            rows="2"
            placeholder="Point 2 Description"
            className={
              'form-control' +
              (formik.errors.point2_description && formik.touched.point2_description
                ? ' is-invalid'
                : '')
            }
            defaultValue={formik.values.point2_description}
            onChange={formik.handleChange}
            aria-label="point2_description"
            id="point2_description"
          ></CFormTextarea>
          {formik.errors.point2_description && formik.touched.point2_description && (
            <CFormFeedback invalid>{formik.errors.point2_description}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="message">
            Point 3 Description
          </CFormLabel>
          <CFormTextarea
            rows="2"
            placeholder="Point 3 Description"
            className={
              'form-control' +
              (formik.errors.point3_description && formik.touched.point3_description
                ? ' is-invalid'
                : '')
            }
            defaultValue={formik.values.point3_description}
            onChange={formik.handleChange}
            aria-label="point3_description"
            id="point3_description"
          ></CFormTextarea>
          {formik.errors.point3_description && formik.touched.point3_description && (
            <CFormFeedback invalid>{formik.errors.point3_description}</CFormFeedback>
          )}
        </CCol>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Feature Images
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="feature_images"
            className={
              formik.touched.feature_images
                ? formik.errors.feature_images
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            multiple // Add this attribute to allow selecting multiple files
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                feature_images: true,
              })

              const files = event.target.files
              const fileArray = Array.from(files) // Convert FileList to an array

              formik.setFieldValue('feature_images', fileArray)
            }}
          />
          {formik.touched.feature_images && formik.errors.feature_images ? (
            <CFormFeedback invalid>{formik.errors.feature_images}</CFormFeedback>
          ) : null}
          <CRow>
            {' '}
            {/* Displaying preview for multiple selected images is more complex and might require additional logic */}
            {formik.values.feature_images ? (
              <>
                {Array.from(formik.values.feature_images).map((file, index) => (
                  <PreviewImage
                    key={index}
                    className={{ margin: 'auto' }}
                    width={100}
                    height={100}
                    file={file}
                  />
                ))}
              </>
            ) : null}
          </CRow>
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EditForm
