import { CFormSwitch } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import TeamService from 'src/service/TeamService'

const ActivateTeam = (props) => {
  const handleChangeStatus = (event) => {
    props.setShow(event.target.checked)
    const data = {}
    data.status = event.target.checked

    TeamService.activateTeamStatus(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <div>
      <CFormSwitch
        size="lg"
        label="Show/Hide Teams"
        id="formSwitchCheckDefaultLg"
        // checked={isRadio === item.id}
        checked={props.show ? true : false}
        onChange={handleChangeStatus}
      />
    </div>
  )
}

export default ActivateTeam
