import React, { useState } from 'react'
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CListGroup,
  CListGroupItem,
  CForm,
  CFormFeedback,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'

const DefaultSalarySymbol = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    default_salary_symbol: Yup.string().required('Select Default salary symbol is required'),
  })
  const formik = useFormik({
    initialValues: {
      default_salary_symbol: props.defaultSalarySymbol,
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      CommonService.updateDefaultSalarySymbol(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setLoader(false)
          props.fetchData()
        } else {
          setLoader(false)
          ToastComponent(res.message, 'error')
        }
      })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CRow className="">
          <CCol md={12} xs={12}>
            <CFormLabel className="fw-bold pt-2" htmlFor="team">
              Select Default Salary Symbol
            </CFormLabel>

            <CFormSelect
              aria-label="Select Team"
              name="default_salary_symbol"
              className={
                'form-control' +
                (formik.errors.default_salary_symbol && formik.touched.default_salary_symbol
                  ? ' is-invalid'
                  : '')
              }
              defaultValue={formik.values.default_salary_symbol}
              onChange={formik.handleChange}
              id="default_salary_symbol"
            >
              <option value="€">EUR0 (€) </option>
              <option value="$">DOLLAR ($)</option>
              <option value="£">POUND (£)</option>
              <option value="">NONE</option>
            </CFormSelect>
            {formik.errors.default_salary_symbol && formik.touched.default_salary_symbol && (
              <CFormFeedback invalid>{formik.errors.default_salary_symbol}</CFormFeedback>
            )}
          </CCol>
        </CRow>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default DefaultSalarySymbol
