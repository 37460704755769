import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
  CInputGroupText,
  CFormCheck,
  CRow,
  CFormFeedback,
  CDatePicker,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CTooltip,
  CButton,
  CCollapse,
} from '@coreui/react-pro'
import { cilInfo } from '@coreui/icons'
import { DocsExample } from 'src/components'
import ClubService from 'src/service/ClubService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import Loader from 'src/components/Loader'
import GameSocial from './GameSocial'
import GameIntro from './GameIntro'
import AboutGame from './AboutGame'
import FeeInfo from './FeeInfo'
import BasicSetting from './BasicSetting'
import TermCondition from './TermCondition'

const ClubAboutGame = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [userDetail, setUserDetail] = useState({})
  const [gameDetail, setGameDetail] = useState({})
  const [timezone, setTimezone] = useState()
  const [userFile, setUserFile] = useState()
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [termcondition, setTermCondition] = useState()
  const [entryFeeInfo, setEntryFeeInfo] = useState()
  const [entryMessage, setEntryMessage] = useState()
  useEffect(() => {
    ClubService.getClubDetails().then((res) => {
      setLoading(true)
      if (res.status === 200) {
        const userDetailData = res.user_details
        setUserDetail((prevState) => ({ ...prevState, userDetailData }))
        setGameDetail(res.game_details)
        setTermCondition(res.game_details.termcondition)
        setTimezone(res.game_details?.timezone)
        setEntryFeeInfo(res.game_details?.entry_fee_info)
        setEntryMessage(res.game_details?.entry_message)
        setUserFile(res?.user_files)
        setCountryList(res?.country_list)

        setStateList(res?.state_list)
        if (res.user_details.show_email === 1) {
          setShowEmail(true)
        }
        if (res.user_details.show_name === 1) {
          setShowName(true)
        }
        if (res.user_details.gender === 'male') {
          setShowMale('male')
        }
        if (res.user_details.gender === 'female') {
          setShowFemale('female')
        }
        if (res.user_details.gender === 'Do not wish to ') {
          setShowOtherGender('Do not wish to ')
        }
        setLoading(false)
      }
    })
  }, [])

  const FILE_SIZE = 160 * 1024
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    first_name: Yup.string().required('First Name is required').max(50),
    last_name: Yup.string().required('Last Name is required').max(50),
    // dob: Yup.string().required('DOB is required'),
    phone: Yup.string().required('Phone is required'),
    // image: Yup.mixed()
    //   .required('A file is required')
    //   .test('fileSize', 'File too large', (value) => value && value.size <= FILE_SIZE)
    //   .test(
    //     'fileFormat',
    //     'Unsupported Format',
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type),
    //   ),
  })

  const formik = useFormik({
    initialValues: {
      first_name: gameDetail?.first_name,
      last_name: gameDetail?.last_name,
      email: gameDetail?.email,
      // dob: gameDetail?.dob,
      phone: gameDetail?.phone,
      // gender: gameDetail?.gender,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      setLoader(true)
      data['user_id'] = gameDetail?.id
      ClubService.updateGameAdmin(data)
        .then((res) => {
          if (res.status === 200) {
            setLoader(false)
            ToastComponent(res.message, 'success')
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          setLoader(false)
          ToastComponent(e.response?.data?.message, 'error')
        })
    },
  })

  const [loader, setLoader] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showName, setShowName] = useState(false)

  const [showMale, setShowMale] = useState('male')
  const [showFemale, setShowFemale] = useState('female')
  const [showOtherGender, setShowOtherGender] = useState('Do not wish to ')

  const handleEmailChange = () => {
    setShowEmail((current) => !current)
  }
  const handleNameChange = () => {
    setShowName((current) => !current)
  }

  const handleMaleChange = (event) => {
    setShowMale(event.target.value)
  }

  const handleFemaleChange = (event) => {
    setShowFemale(event.target.value)
  }
  const handleOtherChange = (event) => {
    setShowOtherGender(event.target.value)
  }

  const handleOpenModal = () => {
    alert('opened')
  }
  const [visible, setVisible] = useState(false)
  return isLoading ? (
    <Loader />
  ) : (
    <CRow>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <CTooltip
              content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              placement="top"
            >
              <strong>Game Details</strong>
            </CTooltip>
          </CAccordionHeader>
          <CAccordionBody>
            <BasicSetting />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Timezone</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AboutGame userDetail={gameDetail} timezone={timezone} setTimezone={setTimezone} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Welcome Message</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <FeeInfo
              gameDetail={gameDetail}
              entryFeeInfo={entryFeeInfo}
              setEntryFeeInfo={setEntryFeeInfo}
              entryMessage={entryMessage}
              setEntryMessage={setEntryMessage}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Terms & Conditions</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <TermCondition
              userDetail={gameDetail}
              termcondition={termcondition}
              setTermCondition={setTermCondition}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Game Admin</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <form className="row g-3" onSubmit={formik.handleSubmit}>
              <CCol md={6}>
                <CFormLabel className="fw-bold" htmlFor="first_name">
                  First Name *
                </CFormLabel>
                <CInputGroup className="">
                  <CInputGroupText>
                    <input
                      type="checkbox"
                      checked={showName}
                      name="show_name"
                      defaultValue={1}
                      onChange={handleNameChange}
                    />
                  </CInputGroupText>
                  <input
                    type="text"
                    name="first_name"
                    className={
                      'form-control' +
                      (formik.errors.first_name && formik.touched.first_name ? ' is-invalid' : '')
                    }
                    id="first_name"
                    placeholder="First Name"
                    defaultValue={gameDetail?.first_name}
                    onChange={formik.handleChange}
                  />

                  {formik.errors.first_name && formik.touched.first_name && (
                    <CFormFeedback invalid>{formik.errors.first_name}</CFormFeedback>
                  )}
                </CInputGroup>
                <small>
                  <i> ({`Tick to hide admin's Full Name from members`})</i>
                </small>
              </CCol>
              <CCol md={6}>
                <CFormLabel className="fw-bold" htmlFor="alast_name">
                  Last Name *
                </CFormLabel>
                <input
                  type="text"
                  name="last_name"
                  className={
                    'form-control' +
                    (formik.errors.last_name && formik.touched.last_name ? ' is-invalid' : '')
                  }
                  id="validationServer01"
                  placeholder="Last Name"
                  defaultValue={gameDetail?.last_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                  <CFormFeedback invalid>{formik.errors.last_name}</CFormFeedback>
                )}
              </CCol>

              <CCol md={6}>
                <CFormLabel className="fw-bold" htmlFor="phone">
                  Email *
                </CFormLabel>
                <CInputGroup className="">
                  <CInputGroupText>
                    <input
                      type="checkbox"
                      checked={showEmail}
                      defaultValue={1}
                      name="show_email"
                      onChange={handleEmailChange}
                    />
                  </CInputGroupText>
                  <input
                    type="text"
                    name="email"
                    className={
                      'form-control' +
                      (formik.errors.email && formik.touched.email ? ' is-invalid' : '')
                    }
                    id="email"
                    placeholder="Email"
                    defaultValue={gameDetail?.email}
                    onChange={formik.handleChange}
                  />

                  {formik.errors.email && formik.touched.email && (
                    <CFormFeedback invalid>{formik.errors.email}</CFormFeedback>
                  )}
                </CInputGroup>
                <small>
                  <i> ({`Tick to hide admin's Email from members`})</i>
                </small>
              </CCol>
              <CCol md={6}>
                <CFormLabel className="fw-bold" htmlFor="phone">
                  Phone *
                </CFormLabel>
                <input
                  type="text"
                  name="phone"
                  className={
                    'form-control' +
                    (formik.errors.phone && formik.touched.phone ? ' is-invalid' : '')
                  }
                  id="phone"
                  placeholder="Phone"
                  defaultValue={gameDetail?.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <CFormFeedback invalid>{formik.errors.phone}</CFormFeedback>
                )}
              </CCol>

              {/* <CCol md={6}>
                <CFormLabel className='fw-bold' htmlFor="new_password">New Password</CFormLabel>
                <CFormInput
                  type="password"
                  name="password"
                  placeholder="New password"
                  aria-label="New password"
                />
              </CCol>
              <CCol md={6}>
                <CFormLabel className='fw-bold' htmlFor="confirm password">Confirm Password</CFormLabel>
                <CFormInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password"
                  aria-label="Confirm password"
                />
              </CCol> */}

              {/*
              <CCol md={6}>
                <div className="mb-3">
                  <CFormLabel className='fw-bold' htmlFor="formFile">Profile Image</CFormLabel>
                  <CFormInput type="file" id="formFile" name="image" />
                  {formik.errors.image && formik.touched.image && (
                    <CFormFeedback invalid>{formik.errors.image}</CFormFeedback>
                  )}
                </div>
              </CCol> */}

              <CCol md={6}>
                <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
                  Submit
                </CLoadingButton>
              </CCol>
            </form>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Social Links</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <GameSocial userDetail={gameDetail} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      {/* <CAccordion alwaysOpen activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Videos & Photos</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <GameIntro userFile={userFile} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion> */}
    </CRow>
  )
}

export default ClubAboutGame
