import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FeatureService from 'src/service/FeatureService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
const EditForm = (props) => {
  const [featureDetail, setFeatureDetail] = useState()
  const [shortDescription, setShortDescription] = useState()
  const [longDescription, setLongDescription] = useState()
  useEffect(() => {
    if (props.selectedFeatureId === props.featureId) {
      FeatureService.getFeatureDetail(props.featureId).then((res) => {
        if (res.status === 200) {
          setFeatureDetail(res.data)
          setShortDescription(res?.data.short_description)
          setLongDescription(res?.data.long_description)
        }
      })
    }
  }, [props])

  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(50, '50 Character Limit is allowed.'),
    type: Yup.string().required('Type is required'),
    short_description: Yup.string().required('Short description is required'),
    long_description: Yup.string().required('Long description is required'),
  })
  const formik = useFormik({
    initialValues: {
      title: featureDetail?.title,
      short_description: featureDetail?.short_description,
      long_description: featureDetail?.long_description,
      type: featureDetail?.type,
      primary_image: null,
      secondary_image: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      var formData = new FormData()
      formData.append('featureId', props.featureId)
      formData.append('title', data.title)
      formData.append('type', data.type)
      formData.append('image', data.image)
      formData.append('short_description', shortDescription)
      formData.append('long_description', longDescription)
      formData.append('primary_image', data.primary_image)
      // Append each secondary image to formData
      data.secondary_image.forEach((image) => {
        formData.append('secondary_image[]', image)
      })
      setLoader(true)
      FeatureService.editFeature(formData)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent('something went wrong.', 'error')
          }
        })
        .catch((e) => {
          console.log(e)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Type *
          </CFormLabel>
          <CFormSelect
            aria-label="Default select example"
            name="type"
            className={
              'form-control' + (formik.errors.type && formik.touched.type ? ' is-invalid' : '')
            }
            value={formik.values.type}
            onChange={formik.handleChange}
            id="type"
          >
            <option value={0}>Select type</option>
            <option value={1}>Feature (Admin)</option>
            <option value={2}>Feature (Member)</option>
            <option value={3}>What&apos;s New</option>
            <option value={4}>Press Release</option>
            <option value={5}>Tour</option>
          </CFormSelect>
          {formik.errors.type && formik.touched.type && (
            <CFormFeedback invalid>{formik.errors.type}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Title
          </CFormLabel>
          <input
            type="text"
            name="title"
            className={
              'form-control' + (formik.errors.title && formik.touched.title ? ' is-invalid' : '')
            }
            id="title"
            placeholder="title"
            defaultValue={featureDetail?.title}
            onChange={formik.handleChange}
          />
          {formik.errors.title && formik.touched.title && (
            <CFormFeedback invalid>{formik.errors.title}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Short Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={shortDescription}
            onChange={setShortDescription}
          />
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Long Description
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={longDescription}
            onChange={setLongDescription}
          />
        </CCol>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Primary Image
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="primary_image"
            className={
              formik.touched.primary_image
                ? formik.errors.primary_image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                primary_image: true,
              })
              formik.setFieldValue('primary_image', event.target.files[0])
            }}
          />
          {formik.touched.primary_image && formik.errors.primary_image ? (
            <CFormFeedback invalid>{formik.errors.primary_image}</CFormFeedback>
          ) : null}
          <CRow>
            <CCol md={4}>
              {' '}
              {formik?.values?.primary_image ? (
                <PreviewImage
                  className={{ margin: 'auto' }}
                  width={100}
                  height={100}
                  file={formik.values.primary_image}
                />
              ) : null}
            </CCol>
          </CRow>
        </CCol>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Secondary Images
          </CFormLabel>
          <CFormInput
            type="file"
            id="formFile"
            name="secondary_image"
            className={
              formik.touched.secondary_image
                ? formik.errors.secondary_image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            multiple // Add this attribute to allow selecting multiple files
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                secondary_image: true,
              })

              const files = event.target.files
              const fileArray = Array.from(files) // Convert FileList to an array

              formik.setFieldValue('secondary_image', fileArray)
            }}
          />
          {formik.touched.secondary_image && formik.errors.secondary_image ? (
            <CFormFeedback invalid>{formik.errors.secondary_image}</CFormFeedback>
          ) : null}
          <CRow>
            {' '}
            {/* Displaying preview for multiple selected images is more complex and might require additional logic */}
            {formik.values.secondary_image ? (
              <>
                {Array.from(formik.values.secondary_image).map((file, index) => (
                  <PreviewImage
                    key={index}
                    className={{ margin: 'auto' }}
                    width={100}
                    height={100}
                    file={file}
                  />
                ))}
              </>
            ) : null}
          </CRow>
        </CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EditForm
