import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CFormLabel,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSelect,
} from '@coreui/react-pro'
import UserService from 'src/service/UserService'
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table'

const UserLeaderboard = (props) => {
  const [users, setUsers] = useState([])
  const [roundList, setRoundList] = useState([])
  const [roundId, setRoundId] = useState(0)
  const handleRound = (e) => {
    setRoundId(e.target.value)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'userdata.full_name',
      },
      {
        Header: 'Team Name',
        accessor: 'my_team_name',
      },
      {
        Header: 'Points',
        accessor: 'team_points',
      },
      {
        Header: 'Rank',
        accessor: 'team_rank',
      },
    ],
    [],
  )

  useEffect(() => {
    fetchData(roundId)
  }, [roundId])

  const fetchData = (roundId) => {
    UserService.getRank(roundId).then((result) => {
      setUsers(result.data?.user_teams)
      setRoundList(result.data?.round_list)
    })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: users,
    },
    useGlobalFilter,
    useSortBy,
  )

  const { globalFilter } = state

  const debouncedSetGlobalFilter = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Fantasy Leaderboard</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow className="pb-3">
                <CCol className="col-md-6">
                  {' '}
                  <CFormSelect
                    aria-label="Select Round"
                    name="round"
                    className={'form-control'}
                    value={roundId}
                    onChange={handleRound}
                    id="round"
                  >
                    <option value={0}>Select Round</option>
                    {roundList &&
                      roundList.map((item, key) => (
                        <option value={item.id} key={key}>
                          Round {item?.round}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol className="col-md-6">
                  {' '}
                  <input
                    value={globalFilter || ''}
                    className="form-control"
                    onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
                    placeholder={'Search by Full Name'}
                  />
                </CCol>
              </CRow>

              <table {...getTableProps()} className="main-table table innertable">
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, columnIndex) => (
                        <th
                          key={columnIndex}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, key) => {
                    prepareRow(row)
                    return (
                      <tr key={key} {...row.getRowProps()}>
                        {row.cells.map((cell, columnIndex) => {
                          return (
                            <td key={columnIndex} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default UserLeaderboard
