import React, { useState } from 'react'
import {
  CForm,
  CRow,
  CFormInput,
  CCol,
  CFormSelect,
  CLoadingButton,
  CFormLabel,
  CFormFeedback,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
const SetMaxPlayerValue = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    max_limit: Yup.string().required('Max Limit is required'),
  })
  const formik = useFormik({
    initialValues: {
      max_limit: props.advanceMaxPlayerprice,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      setLoader(true)
      data.limit_type = 'max'
      CommonService.updateSetFantasyValueLimit(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            props.fetchData()
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          console.log('Here is Error', e)
          setLoader(false)
        })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CRow className="">
          <CCol md={12} xs={12}>
            <CFormLabel className="fw-bold pt-2" htmlFor="team">
              Maximum Fantasy Value *
            </CFormLabel>

            <CFormSelect
              aria-label="Select Team"
              name="max_limit"
              className={
                'form-control' +
                (formik.errors.max_limit && formik.touched.max_limit ? ' is-invalid' : '')
              }
              defaultValue={formik.values.max_limit}
              onChange={formik.handleChange}
              id="max_limit"
            >
              <option value="0">Select Max Limit </option>
              {Array.from({ length: 75 }, (_, index) => index + 16).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </CFormSelect>
            {formik.errors.max_limit && formik.touched.max_limit && (
              <CFormFeedback invalid>{formik.errors.max_limit}</CFormFeedback>
            )}
          </CCol>
        </CRow>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default SetMaxPlayerValue
