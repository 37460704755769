import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import Table from './Table'
import { useEffect } from 'react'
import FixtureVotingService from 'src/service/FixtureVotingService'
import ToastComponent from 'src/components/common/TaostComponent'
const TeamFixtureVoting = () => {
  const [data, setData] = useState([])
  const [playerListing, setPlayerListing] = useState([])
  const [votingListing, setVotingListing] = useState([])
  useEffect(() => {
    FixtureVotingService.teamFixtureVoting()
      .then((res) => {
        if (res.success) {
          setData(res.data)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong.Please try again', 'error')
      })
  }, [])
  const handlePlayerData = (teamId) => {
    setPlayerListing([])
    FixtureVotingService.teamFixtureVotingDetail(teamId)
      .then((res) => {
        if (res.success) {
          setPlayerListing(res.data)
          setVotingListing(res.voting_list)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong.Please try again', 'error')
      })
  }

  const calculateVotingValue = (record, votingListing) => {
    let votingValue = 0
    return votingListing.map((value, key1) => {
      const getFixtureVoting = record?.get_fixture_voting || [] // Check if get_FixtureVoting is defined
      const getVlasdasd = getFixtureVoting.filter((voting) => voting.voting_id === value.id).length
      const votingName = value.name
      votingValue += parseInt(votingName.replace(/[^0-9]/g, ''), 10) * getVlasdasd
      return <td key={key1}>{getVlasdasd}</td>
    })
  }

  return (
    <CRow>
      <CAccordion>
        {data &&
          data.map((item, key) => (
            <CAccordionItem itemKey={key} key={key}>
              <CAccordionHeader onClick={() => handlePlayerData(item?.team_id)}>
                <strong>
                  {item?.grade_name} | {item?.team_name}
                </strong>
              </CAccordionHeader>
              <CAccordionBody>
                <table className="main-table table table-bordered innertable">
                  <thead>
                    <tr>
                      <th>Player Name</th>
                      {votingListing &&
                        votingListing.map((item, key1) => <th key={key1}>{item?.name}</th>)}
                      <th>Total Votes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {playerListing &&
                      playerListing.map((item, key2) => (
                        <tr key={key2}>
                          <td>{item?.full_name}</td>
                          {calculateVotingValue(item, votingListing)}

                          <td>{item?.total_votes}</td>
                        </tr>
                      ))}
                    {playerListing.length <= 0 && (
                      <tr>
                        <td colSpan={4}>No record yet available.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </CAccordionBody>
            </CAccordionItem>
          ))}
        {data.length === 0 && <div>No records found</div>}
      </CAccordion>
    </CRow>
  )
}

export default TeamFixtureVoting
