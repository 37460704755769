import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import AddForm from './AddForm'
import Table from './Table'
import { useEffect } from 'react'
import AboutService from 'src/service/AboutService'
const About = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const columns = [
    {
      key: 'type',
    },
    { key: 'text1' },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    AboutService.getAbout(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }

  const [openCollapseId, setOpenCollapseId] = useState(null)

  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Add About</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm
              fetchData={fetchData}
              setLoading={setLoading}
              loading={loading}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
              itemsPerPage={itemsPerPage}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage About</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table
              users={users}
              activePage={activePage}
              setActivePage={setActivePage}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              columnSorter={columnSorter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              columns={columns}
              fetchData={fetchData}
              openCollapseId={openCollapseId}
              setOpenCollapseId={setOpenCollapseId}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default About
