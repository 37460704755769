import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSwitch,
} from '@coreui/react-pro'
import ToastComponent from 'src/components/common/TaostComponent'
import FixtureVotingService from 'src/service/FixtureVotingService'
const ActivateVoting = (props) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    FixtureVotingService.getVotingActivation()
      .then((res) => {
        if (res.status === 200) {
          setShow(res.data)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong.Please try again', 'error')
      })
  }, [])
  const handleChangeStatus = (event) => {
    setShow(event.target.checked)
    FixtureVotingService.changeVotingStatus(event.target.checked).then((res) => {
      if (res.status === 200) {
        // setShow(res.data)
      }
    })
  }
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Activate Fixture Voting</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CFormSwitch
              size="lg"
              label="Show/Hide Fixture Voting"
              id="formSwitchCheckDefaultLg"
              // checked={isRadio === item.id}
              checked={show ? true : false}
              onChange={handleChangeStatus}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default ActivateVoting
