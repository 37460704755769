import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import ExamplePdf from '../../assets/FixtureSampleFile.csv'
import FixtureService from 'src/service/FixtureService'

const ImportFixture = () => {
  const [file, setFile] = useState()
  const [array, setArray] = useState([])
  const fileReader = new FileReader()

  useEffect(() => {
    if (array.length > 0) {
      const FixtureCSVData = {}
      FixtureCSVData.fixtureData = array
      FixtureService.fixtureImport(FixtureCSVData).then((res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
        } else {
          ToastComponent(res.message, 'error')
        }
      })
    }
  }, [array])
  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',')
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i) => {
      const values = i.split(',')
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleImoprtPlayer = (e) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }
      fileReader.readAsText(file)
    }
  }
  const downloadCSVFile = () => {
    console.log('diwnload')
  }
  return (
    <>
      <CRow className="pt-2">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Fixture Import</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={6}>
                  <CFormInput
                    type="file"
                    id="playerFileId"
                    name="playerFileName"
                    accept={'.csv'}
                    onChange={handleOnChange}
                  />
                </CCol>
                <CCol md={6}>
                  <CLoadingButton
                    color="success"
                    variant="outline"
                    onClick={(e) => {
                      handleImoprtPlayer(e)
                    }}
                  >
                    Run Import
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Download Sample File</strong>
              <br></br>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <p>
                    <p>
                      Easily add multiple fixtures by using the fixtures import tool. Simply create
                      your fixtures list in a CSV (Comma Separated Values) format using the format
                      defined below. Once your file is ready, upload your CSV file and click
                      &apos;Import Fixtures&apos; button.
                    </p>
                  </p>
                  <p>
                    {' '}
                    <a
                      href={ExamplePdf}
                      download="Fixture-Sample-CSV"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CButton color={'primary'} variant="outline">
                        Download Sample CSV
                      </CButton>
                    </a>
                  </p>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Key Points</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <>
                    <div>
                      <ul>
                        <li>
                          Team code can be found next to the Team Name created in the Teams section
                        </li>
                        <li>
                          Valid Start Date format is:
                          <ul>
                            <li>DD.MM.YYYY</li>
                          </ul>
                        </li>
                        <li>
                          Valid End Date format is:
                          <ul>
                            <li>DD.MM.YYYY</li>
                          </ul>
                        </li>
                        <li>
                          Valid Start Time format is (12-Hr Format):
                          <ul>
                            <li>H:MM AM or H:MM PM</li>
                          </ul>
                        </li>
                        <li>
                          Valid End Time format is:
                          <ul>
                            <li>H:MM AM or H:MM PM</li>
                          </ul>
                        </li>
                        <li>
                          Start Date and Start Time of a Fixture MUST be within the Start Date/Start
                          Time and End Time of a Round
                        </li>
                        <li>
                          Required fields are Team Code, Start Date, End Date, Start Time, End Time
                        </li>
                        <li>There must be no blank spaces in the 6 required fields</li>
                        <li>
                          Import function will not process in case of error in the information on
                          the import file
                        </li>
                      </ul>

                      <p>
                        Once the Fixture list has been imported, you can edit and change all fixture
                        information by going to the Fixture List (under Fixture tab).
                      </p>

                      <p>
                        <strong>File Format</strong>
                      </p>

                      <p>
                        HEADINGS REQUIRED in the first row of your CSV file. Each row of your file
                        MUST start with Team Code information in each row (for example below,
                        Columns are separated by a comma (,)):
                      </p>

                      <ul>
                        <li>team_code, start_date, end_date, start_time, end_time</li>
                        <li>T00033, 27.09.2023, 27.09.2023, 4:40 PM, 4:50 PM</li>
                        <li>T00034, 27.09.2023, 28.09.2023, 4:40 PM, 4:50 PM</li>
                      </ul>
                    </div>
                  </>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ImportFixture
