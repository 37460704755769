import React, { useEffect, useState } from 'react'
import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CMultiSelect,
} from '@coreui/react-pro'
import { Popper } from 'react-popper'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import HeadToHeadService from 'src/service/HeadToHeadService'
import ToastComponent from 'src/components/common/TaostComponent'

const SaveGroupOne = (props) => {
  const [loader, setLoader] = useState(false)
  const [selectCleaner, setSelectCleaner] = useState(false)
  const formik = useFormik({
    initialValues: {
      member: '',
    },
    enableReinitialize: true,

    onSubmit: (data, actions) => {
      props.setMemberList([])
      actions.resetForm({
        values: {
          member: '',
        },
      })
      data.member = selectedValue
      data.groupId = 1
      setLoader(true)
      HeadToHeadService.saveGroupMember(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoader(false)
            props.fetchData()
            setSelectCleaner(true)
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.', 'error')
          setLoader(false)
        })
    },
  })

  const [selectedValue, setSelectedValue] = useState([])
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }
  return (
    <div>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <CFormLabel htmlFor="name" className="fw-bold">
            Group 1 Members ({props.group1Count})
          </CFormLabel>

          <CMultiSelect
            id="group1Members"
            options={props.memberList}
            selectionType="tags"
            name="player"
            onChange={handleChange}
            value={props.memberList.filter((obj) => selectedValue.includes(obj.value))}
            hide={selectCleaner}
            popper={{
              modifiers: [
                { name: 'flip', enabled: false },
                { name: 'offset', options: { offset: [0, 8] } },
                { name: 'preventOverflow', options: { padding: 8 } },
              ],
            }}
            // Disable flip modifier
          />
        </CCol>
        <CCol md={12}>
          <div>
            {' '}
            <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
              Save
            </CLoadingButton>
          </div>
        </CCol>
      </CForm>
    </div>
  )
}

export default SaveGroupOne
