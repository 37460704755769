import React, { useEffect, useState, useCallback } from 'react'
import {
  CBadge,
  CButton,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCollapse,
  CCardBody,
  CListGroupItem,
  CCol,
  CListGroup,
} from '@coreui/react-pro'
import moment from 'moment'
import UserService from 'src/service/UserService'

const MctGames = () => {
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [details, setDetails] = useState([])
  const [users, setUsers] = useState()
  const [gameDetails, setGameDetails] = useState({})
  const gameTypeLabels = { 1: 'Club (Outdoor)', 2: 'League', 3: 'Indoor-Cricket' }
  const planTypes = { 1: 'Starter', 2: 'Pro-1', 3: 'Pro-2' }

  const columns = [
    { label: 'Game ID', key: 'id' },
    { key: 'game_name', label: 'Game Name' },
    { label: 'Hub Name', key: 'hub_name' },
    { label: 'Hub ID', key: 'hub_id' },
    { label: 'Members', key: 'member_count' },
    { label: 'Team', key: 'team_count' },
    { label: 'Player', key: 'player_count' },
    // { label: 'Game Views', key: 'game_view_count' },
    { label: 'Game Type', key: 'game_type' },
    { label: 'Pricing Plan', key: 'pricing_plan' },
    { key: 'action', label: 'Actions', filter: false, sorter: false },
  ]

  // Wrap fetchGameData in useCallback to manage dependencies

  const fetchGameData = useCallback(async () => {
    setLoading(true)
    try {
      const offset = (activePage - 1) * itemsPerPage
      const params = new URLSearchParams()
      Object.keys(columnFilter).forEach((key) => params.append(key, columnFilter[key]))
      if (columnSorter?.column)
        params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

      const result = await UserService.getMctGameListing(offset, itemsPerPage, activePage, params)
      setUsers(result.data)
    } catch (error) {
      console.error('Error fetching game data:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, columnFilter, columnSorter, itemsPerPage]) // Add dependencies for re-running

  useEffect(() => {
    fetchGameData()
  }, [fetchGameData])

  const [stripePayment, setStripePayment] = useState()
  const [manualPayment, setManualPayment] = useState()
  const fetchGameDetails = async (gameId) => {
    if (!gameDetails[gameId]) {
      const result = await UserService.getGameDetails(gameId)
      // setGameDetails((prev) => ({ ...prev, [gameId]: result.data }))
      setGameDetails(result.data) // Set the entire game detail directly
      setStripePayment(result.data.stripPayment)
      setManualPayment(result.data.manualPayments)
    }
  }

  const toggleDetails = (gameId) => {
    const updatedDetails = details.includes(gameId)
      ? details.filter((id) => id !== gameId)
      : [...details, gameId]

    setDetails(updatedDetails)
    if (!gameDetails[gameId]) fetchGameDetails(gameId) // Only fetch if not already cached
  }

  const paidAmount = stripePayment?.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0)
  const paidManualAmount = manualPayment?.reduce(
    (sum, item) => sum + (parseFloat(item.amount) || 0),
    0,
  )

  const calculateTotalCollection = (paidAmount) => {
    const amount = parseFloat(paidAmount) || 0 // Ensure it's a valid number or default to 0
    const totalCollection = amount * 0.85 // Apply 15% platform fee
    return parseFloat(totalCollection) || 0.0
  }
  const calculatePlatformFee = (paidAmount) => {
    const amount = parseFloat(paidAmount) || 0
    return (amount * 0.15).toFixed(2) // 15% platform fee
  }
  const totalCollection = calculateTotalCollection(paidAmount)
  const platformFee = calculatePlatformFee(paidAmount)
  const gameTotalEarning = paidAmount + paidManualAmount
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Manage Admins</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{ external: true }}
                columnSorter={{ external: true }}
                scopedColumns={{
                  created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
                  game_type: (item) => (
                    <td>
                      <CBadge color="success">{gameTypeLabels[item.game_type] || ''}</CBadge>
                    </td>
                  ),
                  pricing_plan: (item) => (
                    <td>
                      <CBadge color="success">{planTypes[item.pricing_plan] || ''}</CBadge>
                    </td>
                  ),
                  action: (item) => (
                    <td>
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        onClick={() => toggleDetails(item.id)}
                      >
                        {details.includes(item.id) ? 'Hide' : 'Info'}
                      </CButton>
                    </td>
                  ),
                  details: (item) => {
                    return (
                      <>
                        <CCollapse visible={details.includes(item.id)}>
                          <CCardBody>
                            <CRow>
                              <CCol xs={12}>
                                <CListGroup>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Team Size
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.team_size ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Mode
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.team_view === 0
                                        ? 'Position Based'
                                        : 'Position Free'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Salary Cap
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      ${gameDetails?.salary_cap ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Trades Allowed
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.trades ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Trades Used
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.trade_used ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                </CListGroup>
                              </CCol>
                              <CCol xs={12}>
                                <CListGroup>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Total Fixtures
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.totalFixture ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Not Yet Started
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.notStartedFixture ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    InProgress Fixtures
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.inProgressFixture ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Completed Fixtures
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.completedFixture ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                </CListGroup>
                              </CCol>
                              <CCol xs={12}>
                                <CListGroup className="mt-2">
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Game Privacy
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.game_privacy === 1 ? 'Public' : 'Private'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Squad Alert
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.game_privacy === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Player Selection Limit
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.team_selection_limit ?? 0}
                                    </CBadge>
                                  </CListGroupItem>
                                </CListGroup>
                              </CCol>
                              <CCol xs={12}>
                                <CListGroup className="mt-2">
                                  <CListGroupItem
                                    className="d-flex justify-content-between align-items-center"
                                    active
                                  >
                                    Pro-AdOns
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Bonus Card
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.is_bonus_card_activate === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Team of Round:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.is_totr_activate === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    bracket battle:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.is_bracket_battle_activate === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Head-to-head:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.is_h2h_activate === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Votes:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.is_voting_activate === 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>
                                </CListGroup>
                              </CCol>
                              <CCol xs={12}>
                                <CListGroup className="mt-2">
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Game Pass
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.collect_game_pass == 0
                                        ? 'Free'
                                        : 'Collection Method Applied'}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Collection Method:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.collect_game_pass === 1
                                        ? 'MCT'
                                        : gameDetails?.collect_game_pass === 2
                                        ? 'Self'
                                        : null}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Collection Required:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.collect_game_pass == 1 ? 'Yes' : 'No'}
                                    </CBadge>
                                  </CListGroupItem>

                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Entry Fee:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.entry_price_symbol ?? '$'}
                                      {gameDetails?.entry_price}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Stripe Collection:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.entry_price_symbol}
                                      {totalCollection}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Manual Collection:
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {gameDetails?.entry_price_symbol}
                                      {paidManualAmount}
                                    </CBadge>
                                  </CListGroupItem>
                                  <CListGroupItem className="d-flex justify-content-between align-items-center">
                                    Total Earning :
                                    <CBadge color="primary-gradient" shape="rounded-pill">
                                      {`${
                                        gameDetails?.entry_price_symbol ?? ''
                                      }${gameTotalEarning}`}
                                    </CBadge>
                                  </CListGroupItem>
                                </CListGroup>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCollapse>
                      </>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{ external: true }}
                paginationProps={{
                  activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{ hover: true, responsive: true }}
                onActivePageChange={setActivePage}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={setColumnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default MctGames
