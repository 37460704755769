import React from 'react'

const Top5RunsPlayer = (props) => {
  return (
    <div>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>Top 5 Players</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {props.top5MostRunsPlayers &&
            props.top5MostRunsPlayers.map((item, key) => (
              <tr key={key}>
                <td>{item?.full_name}</td>
                <td>{item?.totalScore}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default Top5RunsPlayer
