import { CSpinner } from '@coreui/react-pro'
import React from 'react'

const Loader = ({ loaderText = '' }) => {
  return (
    <div className="pt-3 text-center" style={{ textAlign: 'center', padding: '20px' }}>
      <CSpinner color="primary" />
      {loaderText && <h2 dangerouslySetInnerHTML={{ __html: loaderText }} />}
    </div>
  )
}

export default Loader
