import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CFormSwitch,
  CRow,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import Loader from 'src/components/Loader'
import FixtureService from 'src/service/FixtureService'

const FixtureSetting = () => {
  const [defaultAttrForManageScore, setDefaultAttrForManageScore] = useState([])
  const [defaultAttrForCompletedScore, setDefaultAttrForCompletedScore] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingForCompletedFixture, setIsLoadingForCompletedFixture] = useState(true)
  const getAttributeDataForManageScore = () => {
    FixtureService.getFixtureAttributes(1).then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setDefaultAttrForManageScore(res.data) // Initialize default attributes from API
      }
    })
  }
  const getAttributeDataForCompletedScore = () => {
    FixtureService.getFixtureAttributes(2).then((res) => {
      if (res.status === 200) {
        setIsLoadingForCompletedFixture(false)
        setDefaultAttrForCompletedScore(res.data) // Initialize default attributes from API
      }
    })
  }
  useEffect(() => {
    getAttributeDataForManageScore()
    getAttributeDataForCompletedScore()
  }, [])

  const handleSwitchChange = (attributeKey, isChecked, type) => {
    setIsLoading(true)
    // Update the is_enabled state locally
    const updatedAttributes = defaultAttrForManageScore.map((attr) =>
      attr.key === attributeKey ? { ...attr, is_enabled: isChecked ? 1 : 0 } : attr,
    )
    setDefaultAttrForManageScore(updatedAttributes) // Update the local state

    // Call the API to update the backend
    const data = { attribute_key: attributeKey, is_enabled: isChecked, type: type }
    FixtureService.updateAttributeState(data)
      .then((res) => {
        if (res.status) {
          setIsLoading(false)
          ToastComponent(res.message, 'success')
          getAttributeDataForManageScore()
        }
      })
      .catch((error) => {
        console.error('Error updating attribute state:', error)
      })
  }
  const handleSwitchChangeForCompletedScore = (attributeKey, isChecked, type) => {
    setIsLoadingForCompletedFixture(true)
    // Update the is_enabled state locally
    const updatedAttributes = defaultAttrForCompletedScore.map((attr) =>
      attr.key === attributeKey ? { ...attr, is_enabled: isChecked ? 1 : 0 } : attr,
    )
    setDefaultAttrForCompletedScore(updatedAttributes) // Update the local state

    // Call the API to update the backend
    const data = { attribute_key: attributeKey, is_enabled: isChecked, type: type }
    FixtureService.updateAttributeState(data)
      .then((res) => {
        if (res.status) {
          setIsLoadingForCompletedFixture(false)
          ToastComponent(res.message, 'success')
          getAttributeDataForCompletedScore()
        }
      })
      .catch((error) => {
        console.error('Error updating attribute state:', error)
      })
  }

  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Manage Scores - Scorecard View</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {isLoading ? (
                <Loader />
              ) : (
                <CTable className="main-table table innertable">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Full Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {defaultAttrForManageScore.map((attr, index) => (
                      <tr key={index}>
                        <td>{attr.label}</td>
                        <td>{attr.full_name}</td>
                        <td>
                          <CFormSwitch
                            // label="Enable/Disable"
                            id={`switch-${attr.attribute_key}`}
                            checked={attr.is_enabled === 1} // Directly use the comparison to boolean for the current attribute
                            onChange={(e) =>
                              handleSwitchChange(attr.attribute_key, e.target.checked ? 1 : 0, 1)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </CTableBody>
                </CTable>
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={2} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Completed Fixtures - Scorecard View</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {isLoadingForCompletedFixture ? (
                <Loader />
              ) : (
                <CTable className="main-table table innertable">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Full Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {defaultAttrForCompletedScore.map((attr, index) => (
                      <tr key={index}>
                        <td>{attr.label}</td>
                        <td>{attr.full_name}</td>
                        <td>
                          <CFormSwitch
                            // label="Enable/Disable"
                            id={`switch-${attr.attribute_key}`}
                            checked={attr.is_enabled === 1} // Directly use the comparison to boolean for the current attribute
                            onChange={(e) =>
                              handleSwitchChangeForCompletedScore(
                                attr.attribute_key,
                                e.target.checked ? 1 : 0,
                                2,
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </CTableBody>
                </CTable>
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default FixtureSetting
