import React, { useState } from 'react'
import { CNav, CNavItem, CNavLink, CSmartTable, CTabContent, CTabPane } from '@coreui/react-pro'
import AllPlayerStatsTab from './AllPlayerStatsTab'
import RoundPlayerStatsTab from './RoundPlayerStatsTab'
import FixturePlayerStatsTab from './FixturePlayerStatsTab'
const PlayerStatTable = (props) => {
  const [activeTab, setActiveTab] = useState('all')

  return (
    <>
      <CNav variant="underline">
        <CNavItem>
          <CNavLink active={activeTab === 'all'} onClick={() => setActiveTab('all')}>
            All
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={activeTab === 'round'} onClick={() => setActiveTab('round')}>
            Round
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={activeTab === 'fixtures'} onClick={() => setActiveTab('fixtures')}>
            Fixtures
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane visible={activeTab === 'all'}>
          <AllPlayerStatsTab
            users={props.users}
            activePage={props.activePage}
            setActivePage={props.setActivePage}
            columnFilter={props.columnFilter}
            setColumnFilter={props.setColumnFilter}
            columnSorter={props.columnSorter}
            setColumnSorter={props.setColumnSorter}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            columns={props.columns}
            fetchData={props.fetchData}
            setLoading={props.setLoading}
            loading={props.loading}
            playerList={props.playerList}
          />
        </CTabPane>
        <CTabPane visible={activeTab === 'round'}>
          <RoundPlayerStatsTab activeTab={activeTab} playerList={props.playerList} />
        </CTabPane>
        <CTabPane visible={activeTab === 'fixtures'}>
          <FixturePlayerStatsTab activeTab={activeTab} playerList={props.playerList} />
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default PlayerStatTable
