import React from 'react'
const RoundResult = (props) => {
  return (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>First Opponent </th>
            <th>First Opponent points</th>
            <th>Second Opponent </th>
            <th>Second Opponent points</th>
            <th>Winner</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((item, key) => (
              <tr key={key}>
                <td>{item.first_opponent}</td>
                <td>{item.total_points_first_member}</td>
                <td>{item.second_opponent}</td>
                <td>{item.total_points_second_member}</td>
                <td>
                  {item.winner_user && item.winner_user}
                  {!item.winner_user && <span>Tie</span>}
                </td>
              </tr>
            ))}
          {props.data?.length === 0 && (
            <tr>
              <td colSpan={4}>No record yet available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default RoundResult
