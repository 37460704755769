import React, { useCallback, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import GradeService from 'src/service/GradeService'
import PointSystem from './PointSystem'
import EditPointSetting from './EditPointSetting'
const GradePoint = () => {
  const [loading, setLoading] = useState()
  const [gradePointData, setGradePointData] = useState([])

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState({})
  const [details, setDetails] = useState([])
  const [fixtureExists, setFixtureExists] = useState([])
  const [divShow, setDivShow] = useState(0)
  const [gradeDetail, setGradeDetail] = useState({})
  const columns = [
    {
      label: 'Comp Name',
      key: 'grade',
      filter: false,
    },
    {
      key: 'show_details',
      label: 'Point System',
      filter: false,
      sorter: false,
    },
  ]

  const toggleDetails = (index) => {
    setGradePointData([])
    setLoading(true)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)

    GradeService.gradePointSystem(index).then((res) => {
      setGradePointData(res.data)
      setFixtureExists(res.is_fixture_exists)
      setLoading(false)
    })
  }

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await GradeService.getGrades(offset, itemsPerPage, activePage, params)
      setUsers(result.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <CRow>
              <CCol xs={10}>
                <strong>Manage Point System</strong>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CSmartTable
              columns={columns}
              columnFilter={{
                external: true,
              }}
              columnSorter={{
                external: true,
              }}
              scopedColumns={{
                show_details: (item) => {
                  return (
                    <>
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleDetails(item.id)
                            setDivShow(1)
                          }}
                        >
                          {'Edit Point System'}
                        </CButton>
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleDetails(item.id)
                            setDivShow(2)
                            GradeService.getGradeDetail(item.id).then((res) => {
                              setGradeDetail(res.data)
                            })
                          }}
                        >
                          {'Edit Minimum'}
                        </CButton>
                      </td>
                    </>
                  )
                },
                details: (item) => {
                  return (
                    <CCollapse visible={details.includes(item.id)}>
                      <CCardBody>
                        {divShow === 1 && (
                          <PointSystem
                            gradePointData={gradePointData}
                            setGradePointData={setGradePointData}
                            gradeId={item.id}
                            setLoading={setLoading}
                            toggleDetails={toggleDetails}
                            fixtureExists={fixtureExists}
                            fetchUsers={fetchUsers}
                          />
                        )}
                        {divShow === 2 && (
                          <EditPointSetting
                            gradeDetail={gradeDetail}
                            toggleDetails={toggleDetails}
                            fetchUsers={fetchUsers}
                          />
                        )}
                      </CCardBody>
                    </CCollapse>
                  )
                },
              }}
              items={users?.data}
              itemsPerPage={itemsPerPage}
              itemsPerPageSelect
              loading={loading}
              pagination={{
                external: true,
              }}
              paginationProps={{
                activePage: activePage,
                pages: Math.ceil(users?.total / itemsPerPage) || 1,
              }}
              tableProps={{
                hover: true,
                responsive: true,
              }}
              onActivePageChange={(activePage) => setActivePage(activePage)}
              onColumnFilterChange={(filter) => {
                setActivePage(1)
                setColumnFilter(filter)
              }}
              onItemsPerPageChange={(itemsPerPage) => {
                setActivePage(1)
                setItemsPerPage(itemsPerPage)
              }}
              onSorterChange={(sorter) => setColumnSorter(sorter)}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default GradePoint
