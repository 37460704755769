import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import AddForm from './AddForm'
import Table from './Table'
import { useEffect } from 'react'
import HeadToHeadService from 'src/service/HeadToHeadService'
const HeadToHead = () => {
  const [users, setUsers] = useState([])
  const [roundList, setRoundList] = useState([])
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState()
  useEffect(() => {
    HeadToHeadService.addHeadTohead().then((res) => {
      if (res.status === 200) {
        setLoading(false)
        setUserList(res.data)
        setRoundList(res.roundList)
      }
    })
  }, [])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Create Head-2-Head</strong>
            </CAccordionHeader>
            <CAccordionBody>
              {!loading && (
                <AddForm userList={userList} roundList={roundList} setUsers={setUsers} />
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              <strong>Manage Head-2-Head</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <Table users={users} setUsers={setUsers} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default HeadToHead
