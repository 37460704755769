import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import HeadToHeadService from 'src/service/HeadToHeadService'
import ToastComponent from 'src/components/common/TaostComponent'

const ChooseHeadToHeadType = (props) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().oneOf(['all', 'group']).required('Please select an option'),
  })
  const formik = useFormik({
    initialValues: {
      selectedOption: props.data?.type || '',
    },
    validationSchema,
    onSubmit: (data) => {
      console.log('data', data)
      props.setLoader(true)
      HeadToHeadService.saveHeadToHeadType(data).then((res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
          props.fetchData()
        }
      })
      props.setLoader(false)
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="all"
              checked={formik.values.selectedOption === 'all'}
              onChange={() => formik.setFieldValue('selectedOption', 'all')}
              id="allRadio"
            />
            <label className="form-check-label" htmlFor="allRadio">
              All
            </label>
          </div>
        </div>

        <div className="col-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="group"
              checked={formik.values.selectedOption === 'group'}
              onChange={() => formik.setFieldValue('selectedOption', 'group')}
              id="groupRadio"
            />
            <label className="form-check-label" htmlFor="groupRadio">
              Group
            </label>
          </div>
        </div>
        <div className="col-3">&nbsp;</div>
        <div className="col-3">&nbsp;</div>
      </div>

      {formik.errors.selectedOption ? (
        <div className="text-danger">{formik.errors.selectedOption}</div>
      ) : null}

      <button type="submit" className="btn btn-primary mt-3">
        Submit
      </button>
    </form>
  )
}

export default ChooseHeadToHeadType
