import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'

const PlayerRound = () => {
  const [rounData, setRounData] = useState([])
  const [searchPlayerName, setSearchPlayerName] = useState('')
  const [sortOrder, setSortOrder] = useState('asc') // Initial sorting order
  const [sortColumn, setSortColumn] = useState(null)
  const [playersData, setPlayersData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const perPage = 10 // Number of items per page

  useEffect(() => {
    PlayerService.playerRound(searchPlayerName, sortOrder, currentPage)
      // .then((response) => response.json())
      .then((result) => {
        setPlayersData(result.data)
        setRounData(result.rounData)
        setTotalPages(result.last_page)
      })
  }, [searchPlayerName, sortOrder, currentPage])
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }
  // const pageNumbers = []
  // for (let i = currentPage - 3; i <= currentPage + 3; i++) {
  //   if (i > 0 && i <= totalPages) {
  //     pageNumbers.push(i)
  //   }
  // }

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked, reverse the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      // If a different column is clicked, set the new column and sorting order
      setSortColumn(column)
      setSortOrder('asc') // Default to ascending
    }
  }

  const sortedPlayersData = [...playersData] // Create a copy to avoid modifying the original data

  // Sort the player data based on the selected column and sorting order
  if (sortColumn) {
    sortedPlayersData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.round[sortColumn - 1].points - b.round[sortColumn - 1].points
      } else {
        return b.round[sortColumn - 1].points - a.round[sortColumn - 1].points
      }
    })
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Player Rounds</strong>
            </CCardHeader>
            <CCardBody>
              <CRow className="mb-3">
                <CCol md={3}>
                  {' '}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by player name"
                    value={searchPlayerName}
                    onChange={(e) => setSearchPlayerName(e.target.value)} // Update searchPlayerName state
                  />
                </CCol>
              </CRow>

              <table className="main-table table innertable">
                <thead className="table-light">
                  <tr>
                    <th>Player</th>
                    {rounData.map((item, key1) => (
                      <th key={key1} onClick={() => handleSort(item.round)}>
                        R{item.round}
                        {sortColumn === item.round && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayersData &&
                    sortedPlayersData.map((item, key) => (
                      <tr key={key}>
                        <td>{item.player_name}</td>
                        {item.round &&
                          item.round.map((item, key1) => <td key={key1}>{item?.points}</td>)}
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <nav aria-label="pagination">
                <ul className="pagination justify-content-start">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number, index) => (
                    <li
                      key={number}
                      className={`page-item ${number === currentPage ? 'active' : ''}`}
                    >
                      <button className="page-link" onClick={() => handlePageChange(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                  {currentPage + 3 < totalPages && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {currentPage + 2 < totalPages && (
                    <li key={totalPages} className="page-item">
                      <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                      </button>
                    </li>
                  )}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav> */}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default PlayerRound
