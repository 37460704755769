import React, { useEffect, useState } from 'react'

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CWidgetStatsB,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cibGoogle,
  cibFacebook,
  cibTwitter,
  cibInstagram,
  cilUserPlus,
  cilSpeak,
  cilNewspaper,
  cilHome,
} from '@coreui/icons'

import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import Top5RunsPlayer from './Top5RunsPlayer'
import Top5WksPlayer from './Top5WksPlayer'

const MctDashboard = () => {
  const [dashboardData, setDashboardData] = useState([])

  const [gender1, setGender1] = useState('')
  const [gender2, setGender2] = useState('')
  const [gender3, setGender3] = useState('')
  const [gender4, setGender4] = useState('')
  const [gender5, setGender5] = useState('')
  const [gameId, setGameId] = useState('')
  const [top5MostRunsPlayers, setTop5MostRunsPlayers] = useState([])
  const [top5MostWksPlayers, setTop5MostWksPlayers] = useState([])
  const [fantasyPointsData, setFantasyPointsData] = useState({})
  const [teamSizeData, setTeamSizeData] = useState([])
  const [playerSelectionData, setPlayerSelectionData] = useState([])
  const [gameSalaryCap, setGameSalaryCap] = useState([])
  const [gamePrivacy, setGamePrivacy] = useState([])
  const [squadAlertData, setSquadAlertData] = useState([])
  const [gameBrandingData, setGameBrandingData] = useState({})
  const [proAdOnsData, setProAdOnsData] = useState({})

  useEffect(() => {
    CommonService.mctDashboardData()
      .then((res) => {
        if (res.status === 200) {
          setDashboardData(res.data)
          setGender1(res.data.g1PercentageUsers)
          setGender2(res.data.g2PercentageUsers)
          setGender3(res.data.g3PercentageUsers)
          setGender4(res.data.g4PercentageUsers)
          setGender5(res.data.g5PercentageUsers)
          return CommonService.getMctTop5Player()
        }
      })
      .then((top5PlayerRes) => {
        setTop5MostRunsPlayers(top5PlayerRes.top5MostRunsPlayers)
        setTop5MostWksPlayers(top5PlayerRes.top5MostWksPlayers)
        return CommonService.getFantasyScoreTotal()
      })
      .then((fantasyPointsDataRes) => {
        setFantasyPointsData(fantasyPointsDataRes.data)
        return CommonService.getGamesTeamSize(gameId)
      })
      .then((getGamesTeamSizeResult) => {
        setTeamSizeData(getGamesTeamSizeResult.data || [])
        setPlayerSelectionData(getGamesTeamSizeResult.playerSelectionData || [])
        setGameSalaryCap(getGamesTeamSizeResult.gameSalaryCap || [])
        setGamePrivacy(getGamesTeamSizeResult.gamePrivacy || [])
        setSquadAlertData(getGamesTeamSizeResult.squadAlert || [])
        setGameBrandingData(getGamesTeamSizeResult.gameBrandingData || {})
        return CommonService.getGameProData(gameId)
      })
      .then((gameProData) => {
        setProAdOnsData(gameProData.data)
        // return CommonService.getGamesTeamSize(gameId)
      })
      .catch((e) => {
        console.log(e)
        ToastComponent('Something went wrong. Please try again', 'error')
      })
  }, [gameId])

  return (
    <>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Hub Info</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">All Hubs</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalFixture}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Active Hubs</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.notStartedFixture}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">In-Active Hubs</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.notStartedFixture}</div>
                  </div>
                </CCol>

                <CCol sm={2}>
                  <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">All Games</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.inProgressFixture}</div>
                  </div>
                </CCol>
                <CCol sm={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Completed Games</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.completedFixture}</div>
                  </div>
                </CCol>
                <CCol sm={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Active Games</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.completedFixture}</div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>Game Infos</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Members</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalMembers}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Players</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalPlayer}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Fixtures</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalFixture}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Trades</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.tradeCount}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Total Teams</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalTeams}</div>
                  </div>
                </CCol>

                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Gameviews</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalGameView}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Rounds</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalRounds}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Game Totals</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalGame?.total_game}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Club</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalGame?.club_total}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Indoor</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalGame?.indoor_total}</div>
                  </div>
                </CCol>
                <CCol md={2}>
                  <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">Leagues</div>
                    <div className="fs-5 fw-semibold">{dashboardData?.totalGame?.league_total}</div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Team Size</CCardHeader>
            <CCardBody>
              <CRow>
                {teamSizeData?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">{team.team_size} Players</div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Player Selection</CCardHeader>
            <CCardBody>
              <CRow>
                {playerSelectionData?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">
                        {team.team_view == 0 ? 'Default' : 'Custom'}
                      </div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Salary Cap</CCardHeader>
            <CCardBody>
              <CRow>
                {playerSelectionData?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">
                        {team.team_view == 0 ? 'Default Setting' : 'Custom Setting'}
                      </div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Squad Alert</CCardHeader>
            <CCardBody>
              <CRow>
                {squadAlertData?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">
                        {team.player_alert == 0 ? 'Not Applied' : 'Applied'}
                      </div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Salary Cap</CCardHeader>
            <CCardBody>
              <CRow>
                {gameSalaryCap?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">
                        <div className="text-medium-emphasis small">${team.salary_cap}</div>
                      </div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Game Privacy</CCardHeader>
            <CCardBody>
              <CRow>
                {gamePrivacy?.map((team, index) => (
                  <CCol sm={2} md={2} key={index}>
                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                      <div className="text-medium-emphasis small">
                        <div className="text-medium-emphasis small">
                          {team.game_privacy === 0 || team.game_privacy === 1
                            ? 'Public'
                            : 'Private'}
                        </div>
                      </div>
                      <div className="fs-5 fw-semibold">{team.count}</div>
                    </div>
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>Branding</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Yes</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold"> {gameBrandingData?.withBrandingCount}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>No</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold">{gameBrandingData?.withoutBrandingCount}</div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={12}>
          <CCard className="mb-4">
            <CCardHeader>PRO Add-Ons</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Bonus Card</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold"> {proAdOnsData?.bonus_card_count}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Head To Head</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold">{proAdOnsData?.h2h_count}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Bracket Battle</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold">{proAdOnsData?.bracket_battle_round}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Votes</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold">{proAdOnsData?.voting_count}</div>
                  </div>
                </CCol>
                <CCol sm={2} md={2}>
                  <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div className="text-medium-emphasis small">
                      <div className="text-medium-emphasis small">
                        <span>Team of the Round</span>
                      </div>
                    </div>
                    <div className="fs-5 fw-semibold">{proAdOnsData?.totr_count}</div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} md={6} xl={6}>
          <CCard className="mb-4">
            <CCardHeader>Most Points</CCardHeader>
            <CCardBody>
              <Top5RunsPlayer top5MostRunsPlayers={top5MostRunsPlayers} />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12} md={6} xl={6}>
          <CCard className="mb-4">
            <CCardHeader>Most Assists</CCardHeader>
            <CCardBody>
              <Top5WksPlayer top5MostWksPlayers={top5MostWksPlayers} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {/* <CRow>
        <CCol xs={12} sm={12} lg={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Games</strong>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                className={'mb-0 border mt-3'}
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  logo: (item) => (
                    <td>
                      <CAvatar
                        size="md"
                        src={`${process.env.REACT_APP_API_URL}uploads/user_profile/${item.image}`}
                      />
                    </td>
                  ),
                  game_details: (item) => (
                    <td>
                      <div>{item.full_name}</div>
                      <div className="small text-medium-emphasis text-nowrap">
                        Registered: {moment(item.created_at).format('D.MM.YYYY')}
                      </div>
                    </td>
                  ),
                  admin: (item) => (
                    <td>
                      {' '}
                      <div className="d-flex justify-content-between">
                        <div className="float-start">
                          <strong>{22}%</strong>
                        </div>
                        <div className="float-end ms-1 text-nowrap">
                          <small className="text-medium-emphasis">{22}</small>
                        </div>
                      </div>
                      <CProgress thin color={`success-gradient`} value={22} />
                    </td>
                  ),
                  fees: (item) => (
                    <td>
                      {item.is_fund_paid === 1 ? (
                        <CBadge color={'success'} title="Paid">

                          Paid
                        </CBadge>
                      ) : (
                        <CBadge color={'danger'} title="Unpaid">
                         Unpaid
                        </CBadge>
                      )}
                    </td>
                  ),
                  show_details: (item) => {
                    return (
                      <>
                        <td className="py-2">
                          <div className="small text-medium-emphasis">Last login</div>
                          <div className="fw-semibold text-nowrap">5 Minuts ago</div>
                        </td>
                      </>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>Demographics</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsB
                    className="mb-4"
                    progress={{
                      color: 'success-gradient',
                      value: gender1,
                    }}
                    title="Male"
                    value={`${gender1}%`}
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsB
                    className="mb-4"
                    value={`${gender2}%`}
                    title="Female"
                    progress={{ color: 'info-gradient', value: gender2 }}
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsB
                    className="mb-4"
                    value={`${gender3}%`}
                    title="Non-Binary"
                    progress={{
                      color: 'warning-gradient',
                      value: gender3,
                    }}
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsB
                    className="mb-4"
                    value={`${gender4}%`}
                    title="Different Identity"
                    progress={{
                      color: 'warning-gradient',
                      value: gender4,
                    }}
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsB
                    className="mb-4"
                    value={`${gender5}%`}
                    title="Prefer not to say"
                    progress={{
                      color: 'warning-gradient',
                      value: gender5,
                    }}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>Refer</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12} md={6} xl={6}>
                  {/* Google */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cibGoogle} size="lg" />
                      <span>{'Google'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.googDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.googlePercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.googlePercentage ?? 0}
                      />
                    </div>
                  </div>
                  {/* Facebook */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cibFacebook} size="lg" />
                      <span>{'Facebook'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.fbDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.fbPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.fbPercentage ?? 0}
                      />
                    </div>
                  </div>
                  {/* twitter */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cibTwitter} size="lg" />
                      <span>{'Twitter'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.twitDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.twitterPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.twitterPercentage ?? 0}
                      />
                    </div>
                  </div>
                  {/* Instagram */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cibInstagram} size="lg" />
                      <span>{'Instagram'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.instaDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.instPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.instPercentage ?? 0}
                      />
                    </div>
                  </div>
                </CCol>

                <CCol xs={12} md={6} xl={6}>
                  {/* Word of mouth */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cilSpeak} size="lg" />
                      <span>{'Word of Mouth'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.womDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.womPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.womPercentage ?? 0}
                      />
                    </div>
                  </div>
                  {/* Advertisement */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cilNewspaper} size="lg" />
                      <span>{'Advertisement'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.adDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.adPercentag}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.adPercentag ?? 0}
                      />
                    </div>
                  </div>
                  {/* Referred by Club */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cilHome} size="lg" />
                      <span>{'Referred by Club'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.refClubDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.refClubPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.refClubPercentage ?? 0}
                      />
                    </div>
                  </div>
                  {/* Referred by Friend */}
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <CIcon className="me-2" icon={cilUserPlus} size="lg" />
                      <span>{'Referred by Friend'}</span>
                      <span className="ms-auto fw-semibold">
                        {dashboardData?.refFriendDetails}{' '}
                        <span className="text-medium-emphasis small">
                          ({dashboardData?.reFriendPercentage}%)
                        </span>
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <CProgress
                        thin
                        color="success-gradient"
                        value={dashboardData?.reFriendPercentage ?? 0}
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default MctDashboard
