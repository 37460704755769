import React, { useState } from 'react'
import { CSmartTable } from '@coreui/react-pro'
const PlayerValueTable = (props) => {
  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        items={props.users?.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect
        loading={props.loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
    </>
  )
}

export default PlayerValueTable
