import React, { useState } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import FinalScorecardModal from './FinalScorecardModal'
const MarkAsCompleteModal = (props) => {
  const showFinalScorecardModal = () => {
    props.setFinalScorecardModalVisible(true)
    props.setCompletMarkModalVisible(false)
  }
  return (
    <>
      <CModal
        backdrop="static"
        visible={props.completMarkModalVisible}
        onClose={() => props.setCompletMarkModalVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CModalTitle>Scoring Tip</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p className="fw-bold" style={{ color: 'red' }}>
            Proceed further if the fantasy points numbers look good, or go back to make any required
            adjustments! Be careful! Marking a fixture as Completed is a non-reversible action.
          </p>
          <table className="main-table table table-bordered innertable">
            <thead>
              <tr>
                <th>Player</th>
                <th>Fantasy Points</th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data?.map((item, key) => (
                  <tr key={key}>
                    <th>
                      {item.player_name} - (<em>{item.position}</em>)
                    </th>
                    <th>{item.fantasy_points}</th>
                  </tr>
                ))}
              {props.data.length === 0 && (
                <tr>
                  <td colSpan={2}>No record yet available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={() => props.setCompletMarkModalVisible(false)}>
            Close
          </CButton>
          <CButton color="success" onClick={showFinalScorecardModal}>
            Confirm to Complete
          </CButton>
        </CModalFooter>
      </CModal>

      <FinalScorecardModal
        completMarkModalVisible={props.completMarkModalVisible}
        setCompletMarkModalVisible={props.setCompletMarkModalVisible}
        finalScorecardModalVisible={props.finalScorecardModalVisible}
        setFinalScorecardModalVisible={props.setFinalScorecardModalVisible}
        fixtureId={props.fixtureId}
      />
    </>
  )
}

export default MarkAsCompleteModal
