import React, { useEffect, useState } from 'react'
import { CFormLabel, CForm, CLoadingButton, CRow, CCol, CFormSelect } from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import 'react-input-range/lib/css/index.css'
const PlayerAlert = (props) => {
  const [loader, setLoader] = useState(false)
  const formik = useFormik({
    initialValues: {
      player_alert: props.gameData?.player_alert,
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (data) => {
      setLoader(true)
      CommonService.updatePlayerAlert(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })

  return (
    <>
      <br></br>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CRow className="mb-10">
          <CCol md={12} xs={12}>
            <CFormSelect
              aria-label="Select Structure"
              name="structure"
              className={
                'mt-3 form-control' +
                (formik.errors.structure && formik.touched.structure ? ' is-invalid' : '')
              }
              value={formik.values.player_alert}
              id="player_alert"
              onChange={(event) => {
                formik.setTouched({
                  ...formik.touched,
                  player_alert: true,
                })
                formik.setFieldValue('player_alert', event.target.value)
              }}
            >
              <option value="0">No Squad Alerts </option>
              <option value="1">Show Squad Alerts </option>
            </CFormSelect>
          </CCol>
        </CRow>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default PlayerAlert
