import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent.js'
import TeamService from 'src/service/TeamService'
import EditForm from './EditForm'
import Notify from '../Notify'
import CommonService from 'src/service/CommonService'
const Table = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [selectedTeamId, setSelectedTeamId] = useState(0)
  const [isEditFormVisible, setIsEditFormVisible] = useState(false) // New state to control EditForm visibility

  const navigate = useNavigate()
  const handleEditFormSubmit = () => {
    setIsEditFormVisible(false) // Close EditForm when submitted
  }

  const [details, setDetails] = useState([])

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedTeamId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
      setIsEditFormVisible(true) // Open EditForm when expanding the collapse
    }
    setDetails(newDetails)
  }

  const deleteHeaderInfoText = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    CommonService.deleteHeaderInfoText(data).then((res) => {
      if (res.status === 200) {
        props.fetchData(
          props.activePage,
          props.columnFilter,
          props.columnSorter,
          props.itemsPerPage,
        )
        ToastComponent(res.message, 'success')
        setLoading(false)
      }
    })
  }

  // Are you sure want modal
  const [handleYes, setHandleYes] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)
  const [tableId, setTableId] = useState(false)
  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }

  const handleConfirm = () => {
    deleteHeaderInfoText(tableId)
    return setShowConfirm(false)
  }
  const areYouSureModal = (id) => {
    setShowConfirm(true)
    setTableId(id)
    // const data = {}
    // data.id = id
    // data.type = 'team'
    // CommonService.checkItemExists(data).then((res) => {
    //   if (res.status === 200) {
    //     if (res.data) {
    //       ToastComponent(res.message, 'error')
    //     } else {
    //       setShowConfirm(true)
    //       setTableId(id)
    //     }
    //   }
    // })
  }

  return (
    <>
      <CSmartTable
        columns={props.columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          svalue: (item) => <td>${item.svalue}m</td>,
          is_active: (item) => (
            <td>
              <CBadge color={getBadge(item.is_active)}>
                {' '}
                {item.is_active === 1 ? 'Activated' : 'Deactivated'}
              </CBadge>
            </td>
          ),
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(item.id)
                    }}
                  >
                    {details.includes(item.id) ? 'Hide' : 'Manage'}
                  </CButton>
                </td>
              </>
            )
          },
          details: (item) => {
            return (
              <CCollapse
                visible={details.includes(item.id) && isEditFormVisible} // Show EditForm only if details are visible and isEditFormVisible is true
              >
                <CCardBody>
                  <CButton
                    size="sm"
                    color="success"
                    className="ml-1"
                    onClick={() => setVisibleHorizontal(!visibleHorizontal)}
                    aria-expanded={visibleHorizontal}
                    aria-controls="collapseEdit"
                  >
                    Edit
                  </CButton>
                  <CButton
                    size="sm"
                    color="danger"
                    className="ml-3"
                    onClick={() => areYouSureModal(item.id)}
                  >
                    Delete
                  </CButton>
                  <CCollapse id="collapseEdit" horizontal visible={visibleHorizontal}>
                    <CCard className="mb-4">
                      <CCardHeader>
                        <strong>Edit Info Text</strong>
                      </CCardHeader>
                      <CCardBody>
                        <EditForm
                          teamId={item.id}
                          selectedTeamId={selectedTeamId}
                          activePage={props.activePage}
                          setActivePage={props.setActivePage}
                          columnFilter={props.columnFilter}
                          setColumnFilter={props.setColumnFilter}
                          columnSorter={props.columnSorter}
                          setColumnSorter={props.setColumnSorter}
                          itemsPerPage={props.itemsPerPage}
                          setItemsPerPage={props.setItemsPerPage}
                          fetchData={props.fetchData}
                          onSubmit={handleEditFormSubmit}
                        />
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </CCardBody>
              </CCollapse>
            )
          },
        }}
        items={props.users?.data}
        itemsPerPage={props.itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: props.activePage,
          pages: Math.ceil(props.users?.total / props.itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => props.setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          props.setActivePage(1)
          props.setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          props.setActivePage(1)
          props.setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => props.setColumnSorter(sorter)}
      />
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleYes={handleYes}
        setHandleYes={setHandleYes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are you sure you want to delete this?"
      />
    </>
  )
}

export default Table
