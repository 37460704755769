import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL

const getUserGameInfo = async (fixtureId, userId) => {
  const response = await agent.get(`${API_URL}getUserGameInfo`)
  return response.data
}
const changeUserGameAddOnStatus = async (data) => {
  const response = await agent.post(`${API_URL}changeUserGameAddOnStatus`, data)
  return response.data
}

const emailLog = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}emails/emailLog?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const getEmailTemplate = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}emails/getEmailTemplate?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}

const getEmailMessage = async (id) => {
  const response = await agent.get(`${API_URL}emails/getEmailMessage/${id}`)
  return response.data
}
const getEmailTemplateDetail = async (id) => {
  const response = await agent.get(`${API_URL}emails/getEmailTemplateDetail/${id}`)
  return response.data
}

const editTemplate = async (data) => {
  const response = await agent.post(`${API_URL}emails/editTemplate`, data)
  return response.data
}
const GeneralService = {
  getUserGameInfo,
  changeUserGameAddOnStatus,
  emailLog,
  getEmailMessage,
  getEmailTemplate,
  getEmailTemplateDetail,
  editTemplate,
}

export default GeneralService
