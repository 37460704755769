import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCollapse,
  CCardBody,
  CCol,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import UserService from 'src/service/UserService'
import GameListingModal from '../GameAccount/GameListingModal'
const GameUser = () => {
  const [loading, setLoading] = useState()

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [selectedId, setSelectedId] = useState(0)
  const [users, setUsers] = useState()

  const [details, setDetails] = useState([])
  const columns = [
    {
      label: 'Member',
      key: 'full_name',
    },
    { key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Registered', key: 'created_at' },
    { label: 'Status', filter: false, key: 'is_active' },
    {
      key: 'action',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  useEffect(() => {
    setLoading(true)
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])
  const fetchData = (
    activePage,
    columnFilter,
    columnSorter,
    itemsPerPage,
    adminTabActive,
    userTabActive,
  ) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      setLoading(true)
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    UserService.getAdminUserListing(offset, itemsPerPage, activePage, params, false, true)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }
  const [modalIsOpen, setIsOpen] = useState(false)
  const [userDataListing, setUserDataListing] = useState([])
  function openFromParent(accountId) {
    UserService.getGameUser(accountId).then((result) => {
      setUserDataListing(result.data)
    })
    setIsOpen(true)
  }
  const changeAccountStatus = (userId, status) => {
    const data = {}
    data.userId = userId
    data.status = status
    UserService.changeAccountStatus(data).then((result) => {
      ToastComponent(result.message, 'success')
      fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
    })
  }
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Members</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
                  is_active: (item) => (
                    <td>
                      <CBadge color={getBadge(item.is_active)}>
                        {' '}
                        {item.is_active === 1 ? 'Activated' : 'Deactivated'}
                      </CBadge>
                    </td>
                  ),
                  action: (item) => {
                    return (
                      <>
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(item.id)
                            }}
                          >
                            {details.includes(item.id) ? 'Hide' : 'Info'}
                          </CButton>{' '}
                        </td>
                      </>
                    )
                  },
                  details: (item) => {
                    return (
                      <CCollapse visible={details.includes(item.id)}>
                        <CCardBody>
                          <CRow>
                            <CCol xs="auto">
                              <span className="px-2">
                                {' '}
                                {/* <Link
                          to={`${process.env.REACT_APP_FRONT_URL}adminLogin/${item.id}`}
                          target="_blank"
                        >
                          Login
                        </Link>{' '} */}
                                <CButton
                                  component="a"
                                  color="primary"
                                  href={`${process.env.REACT_APP_FRONT_URL}adminLogin/${item.id}`}
                                  target="_blank"
                                  role="button"
                                  size="sm"
                                >
                                  Login
                                </CButton>
                                {item.user_role_id === 3 && (
                                  <>
                                    <CButton
                                      className="ml-1"
                                      color={'success'}
                                      size="sm"
                                      onClick={(event) => {
                                        event.preventDefault()
                                        openFromParent(item.id)
                                      }}
                                    >
                                      Games
                                    </CButton>

                                    {item.is_active === 1 && (
                                      <CButton
                                        className="ml-1"
                                        color={'info'}
                                        size="sm"
                                        onClick={() => changeAccountStatus(item?.id, 0)}
                                      >
                                        Hide Hub
                                      </CButton>
                                    )}

                                    {item.is_active === 0 && (
                                      <CButton
                                        className="ml-1"
                                        color={'info'}
                                        size="sm"
                                        onClick={() => changeAccountStatus(item?.id, 1)}
                                      >
                                        Show Hub
                                      </CButton>
                                    )}
                                  </>
                                )}
                              </span>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCollapse>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>

      <GameListingModal
        IsModalOpened={modalIsOpen}
        onCloseModal={setIsOpen}
        userDataListing={userDataListing}
      />
    </>
  )
}

export default GameUser
