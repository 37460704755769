import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TeamService from 'src/service/TeamService'
import ToastComponent from 'src/components/common/TaostComponent'
import { useEffect } from 'react'
import PreviewImage from '../PreviewImage'
import CommonService from 'src/service/CommonService'
import { useMemo } from 'react'
import ReactQuill from 'react-quill'
import { useRef } from 'react'
const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const [value, setValue] = useState()
  const headerText = [
    'Settings',
    'Controls',
    'Players',
    'Fixtures',
    'Members',
    'Trades',
    'Analytics',
    'Bonus Cards',
    'Bracket Battle',
    'Head-2-Head',
    'Timeline',
    'Team of the Round',
    'Votes',
    'Pricing',
  ]
  const validationSchema = Yup.object().shape({
    header_text: Yup.string().required('Header Text is required'),
    page_name: Yup.string().required('Page Name is required'),
    label_name: Yup.string().required('label Name is required'),
  })
  const formik = useFormik({
    initialValues: {
      label_name: '',
      header_text: '',
      tooltip: '',
      page_name: '',
      share_link: '',
      info_text: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      actions.resetForm()
      data.info_text = value
      setLoader(true)
      CommonService.saveHeaderInfo(data).then((res) => {
        if (res.status === 200) {
          props.fetchData(
            props.activePage,
            props.columnFilter,
            props.columnSorter,
            props.itemsPerPage,
          )
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
          [
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
          ],
        ],
      },
    }),
    [],
  )
  const quillRef = useRef()
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="grade_name">
            Accordian Heading *
          </CFormLabel>
          <CFormSelect
            aria-label="Select Comp"
            name="header_text"
            className={
              'form-control' +
              (formik.errors.header_text && formik.touched.header_text ? ' is-invalid' : '')
            }
            value={formik.values.header_text}
            onChange={formik.handleChange}
            id="header_text"
          >
            <option value={0}>Select Header</option>
            {headerText.map((header, index) => (
              <option key={index} value={header}>
                {header}
              </option>
            ))}
          </CFormSelect>
          {formik.errors.header_text && formik.touched.header_text && (
            <CFormFeedback invalid>{formik.errors.header_text}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Page Name
          </CFormLabel>
          <CFormInput
            placeholder="Page Name"
            className={
              'form-control' +
              (formik.errors.page_name && formik.touched.page_name ? ' is-invalid' : '')
            }
            value={formik.values.page_name}
            onChange={formik.handleChange}
            aria-label="page_name"
            id="page_name"
          />
          {formik.errors.page_name && formik.touched.page_name && (
            <CFormFeedback invalid>{formik.errors.page_name}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Label Name
          </CFormLabel>
          <CFormInput
            placeholder="Label Name"
            className={
              'form-control' +
              (formik.errors.label_name && formik.touched.label_name ? ' is-invalid' : '')
            }
            value={formik.values.label_name}
            onChange={formik.handleChange}
            aria-label="label_name"
            id="label_name"
          />
          {formik.errors.label_name && formik.touched.label_name && (
            <CFormFeedback invalid>{formik.errors.label_name}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Tooltip
          </CFormLabel>
          <CFormInput
            placeholder="Tooltip"
            className={
              'form-control' +
              (formik.errors.tooltip && formik.touched.tooltip ? ' is-invalid' : '')
            }
            value={formik.values.tooltip}
            onChange={formik.handleChange}
            aria-label="tooltip"
            id="tooltip"
          />
          {formik.errors.tooltip && formik.touched.tooltip && (
            <CFormFeedback invalid>{formik.errors.tooltip}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Share Link
          </CFormLabel>
          <CFormInput
            placeholder="Share Link"
            className={
              'form-control' +
              (formik.errors.share_link && formik.touched.share_link ? ' is-invalid' : '')
            }
            value={formik.values.share_link}
            onChange={formik.handleChange}
            aria-label="share_link"
            id="share_link"
          />
          {formik.errors.share_link && formik.touched.share_link && (
            <CFormFeedback invalid>{formik.errors.share_link}</CFormFeedback>
          )}
        </CCol>

        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Content
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={value}
            onChange={setValue}
            modules={modules}
            // style={{ border: '1px solid' }}
          />
          {formik.errors.sponsor_link && formik.touched.sponsor_link && (
            <CFormFeedback invalid>{formik.errors.sponsor_link}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
