import React from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import ScorecardService from 'src/service/ScorecardService'
import ToastComponent from 'src/components/common/TaostComponent'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
const FinalScorecardModal = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const handleMarkAsComplete = () => {
    setLoading(true)
    ScorecardService.markAsComplete(props.fixtureId)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          ToastComponent(res.message, 'success')
          navigate('/manage-scores')
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong.', 'error')
      })
  }

  return (
    <>
      <CModal
        backdrop="static"
        visible={props.finalScorecardModalVisible}
        onClose={() => props.setFinalScorecardModalVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CModalTitle style={{ color: 'red' }}>
            ALERT: COMPLETED FIXTURES CANNOT BE RE-OPENED / REVERSED
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p className="fw-bold">
            Press <b>Submit</b> to confirm and mark this fixture as Completed!
          </p>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={() => props.setFinalScorecardModalVisible(false)}>
            Go Back
          </CButton>
          <CButton color="success" onClick={handleMarkAsComplete} disabled={loading}>
            Submit
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default FinalScorecardModal
