import React, { useCallback, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CButtonGroup,
  CFormCheck,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CButton,
  CDropdownItem,
  CDropdownMenu,
  CBadge,
  CDropdownToggle,
  CDropdown,
  CDropdownHeader,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilApplicationsSettings,
  cilApps,
  cilBell,
  cilExternalLink,
  cilFlipToBack,
  cilInfo,
  cilMenu,
  cilMoon,
  cilShareAlt,
  cilSun,
  cilUserFollow,
} from '@coreui/icons'

import { AppBreadcrumb } from './index'

import { AppHeaderDropdown, AppHeaderDropdownNotif, AppHeaderDropdownTasks } from './header/index'

// import { logo } from 'src/assets/brand/logo.jpg'
import logo from '../assets/brand/logo.jpg'
import GameLinkBar from './header/GameLinkBar'
import PageInfoModal from './PageInfoModal'
import CommonService from 'src/service/CommonService'
import ClaimPlayerProfileModal from './ClaimPlayerProfileModal'

const AppHeader = () => {
  const navigate = useNavigate()
  const [gameName, setGameName] = useState('')
  const [notificationCount, setNotificationCount] = useState(0)
  const [verifyPlayerList, setVerifyPlayerList] = useState(0)
  const [infoText, setInfoText] = useState('')
  const authenticated = localStorage.getItem('authenticated')
  const params = useParams()
  const uri = params['*']
  const fetchAppHeaderDetails = useCallback(() => {
    CommonService.getGameName(uri).then((res) => {
      if (res.success) {
        setGameName(res.game_name)
        setNotificationCount(res.notification_count)
        setVerifyPlayerList(res.verifyPlayerList)
        setInfoText(res?.infoText)
      }
    })
  }, [uri, setGameName, setNotificationCount, setVerifyPlayerList, setInfoText])
  useEffect(() => {
    if (!authenticated) {
      navigate('/login')
    }
    fetchAppHeaderDetails()
  }, [navigate, authenticated, fetchAppHeaderDetails])

  const dispatch = useDispatch()
  const handleAccountPage = () => {
    navigate('/account')
  }
  const theme = useSelector((state) => state.theme)

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [infoPageModal, setInfoPageModal] = useState(false)

  // const pathName = window.location.pathname
  const handleInfoClick = () => {
    setInfoPageModal(true)
    CommonService.getHeaderInfoDetailsFromPathName(uri)
  }

  // Claim Player Profile modal open
  const [showClaimProfile, setShowClaimProfile] = useState(false)
  const handleCloseModal = (data) => {
    setShowClaimProfile(false)
  }
  // Claim Player Profile modal
  const adminDetails = JSON.parse(localStorage.getItem('admin'))
  return (
    <>
      <CHeader position="sticky" className="mb-4">
        <CContainer fluid>
          <CHeaderToggler
            className="ps-1"
            onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          >
            <CIcon icon={cilMenu} size="lg" />
            <span> {gameName ?? 'Basketball'}</span>
          </CHeaderToggler>

          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CIcon icon={logo} height={48} alt="Logo" />
          </CHeaderBrand>

          <CHeaderNav className="ms-auto me-4">
            {/* <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === 'default'}
              onChange={() => dispatch({ type: 'set', theme: 'light' })}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === 'dark'}
              onChange={() => dispatch({ type: 'set', theme: 'dark' })}
            />
          </CButtonGroup> */}
          </CHeaderNav>
          {adminDetails?.user_role_id !== 1 && (
            <CHeaderNav>
              <CHeaderNav className="ms-auto">
                <CButton
                  type="button"
                  color="primary"
                  onClick={handleAccountPage}
                  title="Admin Account"
                  style={{ cursor: 'pointer' }}
                  // className="ml-5"
                >
                  {' '}
                  <CIcon icon={cilFlipToBack} size="lg" /> {' HUB'}
                </CButton>
              </CHeaderNav>
              <CDropdown variant="nav-item" alignment="end">
                <CDropdownToggle caret={false}>
                  <CIcon icon={cilBell} size="lg" className="my-1 mx-2" />
                  <CBadge
                    shape="rounded-pill"
                    color="danger-gradient"
                    className="position-absolute top-0 end-0"
                  >
                    {notificationCount}
                  </CBadge>
                </CDropdownToggle>
                <CDropdownMenu className="pt-0">
                  <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10">
                    <strong>You have {notificationCount} notifications</strong>
                  </CDropdownHeader>
                  {verifyPlayerList &&
                    verifyPlayerList.map((item, key) => (
                      <CDropdownItem key={key}>
                        <Link onClick={() => setShowClaimProfile(true)}>
                          <CIcon icon={cilUserFollow} className="me-2 text-success" />
                          Player Verification Request: {item?.full_name}{' '}
                        </Link>
                      </CDropdownItem>
                    ))}
                </CDropdownMenu>
              </CDropdown>
              {/* <AppHeaderDropdownNotif /> */}

              {/* <GameLinkBar /> */}
              {/* <AppHeaderDropdownMssg /> */}
            </CHeaderNav>
          )}
          {adminDetails?.user_role_id === 1 && (
            <CHeaderNav className="ms-3 me-4">
              <AppHeaderDropdown />
            </CHeaderNav>
          )}
          <CHeaderNav className="ms-3 me-4">{/* <AppHeaderDropdown /> */}</CHeaderNav>
          {/* <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler> */}
        </CContainer>
        <CHeaderDivider />
        <CContainer fluid>
          <AppBreadcrumb />
          <CHeaderNav className="ms-auto">
            <CButtonGroup aria-label="Theme switch">
              {/*  'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string */}

              <CButton
                color={'warning'}
                onClick={handleInfoClick}
                // onMouseEnter={() => console.log('blur happed')}
                title={infoText?.tooltip}
              >
                <CIcon icon={cilInfo} />
              </CButton>
              <CButton color={'primary'}>
                <CIcon icon={cilExternalLink} />
              </CButton>
            </CButtonGroup>
          </CHeaderNav>
          <span></span>
          <span></span>
        </CContainer>
        <PageInfoModal
          infoPageModal={infoPageModal}
          setInfoPageModal={setInfoPageModal}
          infoText={infoText}
        />
      </CHeader>
      <ClaimPlayerProfileModal
        showClaimProfile={showClaimProfile}
        setShowClaimProfile={setShowClaimProfile}
        onCloseModal={handleCloseModal}
        verifyPlayerList={verifyPlayerList}
        fetchAppHeaderDetails={fetchAppHeaderDetails}
      />
    </>
  )
}

export default AppHeader
