import React, { useEffect, useState } from 'react'
import {
  CRow,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
  CAccordion,
  CButton,
  CCol,
  CFormLabel,
  CFormSelect,
  CHeader,
  CContainer,
  CHeaderNav,
  CFormInput,
  CTooltip,
  CLink,
} from '@coreui/react-pro'
import logo from '../../assets/brand/logo.jpg'
import Notify from '../Notify'
import CommonService from 'src/service/CommonService'
import Loader from 'src/components/Loader'
import { AppHeaderDropdown } from 'src/components/header'
import { AppFooter } from 'src/components'
import ToastComponent from 'src/components/common/TaostComponent'
import PlayerService from 'src/service/PlayerService'

const CopyPlayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [gameDropdown, setGameDropdown] = useState([])
  const [users, setUsers] = useState([])
  const [fromGameId, setFromGameId] = useState(0)
  const [toGameId, setToGameId] = useState(0)
  const [selectValueSystem, setSelectValueSystem] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)

  const fetchData = async () => {
    try {
      const infoTextResponse = await CommonService.getGameList()
      if (infoTextResponse.status === 200) {
        setIsLoading(false)
        setGameDropdown(infoTextResponse.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchCopiedPlayers = async (fromGameId, sortBy, searchTerm, sortOrder) => {
    try {
      const params = {
        fromGameId,
        sortBy,
        searchTerm: searchTerm, // Ensure searchTerm is passed as 'search' in params
        sortOrder,
      }

      const response = await PlayerService.getCopiedPlayers(params)
      if (response.status === 200) {
        setUsers(response.data)
      } else {
        console.error('Error fetching players:', response.data)
      }
    } catch (error) {
      console.error('Error fetching players:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchCopiedPlayers(fromGameId, sortBy, searchTerm, sortOrder)
  }, [fromGameId, sortBy, sortOrder, searchTerm])

  const handleFromGameChange = (value) => {
    setFromGameId(Number(value))
    if (Number(value) === toGameId) {
      setToGameId(0)
    }
  }

  const handleToGameChange = (value) => {
    setToGameId(Number(value))
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSort = (column) => {
    let newSortOrder = 'asc' // Default sort order
    if (column === sortBy) {
      // If clicking on the same column again, toggle the sort order
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setSortBy(column)
    setSortOrder(newSortOrder)

    // Perform the sort
    const sortedUsers = [...users].sort((a, b) => {
      if (column === 'next_svalue') {
        return newSortOrder === 'asc' ? a[column] - b[column] : b[column] - a[column]
      }
      if (column === 'average_value') {
        return newSortOrder === 'asc' ? a[column] - b[column] : b[column] - a[column]
      }
      if (a[column] < b[column]) {
        return newSortOrder === 'asc' ? -1 : 1
      }
      if (a[column] > b[column]) {
        return newSortOrder === 'asc' ? 1 : -1
      }
      return 0
    })

    setUsers(sortedUsers)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const res = await PlayerService.saveCopiedPlayers({
        fromGameId,
        toGameId,
        selectValueSystem,
        selectedPlayers: selectedPlayers, // Send only player_id array
      })
      if (res.status === 200) {
        setShowConfirm(false)
        setIsLoading(false)
        ToastComponent(res.message, 'success')
      }
    } catch (error) {
      console.error('Error saving players:', error)
    }
  }

  const handleCopyPlayersClick = () => {
    setShowConfirm(true)
  }

  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const togglePlayerSelection = (playerId) => {
    const selectedIds = [...selectedPlayers]
    if (selectedIds.includes(playerId)) {
      setSelectedPlayers(selectedIds.filter((id) => id !== playerId))
    } else {
      setSelectedPlayers([...selectedIds, playerId])
    }
  }

  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedPlayers([])
    } else {
      const allPlayerIds = users.map((player) => player.id)
      setSelectedPlayers(allPlayerIds)
    }
    setSelectAllChecked(!selectAllChecked)
  }

  const filteredGameDropdown = gameDropdown.filter((item) => item.id !== fromGameId)

  const handleSelectValue = (value) => {
    setSelectValueSystem(value)
  }
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CRow>
        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Copy Players</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="fromGameId">
                    Copy Players From Game*
                  </CFormLabel>
                  <CFormSelect
                    aria-label="select Game"
                    name="fromGameId"
                    className={'form-control'}
                    value={fromGameId}
                    onChange={(e) => handleFromGameChange(e.target.value)}
                    id="fromGameId"
                  >
                    <option value={0}>Select Game</option>
                    {gameDropdown &&
                      gameDropdown.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.game_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="toGameId">
                    Copy Players To Game*
                  </CFormLabel>
                  <CFormSelect
                    aria-label="select Game"
                    name="toGameId"
                    className={'form-control'}
                    value={toGameId}
                    onChange={(e) => handleToGameChange(e.target.value)}
                    id="toGameId"
                    // disabled={fromGameId === 0}
                  >
                    <option value={0}>Select Game</option>
                    {filteredGameDropdown &&
                      filteredGameDropdown.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.game_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="valueSystem">
                    Copy Fantasy Value Type*
                  </CFormLabel>
                  <CFormSelect
                    aria-label="select Game"
                    name="valueSystem"
                    className={'form-control'}
                    value={selectValueSystem}
                    onChange={(e) => handleSelectValue(e.target.value)}
                    id="valueSystem"
                    // disabled={fromGameId === 0}
                  >
                    <option value={1}>Rounded Values</option>
                    <option value={2}>Starting Values</option>
                    <option value={3}>Average Values</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md={6} className="d-grid">
                  <CButton
                    onClick={handleCopyPlayersClick}
                    color="success"
                    variant="outline"
                    disabled={fromGameId === 0 || toGameId === 0}
                  >
                    Copy Players
                  </CButton>
                </CCol>
                <CCol md={6} className="d-grid">
                  <CButton component="a" color="primary" href="/account" role="button">
                    Back
                  </CButton>
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>

        <CAccordion alwaysOpen activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>Players</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <div className="mb-3">
                <CFormLabel htmlFor="search">Search Players</CFormLabel>
                <CFormInput
                  id="search"
                  type="text"
                  placeholder="Search by name or position"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div style={{ overflowY: 'auto', maxHeight: '800px' }}>
                <table
                  className="main-table table innertable"
                  style={{ width: '100%', borderCollapse: 'collapse' }}
                >
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: 'white',
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAllChecked}
                        />
                      </th>
                      <th onClick={() => handleSort('full_name')}>
                        Player {sortBy === 'full_name' && (sortOrder === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('position_name')}>
                        Position {sortBy === 'position_name' && (sortOrder === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('start_fv')}>
                        <CTooltip content="This was the Starting Value of the players in the previous game.">
                          <CLink>
                            Starting FV {sortBy === 'start_fv' && (sortOrder === 'asc' ? '▲' : '▼')}
                          </CLink>
                        </CTooltip>
                      </th>
                      <th onClick={() => handleSort('current_fv')}>
                        <CTooltip content="This is the Current Value of the players in the previous game.">
                          <CLink>
                            Current FV{' '}
                            {sortBy === 'current_fv' && (sortOrder === 'asc' ? '▲' : '▼')}
                          </CLink>
                        </CTooltip>
                      </th>
                      <th onClick={() => handleSort('average_value')}>
                        <CTooltip content="This is the Average of Starting & Current Value in the previous game, rounded to the closest available default value.">
                          <CLink>
                            {' '}
                            Average FV{' '}
                            {sortBy === 'average_value' && (sortOrder === 'asc' ? '▲' : '▼')}
                          </CLink>
                        </CTooltip>
                      </th>
                      <th onClick={() => handleSort('next_svalue')}>
                        <CTooltip content="This is the Rounded Value of the Current Value in the previous game, rounded to the closest available default value.">
                          <CLink>
                            {' '}
                            Rounded FV{' '}
                            {sortBy === 'next_svalue' && (sortOrder === 'asc' ? '▲' : '▼')}{' '}
                          </CLink>
                        </CTooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedPlayers.includes(user.id)}
                            onChange={() => togglePlayerSelection(user.id)}
                          />
                        </td>
                        <td>{user.full_name}</td>
                        <td>{user.position_name}</td>
                        <td>{user.start_fv}</td>
                        <td>{user.current_fv}</td>
                        <td>{user.next_svalue}</td>
                        <td>{user.average_value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleConfirm={handleSubmit}
        handleCancel={() => setShowConfirm(false)}
        text="Are you sure you want to copy these player records?"
      />
    </>
  )
}

export default CopyPlayer
