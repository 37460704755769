import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CCard,
  CCardHeader,
  CCardBody,
  CCol,
  CFormLabel,
  CFormFeedback,
  CLoadingButton,
  CMultiSelect,
  CModalHeader,
  CBadge,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import UserService from 'src/service/UserService'

const GameListingModal = (props) => {
  const marAsActive = (gameId, status) => {
    const data = {}
    data.gameId = gameId
    data.status = status
    UserService.markAsActiveGame(data).then((res) => {
      if (res.status === 200) {
        props.onCloseModal(false)
        ToastComponent(res.message, 'success')
      }
    })
  }

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'default'
    }
  }

  return (
    <>
      <CModal visible={props.IsModalOpened} size="lg">
        <CModalHeader>Games</CModalHeader>
        <CModalBody>
          <table className="main-table table innertable">
            <thead>
              <tr>
                <th>Game Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {props.userDataListing &&
                props.userDataListing?.map((item, key) => (
                  <tr key={key}>
                    <th>{item?.game_name}</th>
                    <th>
                      <CBadge color={getBadge(item.game_status)}>
                        {' '}
                        {item.game_status === 1 ? 'Activated' : 'In-Active'}
                      </CBadge>
                    </th>
                    <td>
                      <CButton color="primary" size="sm" onClick={() => marAsActive(item?.id, 1)}>
                        Activate
                      </CButton>{' '}
                      <CButton color="info" size="sm" onClick={() => marAsActive(item?.id, 0)}>
                        Deactivate
                      </CButton>
                    </td>
                  </tr>
                ))}
              {props.userDataListing.length === 0 && (
                <tr>
                  <td colSpan={2}>No record yet available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </CModalBody>

        <CModalFooter>
          <CButton color="primary" onClick={(e) => props.onCloseModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default GameListingModal
