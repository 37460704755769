import React, { useState, useEffect } from 'react'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import { Col } from 'react-bootstrap'
import BracketBattleService from 'src/service/BracketBattleService'
import ToastComponent from 'src/components/common/TaostComponent'
const FinalizeResult = (props) => {
  const [loader, setLoader] = useState(false)
  const [statusValue, setStatusValue] = useState(0)
  const handleChange = (value) => {
    setStatusValue(value)
    console.log('Value is here', value)
  }
  const submitStatus = () => {
    setLoader(true)

    const data = {}
    data.roundId = props.roundId
    BracketBattleService.matchCompletion(data).then((res) => {
      setLoader(false)

      props.setTieMatchedList(res.data)
      ToastComponent(res.message, 'success')
    })
  }
  return (
    <CCard className="mb-4">
      <CCardHeader>
        <strong>Finalise Round {props.bracketStructure?.round} Result</strong>
      </CCardHeader>

      <CCardBody>
        <Col md={12}>
          {' '}
          <p>
            Once all your score updates for the gameweeks in the Round have been completed, set the
            status of the Round to Completed, to trigger the completion of this round, so you are
            ready to move to the next Round.
          </p>
        </Col>
        <Col md={3}>
          {props.bracketStructure?.status !== 2 && (
            <CFormSelect
              id="status"
              name={`status`}
              value={props.bracketRoundStatus}
              // onChange={formik.handleChange}
              onChange={(e) => {
                handleChange(e.target.value)
                props.setBracketRoundStatus(e.target.value)
              }}
            >
              <option value={0}>Status</option>
              <option value={2}>Completed</option>
            </CFormSelect>
          )}
          {props.bracketStructure?.status === 2 && <CBadge color={'success'}>{'Completed'}</CBadge>}
        </Col>
        {props.bracketStructure?.status !== 2 && (
          <Col md={3} className="mt-3">
            <CLoadingButton
              type="button"
              color="success"
              variant="outline"
              onClick={submitStatus}
              loading={loader}
            >
              Submit
            </CLoadingButton>
          </Col>
        )}
      </CCardBody>
    </CCard>
  )
}

export default FinalizeResult
