import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import UserService from 'src/service/UserService'
const ManageMemebership = () => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState([])

  const columns = [
    {
      label: 'Member',
      key: 'full_name',
    },
    {
      label: 'Account',
      key: 'user_role_id',
    },
    {
      label: 'Email',
      key: 'email',
    },
    { label: 'Status', filter: false, key: 'membership_is_paid' },
    {
      key: 'action',
      label: 'Action',
      filter: false,
      sorter: false,
    },
  ]

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    UserService.membershipListing(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }

  const changeMembership = (userId, status) => {
    UserService.changeMembership(userId, status).then((res) => {
      fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
      ToastComponent(res.message, 'success')
      setLoading(false)
    })
  }
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Members</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  membership_is_paid: (item) => {
                    return (
                      <td>
                        {item?.membership_is_paid ? (
                          <CBadge color={'success'}>Paid </CBadge>
                        ) : (
                          <CBadge color={'danger'}>Unpaid</CBadge>
                        )}
                      </td>
                    )
                  },
                  user_role_id: (item) => {
                    return (
                      <td>
                        {item?.user_role_id === 3 ? (
                          <CBadge color={'success'}>Admin </CBadge>
                        ) : (
                          <CBadge color={'primary'}>Member</CBadge>
                        )}
                      </td>
                    )
                  },
                  action: (item) => {
                    return (
                      <>
                        <td>
                          {item?.membership_is_paid ? (
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                changeMembership(item.id, 0)
                              }}
                            >
                              Mark Unpaid
                            </CButton>
                          ) : (
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                changeMembership(item.id, 1)
                              }}
                            >
                              Mark Paid
                            </CButton>
                          )}
                        </td>
                      </>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default ManageMemebership
