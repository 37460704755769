import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer, cilUser, cilMoney } from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/mct-dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Members ',
    to: '/',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Admins',
        to: '/game-admins',
      },
      {
        component: CNavItem,
        name: 'Members',
        to: '/game-users',
      },
      {
        component: CNavItem,
        name: 'Games',
        to: '/games',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Pro-Membership ',
    to: '/',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Manage',
        to: '/manage-membership',
      },
      {
        component: CNavItem,
        name: 'Pro-Members',
        to: '/membership-members',
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Content ',
    to: '/',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'CMS',
        to: '/cms',
      },
      {
        component: CNavItem,
        name: 'About',
        to: '/about',
      },
      {
        component: CNavItem,
        name: 'Info Box',
        to: '/admin-info-box',
      },
      {
        component: CNavItem,
        name: 'Showcase',
        to: '/showcase',
      },
      {
        component: CNavItem,
        name: 'Articles',
        to: '/articles',
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Mail ',
    to: '/',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Email Template',
        to: '/email-template',
      },
      {
        component: CNavItem,
        name: 'Email Logs',
        to: '/email-logs',
      },
    ],
  },
]

export default _nav
