import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CMultiSelect,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
import Loader from 'src/components/Loader'
import TeamService from 'src/service/TeamService'
import CommonService from 'src/service/CommonService'

const PlayerBulkImageUpload = (props) => {
  const [multiOption, setMultiOption] = useState([])
  const [users, setUsers] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }
  useEffect(() => {
    CommonService.clubPlayers().then((result) => {
      setMultiOption(result.data)
    })
  }, [])

  const SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    player: Yup.array().required('Player is required'),
    image: Yup.mixed()
      .nullable(true)
      .required('Image is required')
      .test('fileSize', 'File size too large, max file size is 5 Mb', (file) => {
        if (file) {
          return file.size <= 5500000
        } else {
          return true
        }
      })
      .test(
        'type',
        'Invalid file format selection',
        (value) =>
          // console.log(value);
          !value || (value && SUPPORTED_FORMATS.includes(value?.type)),
      ),
  })
  const formik = useFormik({
    initialValues: {
      player: [],
      image: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      var formData = new FormData()
      data.player.forEach((playerValue) => {
        formData.append('player[]', playerValue)
      })
      formData.append('image', data.image)
      setLoader(true)
      PlayerService.saveBulkPlayerImage(formData).then((res) => {
        if (res.status === 200) {
          formik.resetForm({
            values: {
              player: [],
              image: null,
            },
          })
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
      setLoader(false)
    },
  })

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CMultiSelect
            options={multiOption}
            selectionType="tags"
            name="player"
            className={formik.errors.player && formik.touched.player ? 'is-invalid' : ''}
            onChange={(selectedOptions) => {
              formik.setFieldValue(
                'player',
                selectedOptions.map((option) => option.value), // Extracting values from selected objects
              )
              formik.setFieldTouched('player', true) // Marking the field as touched
            }}
            value={multiOption.filter((obj) => formik.values.player.includes(obj.value))}
          />
          {formik.errors.player && formik.touched.player && (
            <CFormFeedback invalid>{formik.errors.player}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormInput
            type="file"
            id="formFile"
            name="image"
            className={
              formik.touched.image
                ? formik.errors.image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                image: true,
              })
              formik.setFieldValue('image', event.target.files[0])
            }}
          />
          <br></br>
          {formik.values.image ? (
            <PreviewImage
              className={{ margin: 'auto' }}
              width={100}
              height={100}
              file={formik.values.image}
            />
          ) : null}
          {formik.touched.image && formik.errors.image ? (
            <CFormFeedback invalid>{formik.errors.image}</CFormFeedback>
          ) : null}
        </CCol>

        <CCol md={4}>
          <div className="form-group">
            {' '}
            <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
              Submit
            </CLoadingButton>
          </div>
        </CCol>
      </CForm>
    </>
  )
}

export default PlayerBulkImageUpload
