import React, { useEffect } from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {
  CForm,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CFormInput,
  CFormCheck,
  CFormFeedback,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react-pro'
import { Col, Row } from 'react-bootstrap'
import PaymentModal from '../ActivateGame/PaymentModal'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import ClubService from 'src/service/ClubService'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Loader from './AdminLoader'

const AdminLogin = () => {
  let [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  var userId = searchParams.get('user_id')
  const data = {}
  data.userId = userId
  useEffect(() => {
    setLoading(true)
    CommonService.gameLogin(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          localStorage.setItem('admin', JSON.stringify(res.user))
          localStorage.setItem('token', JSON.stringify(res.token))
          localStorage.setItem('authenticated', true)
          navigate('/account')
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }, [])

  return <div>{loading ? <Loader /> : <div>Loading....</div>}</div>
}

export default AdminLogin
