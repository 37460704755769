import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const SetHeadToHeadPoint = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    winner_points: Yup.string().required('Winner points are required'),
    loosers_points: Yup.string().required('Looser points are required'),
    tie_points: Yup.string().required('Tie points are required'),
  })
  const formik = useFormik({
    initialValues: {
      winner_points: props.data?.winner_points,
      loosers_points: props.data?.loosers_points,
      tie_points: props.data?.tie_points,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      HeadToHeadService.saveHeadToHeadPoint(data).then((res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CFormLabel htmlFor="name" className="fw-bold">
            Win Points *
          </CFormLabel>
          <CFormInput
            placeholder="Winner Points"
            type="number"
            className={
              'form-control' +
              (formik.errors.winner_points && formik.touched.winner_points ? ' is-invalid' : '')
            }
            value={formik.values.winner_points}
            onChange={formik.handleChange}
            aria-label="winner_points"
            id="winner_points"
          />
          {formik.errors.winner_points && formik.touched.winner_points && (
            <CFormFeedback invalid>{formik.errors.winner_points}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor="name" className="fw-bold">
            Loss Points *
          </CFormLabel>
          <CFormInput
            placeholder="Looser Points"
            type="number"
            className={
              'form-control' +
              (formik.errors.loosers_points && formik.touched.loosers_points ? ' is-invalid' : '')
            }
            value={formik.values.loosers_points}
            onChange={formik.handleChange}
            aria-label="loosers_points"
            id="loosers_points"
          />
          {formik.errors.loosers_points && formik.touched.loosers_points && (
            <CFormFeedback invalid>{formik.errors.loosers_points}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor="tie" className="fw-bold">
            Tie Points *
          </CFormLabel>
          <CFormInput
            placeholder="Tie Points"
            type="number"
            className={
              'form-control' +
              (formik.errors.tie_points && formik.touched.tie_points ? ' is-invalid' : '')
            }
            value={formik.values.tie_points}
            onChange={formik.handleChange}
            aria-label="tie_points"
            id="tie_points"
          />
          {formik.errors.tie_points && formik.touched.tie_points && (
            <CFormFeedback invalid>{formik.errors.tie_points}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Save
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default SetHeadToHeadPoint
