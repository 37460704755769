import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CMultiSelect,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SaveGroupOne from './SaveGroupOne'
import SaveGroupTwo from './SaveGroupTwo'

const ChooseMember = (props) => {
  return (
    <>
      <CRow>
        <CCol xs={12} sm={12} md={6}>
          <SaveGroupOne
            memberList={props.memberList}
            setMemberList={props.setMemberList}
            fetchData={props.fetchData}
            group1Count={props.group1Count}
            setGroup1Count={props.setGroup1Count}
          />
        </CCol>
        <CCol xs={12} sm={12} md={6}>
          <SaveGroupTwo
            memberList2={props.memberList2}
            setMemberList2={props.setMemberList2}
            fetchData={props.fetchData}
            group2Count={props.group2Count}
            setGroup1Count={props.setGroup1Count}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default ChooseMember
