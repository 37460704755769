import React, { useEffect } from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {
  CForm,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CFormInput,
  CFormCheck,
  CFormFeedback,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react-pro'
import { Col, Row } from 'react-bootstrap'
import PaymentModal from '../ActivateGame/PaymentModal'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import ClubService from 'src/service/ClubService'
import { Link } from 'react-router-dom'

const ScorerPlus = () => {
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState()
  const [noOfFixture, setNoOfFixture] = useState()
  const [activationInfo, setActivationInfo] = useState([])
  const accountInfo = JSON.parse(localStorage.getItem('admin'))
  const [invoiceLink, setInvoiceLink] = useState(true)
  // console.log(accountInfo)
  useEffect(() => {
    CommonService.getGameActivateInfo(3)
      .then((res) => {
        if (res.status === 200) {
          setActivationInfo(res.getGameActivationInfo)
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }, [])
  const getInvoiceLink = (transInfo) => {
    const invoiceURL = JSON.parse(transInfo)
    return invoiceURL.receipt_url
    // setInvoiceLink(invoiceURL.receipt_url)
  }
  const [visible, setVisible] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const showFinalAmount = (e) => {
    const value = e.target.value
    const changeAmonunt = 2 * value
    setNoOfFixture(value)
    setAmount(changeAmonunt)
  }

  const handleActiveTerm = (event) => {
    if (event.target.checked) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  }
  const validationSchema = Yup.object().shape({
    fixture_number: Yup.number()
      .min(10, 'Minimum value of 10 is required')
      .max(200, 'Maximum value of 200 is required')
      .required('Enter fixture numbers.'),
  })
  const formik = useFormik({
    initialValues: {
      fixture_number: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      data.type = 'scorer_plus'
      setVisible(true)
      // ClubService.updateFeeInfo(data)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       ToastComponent(res.message, 'success')
      //       setLoading(false)
      //     } else {
      //       setLoading(false)
      //       ToastComponent(res.message, 'error')
      //     }
      //   })
      //   .catch((e) => {
      //     ToastComponent('Something went wrong.', 'error')
      //     setLoading(false)
      //   })
    },
  })

  const hanldePayNow = () => {
    //setVisible(true)
  }
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Service 2: Scorer+ (optional)</strong>
        </CCardHeader>
        <CCardBody>
          Let us take care of your score updates
          <ul className="mt-2">
            <li>Fixtures per team based pricing</li>
            <li>Fees: $2 per fixture/team</li>
            <li>SLA: 24-48 hours</li>
            <li>Minimum: 10 Fixtures ($20)</li>
          </ul>
          <CForm className="row g-3" onSubmit={formik.handleSubmit}>
            <CRow className="">
              <div className="col-md-6">
                <CFormInput
                  type="number"
                  name="fixture_number"
                  id="inputPassword2"
                  min={10}
                  max={200}
                  defaultValue={noOfFixture}
                  className={
                    'form-control' +
                    (formik.errors.fixture_number && formik.touched.fixture_number
                      ? ' is-invalid'
                      : '')
                  }
                  placeholder="Set number of fixtures*"
                  // onChange={showFinalAmount}
                  onChange={(event) => {
                    formik.setTouched({
                      ...formik.touched,
                      fixture_number: true,
                    })
                    formik.setFieldValue('fixture_number', event.target.value)
                    showFinalAmount(event)
                  }}
                />
              </div>
              {formik.errors.fixture_number && formik.touched.fixture_number && (
                <CFormFeedback style={{ color: '#dc3545' }}>
                  {formik.errors.fixture_number}
                </CFormFeedback>
              )}
              <span>(Fees: ${amount ?? 0})</span>
            </CRow>

            <Row className="mt-4">
              <Col>
                <p className="mt-2">
                  Accept{' '}
                  <a
                    href="https://cricket.myclubtap.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of use
                  </a>{' '}
                </p>
                <div className="form-group mb-3">
                  <CFormCheck
                    inline
                    id="inlineCheckbox1"
                    value="option1"
                    label="I agree to the terms of use"
                    onChange={handleActiveTerm}
                  />
                </div>
              </Col>
            </Row>

            <CRow className="mt-4">
              <div className="col-md-6">
                <CButton onClick={() => setVisible(!visible)} disabled={isChecked}>
                  Pay Now (${amount ?? 0})
                </CButton>
              </div>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mb-4">
        <CCardHeader>
          <strong>Transactions</strong>
        </CCardHeader>
        <CCardBody>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">Fixtures</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                <CTableHeaderCell scope="col">Invoice</CTableHeaderCell>
                <CTableHeaderCell scope="col">Paid On</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {activationInfo &&
                activationInfo.map((item, key) => (
                  <CTableRow key={key}>
                    <CTableHeaderCell scope="row">{item.fixtures_count}</CTableHeaderCell>
                    <CTableDataCell>${item?.fee}</CTableDataCell>
                    <CTableDataCell>
                      {/* <button onClick={handleDownload}>Download Invoice</button> */}
                      <a
                        href={getInvoiceLink(item?.transaction_information)}
                        target="_blank"
                        rel={'noreferrer'}
                      >
                        View
                      </a>
                    </CTableDataCell>
                    <CTableDataCell>
                      {' '}
                      {moment(item?.created_at).format('D.MM.YYYY h:mm A')}
                    </CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>

      <PaymentModal
        visible={visible}
        setVisible={setVisible}
        activationInfo={activationInfo}
        setActivationInfo={setActivationInfo}
        amount={amount}
        type={3}
        noOfFixture={noOfFixture}
      />
    </>
  )
}

export default ScorerPlus
