import React, { useEffect, useState } from 'react'
import { CBadge, CButton } from '@coreui/react-pro'
import { toast } from 'react-toastify'
import moment from 'moment'
import Bootbox from 'bootbox-react'

import { useNavigate } from 'react-router-dom'
import CommonService from 'src/service/CommonService'
import Loader from 'src/components/Loader'
import BonusCardModal from './BonusCardModal'
import BonusCardPlayer from './BonusCardPlayer'
import ToastComponent from 'src/components/common/TaostComponent'
const BonusTable = (props) => {
  const [roundNumber, setRoundNumber] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false)
  const [playerModalOpen, setPlayerModalOpen] = useState(false)
  const handleCloseModal = (data) => {
    setIsOpen(false)
  }

  const handlePlayerCloseModal = (data) => {
    setPlayerModalOpen(false)
  }

  const [playerData, setPlayerData] = useState([])
  const [teamStatus, setTeamStatus] = useState(0)

  // Point Modal Box
  function openFromParentForPlayer(teamPowerId, roundId) {
    setPlayerData([])
    setRoundNumber()
    CommonService.bonusCardPlayer(teamPowerId).then((result) => {
      setPlayerData(result.data)
      setTeamStatus(result.teamStatus)
    })
    setPlayerModalOpen(true)
    setRoundNumber(roundId)
  }

  const [bonusCardDetail, setBonusCardDetail] = useState({})
  const [selectedPlayer, setSelectedPlayer] = useState([])
  const [options, setOptions] = useState([])

  // Set Up Modal Box
  function openFromParent(roundId) {
    setBonusCardDetail([])
    setSelectedPlayer([])
    CommonService.bonusCardSelectedPlayer(roundId).then((result) => {
      setOptions(result.data)
    })
    CommonService.getBonusCardDetail(roundId).then((result) => {
      setBonusCardDetail(result.data)
      setSelectedPlayer(result.selected_player)
    })

    setIsOpen(true)
    setRoundNumber(roundId)
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const showCofirmBox = (recordId) => {
    setRecordId(recordId)
    setShowConfirm(true)
  }
  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }

  const changeStatus = () => {
    setShowConfirm(false)
    const data = {}
    data.teamPowerId = recordId
    CommonService.revertBonusCard(data).then((res) => {
      if (res.success) {
        ToastComponent(res.message, 'success')
        props.fetchData()
      }
    })
  }

  return props.loading ? (
    <Loader />
  ) : (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>Round</th>
            <th>Duration</th>
            <th>Bonus Card Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.users &&
            props.users.map((item, key) => (
              <tr key={key}>
                <th>{item.round}</th>
                <th>
                  {' '}
                  {moment(item.start_date).format('MMM D')}-{moment(item.end_date).format('MMM D')}
                </th>
                <td>{item?.bonus_card_name}</td>
                <td>
                  {item?.status === 1 && <CBadge color="success">Active</CBadge>}
                  {(item?.status === 0 || !item?.status) && (
                    <CBadge color="danger">In-Active</CBadge>
                  )}
                </td>
                <td>
                  <CButton onClick={() => openFromParent(item.round)} color={'success'}>
                    Set Up
                  </CButton>
                  &nbsp;
                  <CButton
                    className=""
                    color="success"
                    onClick={() => openFromParentForPlayer(item.team_power_id, item.round)}
                  >
                    Points
                  </CButton>
                  &nbsp;
                  {item?.status === 1 && (
                    <CButton
                      className=""
                      color="warning"
                      onClick={() => showCofirmBox(item.team_power_id)}
                    >
                      Revert
                    </CButton>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <BonusCardModal
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal}
        roundNumber={roundNumber}
        bonusCardDetail={bonusCardDetail}
        selectedPlayer={selectedPlayer}
        options={options}
        fetchData={props.fetchData}
      />
      <BonusCardPlayer
        IsModalOpened={playerModalOpen}
        onCloseModal={handlePlayerCloseModal}
        roundNumber={roundNumber}
        playerData={playerData}
        fetchData={props.fetchData}
        teamStatus={teamStatus}
      />

      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to revert this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default BonusTable
