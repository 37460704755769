import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

const ShowEmailModal = (props) => {
  console.log('emailMessage', props.emailMessage)
  return (
    <CModal
      alignment="center"
      scrollable
      size="lg"
      visible={props.infoPageModal}
      onClose={() => props.setInfoPageModal(false)}
    >
      <CModalHeader>
        <CModalTitle>Email Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <table className="main-table table table-bordered">
          <tr>
            <th>Email To: </th>
            <th>{props.emailMessage.email_to}</th>
          </tr>
          <tr>
            <th>Email From: </th>
            <th>{props.emailMessage.email_from}</th>
          </tr>
          <tr>
            <th>Email Subject: </th>
            <th>{props.emailMessage.subject}</th>
          </tr>
          <tr>
            <th>Message </th>
            <th> {ReactHtmlParser(props.emailMessage.message)}</th>
          </tr>
        </table>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={() => props.setInfoPageModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default ShowEmailModal
