import {
  cilApps,
  cilAppsSettings,
  cilBell,
  cilCopy,
  cilExternalLink,
  cilInfo,
  cilMoon,
  cilNotes,
  cilPlus,
  cilSettings,
  cilUser,
  cilVideo,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CNav,
  CNavLink,
  CRow,
  CWidgetStatsF,
  CAvatar,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CBadge,
  CSmartTable,
  CContainer,
  CHeaderNav,
  CButtonGroup,
  CHeaderDivider,
  CHeader,
  CHeaderBrand,
  CCardTitle,
  CCardText,
} from '@coreui/react-pro'
import logo from '../../assets/brand/logo.jpg'
import { cilPeople } from '@coreui/icons'
import React, { useEffect, useRef, useState } from 'react'
import CommonService from 'src/service/CommonService'
import GameStatusModal from './GameStatusModal'
import TransferAdminModal from './TransferAdminModal'
import ToastComponent from 'src/components/common/TaostComponent'
import { Link, useNavigate } from 'react-router-dom'
import { AppHeaderDropdown, AppHeaderDropdownNotif } from 'src/components/header'
import GameLinkBar from 'src/components/header/GameLinkBar'
import PageInfoModal from 'src/components/PageInfoModal'
import { AppFooter } from 'src/components'
import AdminUserList from './AdminUserList'
const GameAccount = (props) => {
  const [visible, setVisible] = useState(false)
  const [defaultActive, setDefaultActive] = useState(true)
  const [runningActive, setRunningActive] = useState(false)
  const [completedActive, setCompletedActive] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState()
  const [dashboardData, setDashboardData] = useState()
  const adminDetails = JSON.parse(localStorage.getItem('admin'))
  const columns = [
    {
      label: <CIcon icon={cilPeople} />,
      key: 'logo',
      filter: false,
      sorter: false,
    },
    { label: 'Game', key: 'game_name', sorter: true },
    { label: 'Setup', key: 'admin', filter: false, sorter: false },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 2:
        return 'warning'
      case 3:
        return 'danger'
      default:
        return 'primary'
    }
  }

  const handleDefaultActive = () => {
    // getUsers()
    setDefaultActive(true)
    setCompletedActive(false)
    setRunningActive(false)
  }
  const handleCompleteActive = () => {
    // getUsers()
    setCompletedActive(true)
    setRunningActive(false)
    setDefaultActive(false)
  }

  const handleRunningActive = () => {
    // getUsers()
    setRunningActive(true)
    setCompletedActive(false)
    setDefaultActive(false)
  }
  const [loading, setLoading] = useState()
  const [gameStatus, setGameStatus] = useState()
  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)
    const gameType = {}
    gameType.running = runningActive
    gameType.completed = completedActive
    CommonService.gameAccount(
      offset,
      itemsPerPage,
      activePage,
      params,
      runningActive,
      completedActive,
    ).then((result) => {
      setUsers(result.data)
      setDashboardData(result.new_data)
      setLoading(false)
    })
  }, [activePage, columnFilter, columnSorter, itemsPerPage, runningActive, completedActive])

  const [userId, setUserId] = useState()
  const handleGameStatus = (status, userId) => {
    setVisible(true)
    setGameStatus(status)
    setUserId(userId)
  }

  const [transferVisible, setTransferVisible] = useState(false)
  const handleTransferAdmin = (userId) => {
    setTransferVisible(true)
    setUserId(userId)
  }

  const navigate = useNavigate()
  const gameLogin = (userId, gameId) => {
    const data = {}
    data.userId = userId
    data.gameId = gameId
    localStorage.setItem('gameId', gameId)
    CommonService.gameLogin(data).then((res) => {
      if (res.status === 200) {
        localStorage.setItem('admin', JSON.stringify(res.user))
        localStorage.setItem('token', JSON.stringify(res.token))
        navigate('/dashboard')
      }
    })
  }
  const getStarted = (userId, gameId) => {
    const data = {}
    data.userId = userId
    data.gameId = gameId
    localStorage.setItem('gameId', gameId)
    CommonService.gameLogin(data).then((res) => {
      if (res.status === 200) {
        localStorage.setItem('admin', JSON.stringify(res.user))
        localStorage.setItem('token', JSON.stringify(res.token))
        navigate('/get-started')
      }
    })
  }

  const enterGameAdmin = (userId, gameId, url) => {
    const data = {}
    data.userId = userId
    data.gameId = gameId
    localStorage.setItem('gameId', gameId)
    CommonService.gameLogin(data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('admin', JSON.stringify(res.user))
          localStorage.setItem('token', JSON.stringify(res.token))
          navigate(url)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
      })
  }

  const redirectToURL = () => {
    navigate('/season-register')
  }

  const setGameId = (gameId) => {
    localStorage.setItem('gameId', gameId)
    navigate('/activate-game')
  }

  const [infoPageModal, setInfoPageModal] = useState(false)
  const [infoText, setInfoText] = useState('')
  const pathName = window.location.pathname
  const handleInfoClick = () => {
    setInfoPageModal(true)
    CommonService.getHeaderInfoDetails(pathName).then((res) => {
      if (res.status === 200) {
        setInfoText(res.data.info_text)
      }
    })
  }
  const [textToCopy, setTextToCopy] = useState(
    `${process.env.REACT_APP_FRONT_URL}account-hub?account_name=${adminDetails?.username}`,
  ) // Initialize with the text you want to copy

  // Function to handle copying text to the clipboard
  const copyToClipboard = () => {
    // Create a temporary input element
    const tempInput = document.createElement('textarea')
    tempInput.value = textToCopy
    document.body.appendChild(tempInput)

    // Select the text in the input element
    tempInput.select()
    tempInput.setSelectionRange(0, 99999) // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy')

    ToastComponent('Copied to clipboard', 'success')
    // Remove the temporary input element
    document.body.removeChild(tempInput)
  }

  return (
    <>
      {adminDetails?.user_role_id !== 1 && (
        <>
          <CRow className="justify-content-center" style={{ maxHeight: '700px;' }}>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                icon={<CIcon width={24} icon={cilSettings} size="xl" />}
                title="All Games"
                value={dashboardData?.all_games}
                color="primary-gradient"
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                icon={<CIcon width={24} icon={cilUser} size="xl" />}
                title="Completed Games"
                value={dashboardData?.complete_games}
                color="info-gradient"
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                icon={<CIcon width={24} icon={cilMoon} size="xl" />}
                title="All Players"
                value={dashboardData?.all_players}
                color="warning-gradient"
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                icon={<CIcon width={24} icon={cilBell} size="xl" />}
                title="All Members"
                value={dashboardData?.all_members}
                color="danger-gradient"
              />
            </CCol>

            <CCol xs={12} sm={12} lg={12}>
              <CCard className="mb-3">
                <CCardHeader>
                  <strong>Games</strong>
                  {/* &nbsp;
                  <CButton
                    color={'success'}
                    active={false}
                    className="float-end ml-2"
                    onClick={clubRegister}
                  >
                    <CIcon icon={cilPlus} className="me-2" />
                    New
                  </CButton>{' '}
                  &nbsp;
                  <CButton color={'primary'} active={true} className="float-end text-center">
                    <CIcon icon={cilInfo} className="me-2" />
                  </CButton> */}
                  <CHeaderNav className="float-end">
                    <CButtonGroup aria-label="Theme switch">
                      {/*  'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string */}

                      {/* <CButton color={'danger'} onClick={handleInfoClick}>
                            <CIcon icon={cilSettings} />
                          </CButton> */}
                      <CButton color={'success'} onClick={redirectToURL} title="Start New Game">
                        <CIcon icon={cilPlus} />
                      </CButton>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle color={'danger'} title="Tools">
                          <CIcon icon={cilSettings} />
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem href="/duplicate-game">Duplicate Game</CDropdownItem>
                          <CDropdownItem href="/copy-players">Copy Players</CDropdownItem>
                          <CDropdownItem href="/copy-branding">Copy Branding</CDropdownItem>
                          <CDropdownItem href="/copy-sponsors">Copy Sponsor</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </CButtonGroup>
                  </CHeaderNav>
                </CCardHeader>
                <CCardBody>
                  <CNav component="nav" variant="pills" className="flex-column flex-sm-row">
                    <CNavLink href="#" active={defaultActive} onClick={handleDefaultActive}>
                      All
                    </CNavLink>
                    <CNavLink href="#" active={runningActive} onClick={handleRunningActive}>
                      Active
                    </CNavLink>
                    <CNavLink href="#" active={completedActive} onClick={handleCompleteActive}>
                      Completed
                    </CNavLink>
                  </CNav>

                  <CSmartTable
                    className={'mb-0 border mt-3'}
                    columns={columns}
                    columnFilter={{
                      external: true,
                    }}
                    columnSorter={{
                      external: true,
                    }}
                    scopedColumns={{
                      logo: (item) => (
                        <td>
                          <CAvatar
                            size="md"
                            src={`${process.env.REACT_APP_API_URL}uploads/game_logo/${item.logo}`}
                          />
                        </td>
                      ),
                      game_name: (item) => (
                        <td>
                          <div>
                            <Link onClick={() => gameLogin(item.club_id, item.id)}>
                              {item.game_name}
                            </Link>{' '}
                          </div>
                          <div className="small text-medium-emphasis text-nowrap">
                            <span>
                              <CBadge color={getBadge(item.status)}>
                                {item.status === 1 ? 'In-Progress' : 'Completed'}
                              </CBadge>
                              &nbsp;
                              <CBadge color={getBadge(item.status)}>
                                {item.game_type === 3 ? 'Indoor' : 'Outdoor'}
                              </CBadge>
                            </span>{' '}
                          </div>
                        </td>
                      ),
                      admin: (item) => (
                        <td>
                          <CButton
                            color={'warning'}
                            onClick={() => getStarted(item.club_id, item.id)}
                            title="Get Started"
                          >
                            <CIcon icon={cilInfo} />
                          </CButton>
                        </td>
                      ),
                      show_details: (item) => {
                        return (
                          <>
                            <td style={{ position: 'absolute', zIndex: 1000 }}>
                              <CDropdown>
                                <CButton color={'success'}>
                                  {' '}
                                  <CIcon icon={cilAppsSettings} />
                                </CButton>
                                <CDropdownToggle color={'success'} split />
                                <CDropdownMenu>
                                  <CDropdownItem href="/duplicate-game">
                                    Duplicate Game
                                  </CDropdownItem>

                                  <CDropdownItem onClick={() => setGameId(item.id)}>
                                    Game Activation
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/game-info')
                                    }
                                  >
                                    Game Info
                                  </CDropdownItem>

                                  <CDropdownItem
                                    onClick={() => enterGameAdmin(item.club_id, item.id, '/spots')}
                                  >
                                    Game Spots
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() => handleGameStatus(item.status, item.id)}
                                  >
                                    Game Status
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/game-structure')
                                    }
                                  >
                                    Game Structure
                                  </CDropdownItem>

                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/manage-scores')
                                    }
                                  >
                                    Manage Scores
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/members')
                                    }
                                  >
                                    {' '}
                                    Members List
                                  </CDropdownItem>

                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/players')
                                    }
                                  >
                                    Players List{' '}
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/point-system')
                                    }
                                  >
                                    Point System
                                  </CDropdownItem>
                                  <CDropdownItem href={'/plan'}> Pricing Plan</CDropdownItem>
                                  <CDropdownItem
                                    onClick={() => enterGameAdmin(item.club_id, item.id, '/rounds')}
                                  >
                                    Rounds{' '}
                                  </CDropdownItem>
                                  <CDropdownItem
                                    onClick={() =>
                                      enterGameAdmin(item.club_id, item.id, '/sponsors')
                                    }
                                  >
                                    Sponsors{' '}
                                  </CDropdownItem>
                                  <CDropdownDivider />
                                </CDropdownMenu>
                              </CDropdown>
                            </td>
                          </>
                        )
                      },
                    }}
                    items={users?.data}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageSelect
                    loading={loading}
                    pagination={{
                      external: true,
                    }}
                    paginationProps={{
                      activePage: activePage,
                      pages: Math.ceil(users?.total / itemsPerPage) || 1,
                    }}
                    tableProps={{
                      hover: true,
                      responsive: true,
                    }}
                    onActivePageChange={(activePage) => setActivePage(activePage)}
                    onColumnFilterChange={(filter) => {
                      setActivePage(1)
                      setColumnFilter(filter)
                    }}
                    onItemsPerPageChange={(itemsPerPage) => {
                      setActivePage(1)
                      setItemsPerPage(itemsPerPage)
                    }}
                    onSorterChange={(sorter) => setColumnSorter(sorter)}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12} sm={12} lg={12} className="mb-3">
              <CCard>
                <CCardBody>
                  <CButtonGroup role="group" aria-label="Basic mixed styles example">
                    <CDropdown variant="btn-group">
                      <CDropdownToggle color={'danger'} title="Tools">
                        <CIcon icon={cilVideo} />
                        &nbsp; Admin Help Tours
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem
                          href="https://share.layerpath.com/share/clycdymjx008kl50cck8zuqi7/tour"
                          target="_blank"
                        >
                          Introduction to Admin Hub
                        </CDropdownItem>
                        <CDropdownItem
                          href="https://share.layerpath.com/share/clyctqkmt0011ju0cn3k40pru/tour"
                          target="_blank"
                        >
                          Start a New Game
                        </CDropdownItem>
                        <CDropdownItem
                          href="https://share.layerpath.com/share/clycwpcvv0001jq0c5dl3pe6n/tour"
                          target="_blank"
                        >
                          Overview Game Admin Portal
                        </CDropdownItem>
                        <CDropdownItem
                          href="https://share.layerpath.com/playlist/clyd0i2b5000cjo0c78apwfkv"
                          target="_blank"
                        >
                          Playlist-Admin Help Tours
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </CButtonGroup>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
      {adminDetails?.user_role_id === 1 && (
        <CRow className="justify-content-center">
          <CCol xs={12} sm={12} lg={12}>
            <CCard className="mb-3">
              <CCardHeader>
                <strong>User</strong>
              </CCardHeader>
              <CCardBody>
                <AdminUserList />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}

      <GameStatusModal
        visible={visible}
        setVisible={setVisible}
        gameStatus={gameStatus}
        setGameStatus={setGameStatus}
        userId={userId}
        setUsers={setUsers}
      />
      <TransferAdminModal
        transferVisible={transferVisible}
        setTransferVisible={setTransferVisible}
        userId={userId}
      />
      <PageInfoModal
        infoPageModal={infoPageModal}
        setInfoPageModal={setInfoPageModal}
        infoText={infoText}
      />
    </>
  )
}

export default GameAccount
