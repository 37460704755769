import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import React from 'react'

const LegendInfo = (props) => {
  return (
    <CModal
      alignment="center"
      scrollable
      visible={props.infoPageModal}
      onClose={() => props.setInfoPageModal(false)}
    >
      <CModalHeader>
        <CModalTitle>Information</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>
          <strong>PT</strong>: Points{' '}
        </p>
        <p>
          <strong>ST</strong>: Steals
        </p>
        <p>
          <strong>REB</strong>: Rebounds
        </p>
        <p>
          <strong>AST</strong>: Assists
        </p>
        <p>
          <strong>BKS</strong>: Blocks
        </p>
        <p>
          <strong>TOV</strong>: Turnover
        </p>
        <p>
          <strong>3P</strong>: 3-Pointers
        </p>
        <p>
          <strong>PFS</strong>: Personal Fouls
        </p>

        <p>
          <strong>FP</strong>: Fantasy Points
        </p>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={() => props.setInfoPageModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default LegendInfo
