import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  CFormLabel,
  CForm,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CFormInput,
  CFormCheck,
  CFormSelect,
  CLoadingButton,
  CFormFeedback,
  CFormSwitch,
} from '@coreui/react-pro'
import { Col, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PaymentModal from '../ActivateGame/PaymentModal'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import GamePassFeeInfo from './GamePassFeeInfo'
import ClubService from 'src/service/ClubService'
import GamePassMCTMember from './GamePassMCTMember'
import GamePassMember from './GamePassMember'

const GamePass = () => {
  const [loading, setLoading] = useState(false)
  const [visible1, setVisible1] = useState('d-none')
  const [visible2, setVisible2] = useState('d-none')
  const [isChecked, setIsChecked] = useState(true)
  const [isPayRequire, setIsPayRequire] = useState(true)
  const [gameDetail, setGameDetail] = useState(true)
  const [isCollect, setIsCollect] = useState(0)
  const [isPaidRequire, setIsPaidRequire] = useState(0)
  const [entryFeeInfo, setEntryFeeInfo] = useState()
  const [selectedGameFee, setSelectedGameFee] = useState()
  const accountInfo = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    CommonService.getGameActivateInfo()
      .then((res) => {
        if (res.status === 200) {
          setGameDetail(res.game_info)
          setSelectedGameFee(res.game_info?.game_pass_fee ?? 0)
          setIsCollect(res.game_info?.collect_game_pass)
          setIsPaidRequire(res.game_info?.is_paid_require)
          setEntryFeeInfo(res.game_info?.entry_fee_info)
          if (res.game_info?.collect_game_pass === 1) {
            setVisible1()
          }
          if (res.game_info?.collect_game_pass === 2) {
            setVisible2()
          }
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
      })
  }, [])
  const handleFee = (e) => {
    const val = e.target.value
    setIsCollect(val)
    setVisible1('d-none')
    setVisible2('d-none')
    if (val === '1') {
      setVisible1('')
      setVisible2('d-none')
    }
    if (val === '2') {
      setVisible2('')
      setVisible1('d-none')
    }
  }
  const handlePaymentRequire = (e) => {
    setIsPaidRequire(e.target.value)
    if (e.target.value === '') {
      setIsChecked(true)
    } else {
      setIsPayRequire(false)
      setIsChecked(false)
    }
  }
  const handleActiveTerm = (event) => {
    if (event.target.checked) {
      setIsChecked(false)
    }
  }
  const validationSchema = Yup.object().shape({
    game_pass_fee: Yup.string().required('Game pass fee is required'),
  })
  const formik = useFormik({
    initialValues: {
      game_pass_fee: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      data.collect_game_pass = isCollect
      data.is_paid_require = isPaidRequire
      setLoading(true)
      ClubService.updateFeeInfo(data)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoading(false)
          } else {
            setLoading(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.', 'error')
          setLoading(false)
        })
    },
  })
  const handleGamefee = (e) => {
    setSelectedGameFee(e.target.value)
  }
  return (
    <>
      {' '}
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Game Pass</strong>
        </CCardHeader>
        <CCardBody>
          <CRow className="g-3">
            <CCol md={12}>
              Do you want to fundraise and collect a Game Pass (Entry Fee) from members?
            </CCol>

            <CCol md={12}>
              <CFormSelect
                aria-label="collect fee"
                name="is_collect"
                className={'form-control'}
                id="is_collect"
                value={isCollect}
                onChange={handleFee}
              >
                <option value={0}>Select your Game Pass fees collection method</option>
                {accountInfo?.country === 13 && (
                  <option value={1}>
                    Want to use {`MyClubtap's`} Game Pass service to collect within the platform.
                  </option>
                )}
                <option value={2}>Want to collect on our own outside of MyClubtap platform.</option>
              </CFormSelect>
            </CCol>
            <CCol md={12}>Is the payment of fee required before members can access the game?*</CCol>
            <CCol md={12}>
              <CFormSelect
                aria-label="collect fee"
                name="is_collect"
                className={'form-control'}
                id="is_collect"
                value={isPaidRequire}
                onChange={handlePaymentRequire}
              >
                <option value="">Select option</option>
                <option value={1}>
                  Yes. Members must pay the fee before getting access to the game.
                </option>

                <option value={0}>
                  No. Members should be able to access the game and pay the fee at any time.
                </option>
              </CFormSelect>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      {accountInfo?.country === 13 && (
        <CCard className={`mb-4 ${visible1}`}>
          <CCardHeader>
            <strong>Service 1: Set Game Pass Fees (optional)</strong>
          </CCardHeader>
          <CCardBody>
            Team entry fee collection on platform
            <ul className="mt-2">
              <li>Available for clubs/leagues in Australia only</li>
              <li>Processing Fees: 15%, Club/League: 85%</li>
              <li>Min Amount ($10) & Max Amount ($100)</li>
              <li>Fees in Australian Dollars (AUD) only</li>
            </ul>
            <CForm className="row g-3" onSubmit={formik.handleSubmit}>
              <CCol md="6">
                {' '}
                <CFormInput
                  type="number"
                  id="game_pass_fee"
                  min={10}
                  max={100}
                  name="game_pass_fee"
                  placeholder="Set Game Pass Fees*"
                  className={
                    'form-control' +
                    (formik.errors.game_pass_fee && formik.touched.game_pass_fee
                      ? ' is-invalid'
                      : '')
                  }
                  defaultValue={selectedGameFee}
                  onChange={(event) => {
                    formik.setTouched({
                      ...formik.touched,
                      game_pass_fee: true,
                    })
                    formik.setFieldValue('game_pass_fee', event.target.value)
                    handleGamefee(event)
                  }}
                />
                {formik.errors.game_pass_fee && formik.touched.game_pass_fee && (
                  <CFormFeedback invalid>{formik.errors.game_pass_fee}</CFormFeedback>
                )}
              </CCol>
              <CCol md="6"></CCol>
              <CCol md="6">
                <p className="mt-2">
                  Accept{' '}
                  <a
                    href="https://cricket.myclubtap.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of use
                  </a>{' '}
                </p>
                <div className="form-group mb-3">
                  <CFormCheck
                    inline
                    id="inlineCheckbox1"
                    value="option1"
                    label="I agree to the terms of use"
                    onChange={handleActiveTerm}
                  />
                </div>
              </CCol>
              <CCol md="6"></CCol>

              <CCol md="6">
                <CLoadingButton
                  type="submit"
                  color="success"
                  variant="outline"
                  loading={loading}
                  disabled={isChecked}
                >
                  Submit
                </CLoadingButton>
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      )}
      <CCard className={`mb-4 ${visible2}`}>
        <CCardBody>
          <GamePassFeeInfo
            gameDetail={gameDetail}
            entryFeeInfo={entryFeeInfo}
            setEntryFeeInfo={setEntryFeeInfo}
            isCollect={isCollect}
            setIsCollect={setIsCollect}
            isPaidRequire={isPaidRequire}
          />
        </CCardBody>
      </CCard>
      {isCollect == 1 && <GamePassMCTMember />}
      {isCollect == 2 && <GamePassMember />}
    </>
  )
}

export default GamePass
