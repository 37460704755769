import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CRow,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CTableDataCell,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import Loader from 'src/components/Loader'
import moment from 'moment'
import CommonService from 'src/service/CommonService'

const GameSponsorAnalytic = () => {
  const [result, setResult] = useState()
  const [loader, setLoader] = useState(true)
  const param = useParams()
  useEffect(() => {
    CommonService.getSponsorClickViews()
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          setResult(res.data)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong. Please try again.', 'error')
        setLoader(false)
      })
  }, [param.type])
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>
                {' '}
                <Link to="/sponsors">Sponsor Logs</Link>{' '}
              </strong>
            </CCardHeader>
            <CCardBody>
              {loader ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="main-table table innertable">
                    <thead>
                      <tr>
                        <th>Sponsor</th>
                        <th>Website</th>
                        <th>Facebook</th>
                        <th>Twitter</th>
                        <th>Instagram</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        result.map((item, key) => (
                          <CTableRow key={key}>
                            <CTableDataCell>{item.name}</CTableDataCell>
                            <CTableDataCell>{item.game_sponsor_website}</CTableDataCell>
                            <CTableDataCell>{item.game_sponsor_facebook}</CTableDataCell>
                            <CTableDataCell>{item.game_sponsor_twitter}</CTableDataCell>
                            <CTableDataCell>{item.game_sponsor_instagram}</CTableDataCell>
                            <CTableDataCell>{item.total}</CTableDataCell>
                          </CTableRow>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default GameSponsorAnalytic
