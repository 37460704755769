import React, { useState } from 'react'
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CListGroup,
  CListGroupItem,
  CForm,
  CFormFeedback,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import Bootbox from 'bootbox-react'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import AddRemoveInputField from './AddRemoveInputField'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SetMinPlayerValue from './SetMinPlayerValue'
import SetMaxPlayerValue from './SetMaxPlayerValue'
import Loader from 'src/components/Loader'
import DefaultSalarySymbol from './DefaultSalarySymbol'
const Index = () => {
  const [playerCustomValue, setPlayerCustomValue] = useState([])
  const [playerDefaultValue, setPlayerDefaultValue] = useState([])
  const [defaultPlayerPrice, setDefaultPlayerPrice] = useState(1)
  const [playerPriceDropDown, setPlayerPriceDropDown] = useState([])
  const [advanceMinPlayerprice, setAdvanceMinPlayerprice] = useState(0)
  const [advanceMaxPlayerprice, setAdvanceMaxPlayerprice] = useState(0)
  const [playerMinValues, setPlayerMinValues] = useState([])
  const [playerMaxValues, setPlayerMaxValues] = useState([])
  const [defaultSalarySymbol, setDefaultSalarySymbol] = useState('')

  const [key, setKey] = useState('home')
  const [loader, setLoader] = useState(false)

  const fetchData = () => {
    setLoader(true)
    PlayerService.getPlayerFantasyValue().then((result) => {
      setPlayerCustomValue(result.custom_value)
      setDefaultSalarySymbol(result.default_salary_symbol)
      setPlayerPriceDropDown(result.playerpriceList)
      setPlayerDefaultValue(result.default_value)
      setDefaultPlayerPrice(result.default_player_price)
      setAdvanceMinPlayerprice(result.advance_min_player_price)
      setAdvanceMaxPlayerprice(result.advance_max_player_price)
      setPlayerMinValues(result.playerMinValues)
      setPlayerMaxValues(result.playerMaxValues)
      setLoader(false)
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  // are you sure for delete

  const [showConfirm, setShowConfirm] = useState(false)
  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const showCofirmBox = () => {
    setShowConfirm(true)
  }
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const changeStatus = () => {
    setLoader(true)
    PlayerService.deletePlayerAdvanceValue().then((result) => {
      ToastComponent(result.message, 'success')
      fetchData()
      setLoader(false)
    })
  }
  return (
    <>
      <CRow>
        {/* <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Select Fantasy Value System</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                <CCol md={6} className="">

                  <CFormSelect
                    aria-label="Select Structure"
                    name="salary_structure"
                    className={
                      'form-control' +
                      (formik.errors.salary_structure && formik.touched.salary_structure
                        ? ' is-invalid'
                        : '')
                    }
                    value={defaultPlayerPrice}
                    onChange={handlePrice}
                    id="salary_structure"
                  >
                    <option value="1">Use Default Fantasy Values</option>
                    <option value="2">Use Custom Fantasy Values</option>
                  </CFormSelect>
                  {formik.errors.salary_structure && formik.touched.salary_structure && (
                    <CFormFeedback invalid>{formik.errors.salary_structure}</CFormFeedback>
                  )}
                  <label className="mt-2">Applied: Default</label>
                </CCol>
                <CCol md={6}></CCol>
                <CCol md={6}>
                  <CLoadingButton type="submit" color="success" variant="outline" loading={false}>
                    Submit
                  </CLoadingButton>
                </CCol>
              </CForm>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion> */}
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Set Symbol</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <div className="mb-3">
                {loader ? (
                  <Loader />
                ) : (
                  <DefaultSalarySymbol
                    defaultSalarySymbol={defaultSalarySymbol}
                    fetchData={fetchData}
                  />
                )}
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Fantasy Values</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey={1}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey={1} title={`Default`}>
                  <p className="">
                    This is the default fantasy value structure used to create Player records.
                  </p>
                  <CCard>
                    <CCardHeader>Player Default Values</CCardHeader>
                    <CListGroup>
                      {playerDefaultValue &&
                        playerDefaultValue.map((item, key) => (
                          <CListGroupItem key={key}>{item.name}</CListGroupItem>
                        ))}
                    </CListGroup>
                  </CCard>
                </Tab>
                {/* <Tab eventKey={2} title="Custom">
                  <p className="text-medium-emphasis small">
                    Fill below form for custom player Value
                  </p>
                  <br></br>
                  <AddRemoveInputField
                    playerCustomValue={playerCustomValue}
                    setPlayerCustomValue={setPlayerCustomValue}
                    playerPriceDropDown={playerPriceDropDown}
                    setPlayerPriceDropDown={setPlayerPriceDropDown}
                  />
                </Tab> */}
                <Tab eventKey={2} title="Advanced">
                  {/* <p className="text-medium-emphasis small">Advanced Fantasy Values</p> */}
                  <CRow>
                    <CCol md={6} xs={12}>
                      <CCard>
                        <CCardHeader>Advanced Fantasy Values (Minimum)</CCardHeader>
                        {loader ? (
                          <Loader />
                        ) : (
                          <CListGroup className="custom-list-group">
                            {playerMinValues &&
                              playerMinValues.map((item, key) => (
                                <CListGroupItem key={key}>{item.price_name}</CListGroupItem>
                              ))}
                          </CListGroup>
                        )}
                      </CCard>
                    </CCol>
                    <CCol md={6} xs={12}>
                      <CCard>
                        <CCardHeader>Advanced Fantasy Values (Maximum)</CCardHeader>
                        {loader ? (
                          <Loader />
                        ) : (
                          <CListGroup className="custom-list-group">
                            {playerMaxValues &&
                              playerMaxValues.map((item, key) => (
                                <CListGroupItem key={key}>{item.price_name}</CListGroupItem>
                              ))}
                          </CListGroup>
                        )}
                      </CCard>
                    </CCol>
                  </CRow>
                </Tab>
              </Tabs>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={2} alwaysOpen>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Advanced Settings</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow className="mb-10">
                <CCol md={6} xs={12}>
                  {' '}
                  {loader ? (
                    <Loader />
                  ) : (
                    <SetMinPlayerValue
                      advanceMinPlayerprice={advanceMinPlayerprice}
                      fetchData={fetchData}
                    />
                  )}
                </CCol>
                <CCol md={6}>
                  {loader ? (
                    <Loader />
                  ) : (
                    <SetMaxPlayerValue
                      advanceMaxPlayerprice={advanceMaxPlayerprice}
                      fetchData={fetchData}
                    />
                  )}
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>

        <CAccordion activeItemKey={3} alwaysOpen>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>
              {' '}
              <strong>Delete Advanced Fantasy Values</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CLoadingButton
                type="button"
                color="success"
                variant="outline"
                loading={loader}
                onClick={showCofirmBox}
              >
                Delete
              </CLoadingButton>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>

      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default Index
