import {
  CBadge,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'src/components/Loader'
import ToastComponent from 'src/components/common/TaostComponent'
import HeadToHeadService from 'src/service/HeadToHeadService'
import Bootbox from 'bootbox-react'
import moment from 'moment'

const H2HAllBattle = (props) => {
  const [loader, setLoader] = useState(false)
  const handleRevert = (roundId) => {
    const data = {}
    data.round_id = roundId
    HeadToHeadService.revertHeadToHead(data).then((result) => {
      ToastComponent(result.message, 'success')
      props.fetchData()
    })
  }

  const getBadge = (status) => {
    switch (status) {
      case true:
        return 'success'
      default:
        return 'danger'
    }
  }
  const [showConfirm, setShowConfirm] = useState(false)
  const [roundId, setRoundId] = useState(false)
  const [type, setType] = useState('')
  const showCofirmBox = (roundId = 0, type = 0) => {
    setRoundId(roundId)
    setShowConfirm(true)
  }

  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const changeStatus = (type) => {
    setLoader(true)
    const data = {}
    data.roundId = roundId
    data.type = 0
    HeadToHeadService.markAsCompleteHeadToHead(data).then((result) => {
      setLoader(false)
      ToastComponent(result.message, 'success')
      props.fetchData()
    })
  }

  // Delete functionality is here
  // Show head to head delete box
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const showDeleteCofirmBox = (roundId = 0, type = 0) => {
    setRoundId(roundId)
    setType(type)
    setShowDeleteConfirm(true)
  }
  const handleDeleteConfirm = () => {
    changeDeleteStatus()
    return setShowDeleteConfirm(false)
  }
  const handleDeleteCancel = () => {
    return setShowDeleteConfirm(false)
  }

  const changeDeleteStatus = () => {
    setLoader(true)
    const data = {}
    data.roundId = roundId
    data.type = type
    HeadToHeadService.deleteHeadToHead(data).then((result) => {
      setLoader(false)
      ToastComponent(result.message, 'success')
      props.fetchData()
    })
  }
  // Delete functionality is here

  return loader ? (
    <Loader />
  ) : (
    <>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Head-2-Head</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <table className="main-table table innertable">
              <thead>
                <tr>
                  <th>Rounds </th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.roundListing &&
                  props.roundListing.map((item, key) => (
                    <tr key={key}>
                      <th>{item.round}</th>
                      <th>
                        {' '}
                        {moment(item.start_date).format('MMM D')}-
                        {moment(item.end_date).format('MMM D')}
                      </th>
                      <td>
                        <CBadge color={getBadge(item.status)}>
                          {item.status ? 'Completed' : 'Not started Yet'}
                        </CBadge>
                      </td>
                      <td>
                        {!item.status && (
                          <Link
                            size="sm"
                            className="btn btn-success btn-sm ms-1"
                            to={`/h2h-actions/${item.id}`}
                          >
                            Generate H2H
                          </Link>
                        )}
                        {item.status && (
                          <>
                            <Link
                              size="sm"
                              className="btn btn-success btn-sm ms-1"
                              to={`/h2h-actions/${item.id}`}
                            >
                              View H2H
                            </Link>
                            <Link
                              size="sm"
                              className="btn btn-warning btn-sm ms-1"
                              onClick={() => handleRevert(item.id)}
                            >
                              Revert H2H
                            </Link>
                          </>
                        )}

                        {item.is_generated && (
                          <Link
                            size="sm"
                            className="btn btn-info btn-sm ms-1"
                            onClick={() => showCofirmBox(item.id)}
                          >
                            Mark as Complete
                          </Link>
                        )}

                        {(item.status || item.is_generated) && (
                          <Link
                            size="sm"
                            className="btn btn-info btn-sm ms-1"
                            onClick={() => showDeleteCofirmBox(item.id, 0)}
                          >
                            Delete
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                {props.roundListing.length === 0 && (
                  <tr>
                    <td colSpan={4}>No record yet available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to mark as complete this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
      <Bootbox
        show={showDeleteConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete this?'}
        onSuccess={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default H2HAllBattle
