import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CSmartTable } from '@coreui/react-pro'

import HeadToHeadService from 'src/service/HeadToHeadService'
import GroupTwoLeadboardListing from './GroupTwoLeadboardListing'
const GroupTypeHeadToHeadLeaderboard = (props) => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(200)
  const [users, setUsers] = useState([])

  const columns = [
    {
      label: 'Manager Name',
      key: 'full_name',
    },
    {
      key: 'played',
      filter: false,
    },
    {
      key: 'wins',
      filter: false,
    },
    {
      key: 'losses',
      filter: false,
    },
    {
      key: 'ties',
      filter: false,
    },
    {
      label: 'Win%',
      key: 'win_percentage',
      filter: false,
    },
    {
      key: 'points_for',
      filter: false,
    },
    {
      key: 'points_against',
      filter: false,
    },
    {
      key: 'point_difference',
      filter: false,
    },
    {
      key: 'points',
      filter: false,
    },
  ]

  useEffect(() => {
    const fetchLeaderboard = async () => {
      setLoading(true)
      try {
        const offset = itemsPerPage * activePage - itemsPerPage
        const params = new URLSearchParams(columnFilter)
        if (columnSorter?.column !== undefined) {
          params.append('sort', `${columnSorter.column}%${columnSorter.state}`)
        }

        const result = await HeadToHeadService.headToHeadGroup1Leaderboard(
          offset,
          itemsPerPage,
          activePage,
          params,
        )

        setUsers(result.data)
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error('Error fetching leaderboard:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchLeaderboard()
  }, [activePage, columnFilter, columnSorter, itemsPerPage])
  return (
    <CRow>
      <CCol xs={12} md={6}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Group One</strong>
          </CCardHeader>
          <CCardBody>
            <CSmartTable
              columns={columns}
              columnFilter={{
                external: true,
              }}
              columnSorter={{
                external: true,
              }}
              items={users?.data}
              itemsPerPage={itemsPerPage}
              itemsPerPageSelect
              loading={loading}
              pagination={{
                external: true,
              }}
              paginationProps={{
                activePage: activePage,
                pages: Math.ceil(users?.total / itemsPerPage) || 1,
              }}
              tableProps={{
                hover: true,
                responsive: true,
              }}
              onActivePageChange={(activePage) => setActivePage(activePage)}
              onColumnFilterChange={(filter) => {
                setActivePage(1)
                setColumnFilter(filter)
              }}
              onItemsPerPageChange={(itemsPerPage) => {
                setActivePage(1)
                setItemsPerPage(itemsPerPage)
              }}
              onSorterChange={(sorter) => setColumnSorter(sorter)}
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs={12} md={6}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Group Two</strong>
          </CCardHeader>
          <CCardBody>
            <GroupTwoLeadboardListing />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default GroupTypeHeadToHeadLeaderboard
