import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from './PaymentForm'
const stripePromise = loadStripe('pk_live_I2ZzJhqZwGTxEprRPJwLt4TE')
const Stripe = (props) => {
  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentForm
          setVisible={props.setVisible}
          activationInfo={props.activationInfo}
          setActivationInfo={props.setActivationInfo}
          amount={props.amount}
          type={props.type}
          noOfFixture={props.noOfFixture}
        />
      </Elements>
    </>
  )
}

export default Stripe
