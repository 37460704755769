import React, { useState } from 'react'
import Bootbox from 'bootbox-react'
import {
  CButton,
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
  CLoadingButton,
  CModalHeader,
  CRow,
  CCol,
} from '@coreui/react-pro'
import moment from 'moment'
import CommonService from 'src/service/CommonService'
import ToastComponent from './common/TaostComponent'

const ClaimPlayerProfileModal = (props) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const [status, setStatus] = useState(false)

  const showCofirmBox = (recordId, status) => {
    setRecordId(recordId)
    setStatus(status)
    setShowConfirm(true)
  }

  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }
  const handleRequestReject = (recordId, status) => {
    setRecordId(recordId)
    setStatus(status)
    changeStatus()
    props.fetchAppHeaderDetails()
  }
  const changeStatus = () => {
    props.setShowClaimProfile(false)
    const data = {}
    data.recordId = recordId
    data.status = status
    CommonService.verifyPlayerRequest(data).then((res) => {
      if (res.success) {
        props.fetchAppHeaderDetails()
        props.setShowClaimProfile(false)
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <>
      <CModal visible={props.showClaimProfile} size="lg">
        <CModalHeader>
          {' '}
          <CModalTitle>Claim Player Profiles </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCard className="mb-4">
            <CCardBody className="row g-3">
              <table className="main-table table innertable">
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Position</th>
                    <th>Value</th>
                    <th>Date</th>
                    <th>Member</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {props.verifyPlayerList &&
                    props.verifyPlayerList.map((item, key) => (
                      <tr key={key}>
                        <th>{item.player_name}</th>
                        <th>{item.position_name}</th>
                        <td>{item?.svalue}</td>
                        <td>{moment(item.created_at).format('D.MM.YYYY')}</td>
                        <th>{item.full_name}</th>
                        <th>
                          <CButton
                            color={'success'}
                            size="sm"
                            onClick={() => showCofirmBox(item?.id, 1)}
                          >
                            Approve
                          </CButton>
                          &nbsp;
                          <CButton
                            color={'danger'}
                            size="sm"
                            onClick={() => handleRequestReject(item?.id, 2)}
                          >
                            Reject
                          </CButton>
                        </th>
                      </tr>
                    ))}
                  {props.verifyPlayerList.length === 0 && (
                    <tr>
                      <td colSpan={4}>No record yet available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CModalBody>

        <CModalFooter>
          <CButton color="primary" onClick={() => props.setShowClaimProfile(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to approve this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default ClaimPlayerProfileModal
