import React, { useState, useEffect } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import CommonService from 'src/service/CommonService'
import MemberCodeListing from './MemberCodeListing'
import AddMemberCode from './AddMemberCode'
import ImportMemberJoinCode from './ImportMemberJoinCode'
const MemberCode = () => {
  const fetchData = () => {
    CommonService.getJoinCodeMember()
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          setUsers(res.data)
        }
      })
      .catch((e) => {
        console.log('Error =>', e)
        ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Get player listing
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [users, setUsers] = useState({})
  const columns = [
    { label: 'Code', filter: true, sorter: true, key: 'invite_code' },
    { label: 'First Name', filter: true, sorter: true, key: 'first_name' },
    { label: 'Last Name', filter: false, sorter: true, key: 'last_name' },
    { label: 'Email', filter: false, sorter: true, key: 'email' },
    { label: 'Created On', filter: false, sorter: true, key: 'created_at' },
    { label: 'Sent', filter: false, sorter: true, key: 'is_sent' },
    {
      key: 'actions',
      label: 'Code',
      filter: false,
      sorter: false,
    },
  ]

  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Import Game Guests</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <ImportMemberJoinCode fetchData={fetchData} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Game Guests</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddMemberCode
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
              itemsPerPage={itemsPerPage}
              fetchData={fetchData}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Game Guests List</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <MemberCodeListing
              users={users}
              columns={columns}
              itemsPerPage={itemsPerPage}
              loading={loading}
              activePage={activePage}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setColumnSorter={setColumnSorter}
              setColumnFilter={setColumnFilter}
              fetchData={fetchData}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default MemberCode
