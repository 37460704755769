import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
const AddMemberCode = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email field is required.'),
  })
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      actions.resetForm()
      setLoader(true)
      CommonService.saveJoinCodeMember(data)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData()
            ToastComponent(res.message, 'success')
            setLoader(false)
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.', 'error')
          setLoader(false)
        })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="Facebook">
            First Name
          </CFormLabel>
          <CFormInput
            placeholder="First Name"
            className={
              'form-control' +
              (formik.errors.first_name && formik.touched.first_name ? ' is-invalid' : '')
            }
            value={formik.values.first_name}
            onChange={formik.handleChange}
            aria-label="first_name"
            id="first_name"
          />
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="Facebook">
            Last Name
          </CFormLabel>
          <CFormInput
            placeholder="Last Name"
            className={
              'form-control' +
              (formik.errors.last_name && formik.touched.last_name ? ' is-invalid' : '')
            }
            value={formik.values.last_name}
            onChange={formik.handleChange}
            aria-label="last_name"
            id="last_name"
          />
        </CCol>
        <CCol md={4}>
          <CFormLabel className="fw-bold" htmlFor="Facebook">
            Email*
          </CFormLabel>
          <CFormInput
            placeholder="Email"
            className={
              'form-control' + (formik.errors.email && formik.touched.email ? ' is-invalid' : '')
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            aria-label="email"
            id="email"
          />
          {formik.errors.email && formik.touched.email && (
            <CFormFeedback invalid>{formik.errors.email}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddMemberCode
