import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CCol,
  CForm,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
const AddVerifyPlayer = (props) => {
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    user: Yup.string().required('User'),
    player: Yup.string().required('Player'),
  })
  const formik = useFormik({
    initialValues: {
      user: '',
      player: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      actions.resetForm()
      setLoader(true)
      PlayerService.saveVerifyUser(data)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData()
            props.fetchPlayerData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            setLoader(false)
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong.Please try again.', 'error')
          setLoader(false)
        })
    },
  })
  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="user">
            Select Member *
          </CFormLabel>
          <CFormSelect
            aria-label="Select Member"
            name="user"
            className={
              'form-control' + (formik.errors.user && formik.touched.user ? ' is-invalid' : '')
            }
            value={formik.values.user}
            onChange={formik.handleChange}
            id="user"
          >
            <option value={0}>Select Member</option>
            {props.userList &&
              props.userList.map((item, key) => (
                <option value={item?.user_id} key={key}>
                  {item?.full_name} ({item?.email})
                </option>
              ))}
          </CFormSelect>
          {formik.errors.user && formik.touched.user && (
            <CFormFeedback invalid>{formik.errors.user}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="player">
            Select Player*
          </CFormLabel>
          <CFormSelect
            aria-label="Select Player"
            name="player"
            className={
              'form-control' + (formik.errors.player && formik.touched.player ? ' is-invalid' : '')
            }
            value={formik.values.player}
            onChange={formik.handleChange}
            id="player"
          >
            <option value={0}>Select Player</option>
            {props.playerList &&
              props.playerList.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.full_name}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.player && formik.touched.player && (
            <CFormFeedback invalid>{formik.errors.player}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddVerifyPlayer
