import React from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CCard,
  CCardHeader,
  CCardBody,
} from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'

const PreviewEmailTemplete = (props) => {
  return (
    <>
      <CModal visible={props.IsModalOpened} size="xl">
        <CModalBody>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Preview Email Templete</strong>
            </CCardHeader>
            <CCardBody className="row g-3">
              {ReactHtmlParser(props.templeteDetails?.body)}
            </CCardBody>
          </CCard>
        </CModalBody>

        <CModalFooter>
          <CButton color="primary" onClick={(e) => props.onCloseModal()}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default PreviewEmailTemplete
