import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import UserService from 'src/service/UserService'
const MemebershipMember = () => {
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState([])

  const columns = [
    {
      label: 'Name',
      key: 'full_name',
      filter: true,
    },
    {
      label: 'Email',
      key: 'email',
      filter: true,
    },
    { label: 'Start Date', filter: false, key: 'created_at' },
    { label: 'End Date', filter: false, sorter: false, key: 'end_date' },
  ]

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    UserService.membershipMemeber(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }

  const changeMembership = (userId, status) => {
    UserService.changeMembership(userId, status).then((res) => {
      fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
      ToastComponent(res.message, 'success')
      setLoading(false)
    })
  }
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Pro-Members</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  full_name: (item) => {
                    return <td>{item.full_name}</td>
                  },
                  created_at: (item) => {
                    return <td>{moment(item.created_at).format('D.MM.YYYY')}</td>
                  },
                  end_date: (item) => {
                    return <td>{moment(item.end_date).format('D.MM.YYYY')}</td>
                  },
                  action: (item) => {
                    return (
                      <>
                        <td>
                          {item?.is_paid ? (
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                changeMembership(item.user_id, 0)
                              }}
                            >
                              Unpaid
                            </CButton>
                          ) : (
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                changeMembership(item.user_id, 1)
                              }}
                            >
                              Paid
                            </CButton>
                          )}
                        </td>
                      </>
                    )
                  },
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default MemebershipMember
