import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCol,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import AnalyticService from 'src/service/AnalyticService'
const PlayerAnalytic = () => {
  const [loading, setLoading] = useState()

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState([])
  const columns = [
    {
      key: 'player_name',
      label: 'Player',
    },
    { key: 'total_players', label: 'Profile Views' },
  ]
  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    console.log(offset)
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    AnalyticService.getPlayerAnalyticData(offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }, [activePage, columnFilter, columnSorter, itemsPerPage])
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Player Analytics</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol xs={12}>
                <CSmartTable
                  columns={columns}
                  columnFilter={{
                    external: true,
                  }}
                  columnSorter={{
                    external: true,
                  }}
                  items={users?.data}
                  itemsPerPage={itemsPerPage}
                  itemsPerPageSelect
                  loading={loading}
                  pagination={{
                    external: true,
                  }}
                  paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(users?.total / itemsPerPage) || 1,
                  }}
                  tableProps={{
                    hover: true,
                    responsive: true,
                  }}
                  onActivePageChange={(activePage) => setActivePage(activePage)}
                  onColumnFilterChange={(filter) => {
                    setActivePage(1)
                    setColumnFilter(filter)
                  }}
                  onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                  }}
                  onSorterChange={(sorter) => setColumnSorter(sorter)}
                />
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default PlayerAnalytic
