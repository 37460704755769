import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getAbout = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/about/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const saveAbout = async (data) => {
  const response = await agent.post(API_URL + 'about/save-about', data)
  return response.data
}
const editAbout = async (data) => {
  const response = await agent.post(API_URL + 'about/edit-about', data)
  return response.data
}
const getAboutDetail = async (id) => {
  const response = await agent.get(`${API_URL}about/about-details/${id}`)
  return response.data
}

const deleteAbout = async (data) => {
  const response = await agent.post(API_URL + 'about/delete-about', data)
  return response.data
}

const AboutService = {
  getAbout,
  saveAbout,
  deleteAbout,
  getAboutDetail,
  editAbout,
}

export default AboutService
