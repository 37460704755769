import React from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro'
import ReactHtmlParser from 'react-html-parser'

const HeaderInfoContentModal = (props) => {
  return (
    <>
      <CModal visible={props.modalIsOpen} size="lg">
        <CModalHeader>
          {' '}
          <strong> {props.pageName}</strong>
        </CModalHeader>
        <CModalBody>{ReactHtmlParser(props.modalContent)}</CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={(e) => props.setModalIsOpen(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default HeaderInfoContentModal
