import React, { useEffect, useState } from 'react'
import { CBadge, CButton } from '@coreui/react-pro'
import CommonService from 'src/service/CommonService'
import Loader from 'src/components/Loader'
import TeamOfTheWeekPlayer from './TeamOfTheWeekPlayer'
import TeamOfTheWeekPlayerModal from './TeamOfTheWeekPlayerModal'
import ToastComponent from 'src/components/common/TaostComponent'
import moment from 'moment'
import Bootbox from 'bootbox-react'
const TeamOfTheWeekTable = (props) => {
  const [roundNumber, setRoundNumber] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false)
  const [playerModalOpen, setPlayerModalOpen] = useState(false)
  const handleCloseModal = (data) => {
    setIsOpen(false)
  }

  const handlePlayerCloseModal = (data) => {
    setPlayerModalOpen(false)
  }

  const [playerData, setPlayerData] = useState([])
  const [teamStatus, setTeamStatus] = useState(0)
  function openModalForPoint(round) {
    setPlayerData([])
    CommonService.totwPlayerList(round).then((result) => {
      setPlayerData(result.data)
      setTeamStatus(result.teamStatus)
    })
    setPlayerModalOpen(true)
    setRoundNumber(round)
  }

  const [options, setOptions] = useState([])
  function openModalForSetUp(roundId) {
    CommonService.totwSelectedPlayer(roundId).then((result) => {
      setOptions(result.data)
    })
    setIsOpen(true)
    setRoundNumber(roundId)
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const showCofirmBox = (recordId) => {
    setRecordId(recordId)
    setShowConfirm(true)
  }
  const handleConfirm = () => {
    changeStatus()
    return setShowConfirm(false)
  }
  const handleCancel = () => {
    console.log('You clicked No!')
    return setShowConfirm(false)
  }

  const changeStatus = () => {
    setShowConfirm(false)
    const data = {}
    data.totwId = recordId
    CommonService.revertTotw(data).then((res) => {
      if (res.success) {
        ToastComponent(res.message, 'success')
        props.fetchData()
      }
    })
  }
  return props.loading ? (
    <Loader />
  ) : (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th>Round</th>
            <th>Duration</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.users &&
            props.users.map((item, key) => (
              <tr key={key}>
                <th>{item.round}</th>
                <th>
                  {' '}
                  {moment(item.start_date).format('MMM D')}-{moment(item.end_date).format('MMM D')}
                </th>
                <td>
                  {item?.status === 1 && <CBadge color="success">Active</CBadge>}
                  {(item?.status === 0 || !item?.status) && (
                    <CBadge color="danger">In-Active</CBadge>
                  )}
                </td>
                <td>
                  <CButton onClick={() => openModalForSetUp(item.round)} color={'success'}>
                    Set Up
                  </CButton>
                  <CButton
                    className="mx-2"
                    color="success"
                    onClick={() => openModalForPoint(item.round)}
                  >
                    Points
                  </CButton>
                  {item?.status && (
                    <CButton
                      className=""
                      color="warning"
                      onClick={() => showCofirmBox(item.totw_id)}
                    >
                      Revert
                    </CButton>
                  )}
                </td>
              </tr>
            ))}
          {props.users.length === 0 && (
            <tr>
              <td colSpan={4}>No record yet available.</td>
            </tr>
          )}
        </tbody>
      </table>
      <TeamOfTheWeekPlayerModal
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal}
        roundNumber={roundNumber}
        options={options}
        fetchData={props.fetchData}
      />
      <TeamOfTheWeekPlayer
        IsModalOpened={playerModalOpen}
        onCloseModal={handlePlayerCloseModal}
        roundNumber={roundNumber}
        playerData={playerData}
        fetchData={props.fetchData}
        teamStatus={teamStatus}
      />
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        message={'Are you sure you want to revert this?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default TeamOfTheWeekTable
