import React from 'react'
import { CCol, CForm, CRow, CFormCheck, CLoadingButton, CFormSelect } from '@coreui/react-pro'
import { useFormik } from 'formik'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'

const TeamSelectionLimit = (props) => {
  const formik = useFormik({
    initialValues: {
      team_selection_limit: props.gameData?.team_selection_limit || 0,
    },
    enableReinitialize: true,
    onSubmit: (data) => {
      data.team_selection_limit_status = props.showTeamSelectionStatus
      CommonService.updateTeamSelectionLimit(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
        }
      })
    },
  })

  const teamSize = props.gameData?.team_size || 1 // Fallback to 1 if teamSize is undefined or null

  // Generate an array of numbers from 1 to teamSize
  const teamSizeLimitValues = Array.from({ length: teamSize }, (v, k) => k + 1)
  return (
    <>
      <CRow>
        <CCol md={12}>
          <CForm className="row g-3" onSubmit={formik.handleSubmit}>
            <div>
              <CFormCheck
                button={{ color: 'success', variant: 'outline' }}
                type="radio"
                name="success-outlined"
                id="default-sidebar-color"
                autoComplete="off"
                value={0}
                label="Default Setting"
                checked={props.showTeamSelectionStatus == 0}
                onChange={props.handleTeamSelectionLimitChangeStatus}
              />
              &nbsp;
              <CFormCheck
                button={{ color: 'danger', variant: 'outline' }}
                type="radio"
                name="options-outlined"
                value={1}
                id="sidebar-custom-outlined"
                autoComplete="off"
                label="Custom Setting"
                checked={props.showTeamSelectionStatus == 1}
                onChange={props.handleTeamSelectionLimitChangeStatus}
              />
              {props.showTeamSelectionStatus === 1 && (
                <>
                  <CRow>
                    <CCol md={6} xs={6} className="pt-3">
                      <label htmlFor="leftColorInput" className="fw-bold">
                        Maximum players a member is allowed to pick from a team?
                      </label>
                      <CFormSelect
                        aria-label="Select Structure"
                        name="team_selection_limit"
                        disabled={props.isTeamExists}
                        className={
                          'mt-3 form-control' +
                          (formik.errors.team_selection_limit && formik.touched.team_selection_limit
                            ? ' is-invalid'
                            : '')
                        }
                        value={formik.values.team_selection_limit}
                        onChange={formik.handleChange}
                        id="team_selection_limit"
                      >
                        {teamSizeLimitValues.map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </CFormSelect>
                    </CCol>
                  </CRow>
                </>
              )}
            </div>
            <CCol md={6}>
              <CLoadingButton type="submit" color="success" variant="outline" id="submit">
                Submit
              </CLoadingButton>
            </CCol>
          </CForm>
        </CCol>
      </CRow>
    </>
  )
}

export default TeamSelectionLimit
