import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
const TimelineSetting = (props) => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [timelineDetails, setTimelineDetails] = useState()
  useEffect(() => {
    CommonService.getTimelineSetting()
      .then((res) => {
        if (res.status === 200) {
          setTimelineDetails(res.data)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
        setLoader(false)
      })
  }, [])
  const validationSchema = Yup.object().shape({
    fantasy_points: Yup.string().required('Fantasy Points Per Match is required.'),
    pts: Yup.string().required('Pts Per Match is required.'),
    reb: Yup.string().required('Reb Per Match is required.'),
    ast: Yup.string().required('Pts Per Match is required.'),
  })
  const formik = useFormik({
    initialValues: {
      fantasy_points: timelineDetails?.fantasy_points,
      pts: timelineDetails?.pts,
      reb: timelineDetails?.reb,
      ast: timelineDetails?.ast,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      actions.resetForm()
      setLoader(true)
      CommonService.setTimelineSetting(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
    },
  })
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Timeline Settings</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                <CCol md={6}>
                  <CFormLabel htmlFor="name" className="fw-bold">
                    Fantasy Points Per Match *
                  </CFormLabel>
                  <CFormInput
                    placeholder=""
                    type="number"
                    className={
                      'form-control' +
                      (formik.errors.fantasy_points && formik.touched.fantasy_points
                        ? ' is-invalid'
                        : '')
                    }
                    defaultValue={formik.values.fantasy_points}
                    onChange={formik.handleChange}
                    aria-label="fantasy_points"
                    id="fantasy_points"
                  />
                  {formik.errors.fantasy_points && formik.touched.fantasy_points && (
                    <CFormFeedback invalid>{formik.errors.fantasy_points}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel htmlFor="name" className="fw-bold">
                    Points Per Match *
                  </CFormLabel>
                  <CFormInput
                    placeholder=""
                    type="number"
                    className={
                      'form-control' +
                      (formik.errors.pts && formik.touched.pts ? ' is-invalid' : '')
                    }
                    defaultValue={formik.values.pts}
                    onChange={formik.handleChange}
                    aria-label="pts"
                    id="pts"
                  />
                  {formik.errors.pts && formik.touched.pts && (
                    <CFormFeedback invalid>{formik.errors.pts}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel htmlFor="name" className="fw-bold">
                    Rebound Per Match *
                  </CFormLabel>
                  <CFormInput
                    placeholder=""
                    type="number"
                    className={
                      'form-control' +
                      (formik.errors.reb && formik.touched.reb ? ' is-invalid' : '')
                    }
                    defaultValue={formik.values.reb}
                    onChange={formik.handleChange}
                    aria-label="reb"
                    id="reb"
                  />
                  {formik.errors.reb && formik.touched.reb && (
                    <CFormFeedback invalid>{formik.errors.reb}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel htmlFor="name" className="fw-bold">
                    Assists Per Match *
                  </CFormLabel>
                  <CFormInput
                    placeholder=""
                    type="number"
                    className={
                      'form-control' +
                      (formik.errors.ast && formik.touched.ast ? ' is-invalid' : '')
                    }
                    defaultValue={formik.values.ast}
                    onChange={formik.handleChange}
                    aria-label="ast"
                    id="ast"
                  />
                  {formik.errors.ast && formik.touched.ast && (
                    <CFormFeedback invalid>{formik.errors.ast}</CFormFeedback>
                  )}
                </CCol>

                <CCol md={6}>
                  <CLoadingButton
                    type="submit"
                    color="success"
                    variant="outline"
                    loading={loader}
                    id="submit"
                  >
                    Submit
                  </CLoadingButton>
                </CCol>
              </CForm>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default TimelineSetting
