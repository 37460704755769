import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL

const getHeadToHead = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}getHeadToHead?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const addHeadTohead = async () => {
  const response = await agent.get(API_URL + 'addHeadTohead')
  return response.data
}
const saveHeadToHead = async (data) => {
  const response = await agent.post(API_URL + 'saveHeadToHead', data)
  return response.data
}
const roundResult = async () => {
  const response = await agent.get(API_URL + 'roundResult')
  return response.data
}
const headToHeadCumulativePoint = async () => {
  const response = await agent.get(API_URL + 'headToHeadCumulativePoint')
  return response.data
}
const showHeadToHeadRound = async (roundId) => {
  const response = await agent.get(API_URL + 'showHeadToHeadRound/' + roundId)
  return response.data
}
const showGroupHeadToHeadRound = async (type, roundId) => {
  const response = await agent.get(API_URL + 'showGroupHeadToHeadRound/' + type + '/' + roundId)
  return response.data
}
const getHeadToHeadSetting = async () => {
  const response = await agent.get(API_URL + 'getHeadToHeadSetting')
  return response.data
}
const headToHeadSetting = async () => {
  const response = await agent.get(API_URL + 'headToHeadSetting')
  return response.data
}
const getPlayOffdata = async () => {
  const response = await agent.get(API_URL + 'getPlayOffdata')
  return response.data
}
const headToHeadLeaderboard = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}headToHeadLeaderboard?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const headToHeadGroup1Leaderboard = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}headToHeadGroup1Leaderboard?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const headToHeadGroup2Leaderboard = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}headToHeadGroup2Leaderboard?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const changeHeadToHeadStatus = async (data) => {
  const response = await agent.post(API_URL + 'changeHeadToHeadStatus', data)
  return response.data
}
const saveH2HRoundResult = async (data) => {
  const response = await agent.post(API_URL + 'saveH2HRoundResult', data)
  return response.data
}
const saveHeadToHeadWinner = async (data) => {
  const response = await agent.post(API_URL + 'saveHeadToHeadWinner', data)
  return response.data
}
const revertHeadToHeadResult = async (data) => {
  const response = await agent.post(API_URL + 'revertHeadToHeadResult', data)
  return response.data
}
const saveHeadToHeadPoint = async (data) => {
  const response = await agent.post(API_URL + 'saveHeadToHeadPoint', data)
  return response.data
}
const markAsCompleteHeadToHead = async (data) => {
  const response = await agent.post(API_URL + 'markAsCompleteHeadToHead', data)
  return response.data
}
const revertHeadToHead = async (data) => {
  const response = await agent.post(API_URL + 'revertHeadToHead', data)
  return response.data
}
const finalizePlayOffFirst = async (data) => {
  const response = await agent.post(API_URL + 'finalizePlayOffFirst', data)
  return response.data
}
const finalizePlayOffSecond = async (data) => {
  const response = await agent.post(API_URL + 'finalizePlayOffSecond', data)
  return response.data
}
const finalizePlayOffThird = async (data) => {
  const response = await agent.post(API_URL + 'finalizePlayOffThird', data)
  return response.data
}
const savePlayOffRound = async (data) => {
  const response = await agent.post(API_URL + 'savePlayOffRound', data)
  return response.data
}
const saveHeadToHeadType = async (data) => {
  const response = await agent.post(API_URL + 'saveHeadToHeadType', data)
  return response.data
}
const getGroupMember = async () => {
  const response = await agent.get(API_URL + 'getGroupMember')
  return response.data
}
const checkHeadToHeadType = async () => {
  const response = await agent.get(API_URL + 'checkHeadToHeadType')
  return response.data
}
const saveGroupMember = async (data) => {
  const response = await agent.post(API_URL + 'saveGroupMember', data)
  return response.data
}

const finalizeGroupPlayOffFirst = async (data) => {
  const response = await agent.post(API_URL + 'finalizeGroupPlayOffFirst', data)
  return response.data
}

const finalizeGroupPlayOffSecond = async (data) => {
  const response = await agent.post(API_URL + 'finalizeGroupPlayOffSecond', data)
  return response.data
}
const finalizeGroupPlayOffFinal = async (data) => {
  const response = await agent.post(API_URL + 'finalizeGroupPlayOffFinal', data)
  return response.data
}
const resetAllH2H = async (data) => {
  const response = await agent.post(API_URL + 'resetAllH2H', data)
  return response.data
}
const deleteHeadToHead = async (data) => {
  const response = await agent.post(API_URL + 'deleteHeadToHead', data)
  return response.data
}
const HeadToHeadService = {
  getHeadToHead,
  addHeadTohead,
  saveHeadToHead,
  roundResult,
  changeHeadToHeadStatus,
  saveH2HRoundResult,
  saveHeadToHeadWinner,
  revertHeadToHeadResult,
  headToHeadCumulativePoint,
  showHeadToHeadRound,
  headToHeadSetting,
  saveHeadToHeadPoint,
  markAsCompleteHeadToHead,
  headToHeadLeaderboard,
  getHeadToHeadSetting,
  revertHeadToHead,
  finalizePlayOffFirst,
  finalizePlayOffSecond,
  finalizePlayOffThird,
  savePlayOffRound,
  getPlayOffdata,
  saveHeadToHeadType,
  getGroupMember,
  saveGroupMember,
  showGroupHeadToHeadRound,
  finalizeGroupPlayOffFirst,
  finalizeGroupPlayOffSecond,
  finalizeGroupPlayOffFinal,
  resetAllH2H,
  checkHeadToHeadType,
  headToHeadGroup1Leaderboard,
  headToHeadGroup2Leaderboard,
  deleteHeadToHead,
}

export default HeadToHeadService
