import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
import Loader from 'src/components/Loader'
import TeamService from 'src/service/TeamService'

const TeamPlayerImage = (props) => {
  const [loading, setLoading] = useState(false)
  const [teamList, setTeamList] = useState(false)
  useEffect(() => {
    setLoading(true)
    TeamService.getTeamListByGrade()
      .then((res) => {
        if (res.status === 200) {
          setTeamList(res.data)
          setLoading(false)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
      })
  }, [])

  const SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    team: Yup.string().required('Team is required'),
    image: Yup.mixed()
      .nullable(true)
      .required('Image is required')
      .test('fileSize', 'File size too large, max file size is 5 Mb', (file) => {
        if (file) {
          return file.size <= 5500000
        } else {
          return true
        }
      })
      .test(
        'type',
        'Invalid file format selection',
        (value) =>
          // console.log(value);
          !value || (value && SUPPORTED_FORMATS.includes(value?.type)),
      ),
  })
  const formik = useFormik({
    initialValues: {
      playerId: props.playerData?.id,
      image: null,
      team: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      var formData = new FormData()
      formData.append('team', data.team)
      formData.append('image', data.image)
      setLoader(true)
      PlayerService.updateTeamPlayerImage(formData).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setLoader(false)
        }
      })
      setLoader(false)
    },
  })
  return loading ? (
    <Loader />
  ) : (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CFormSelect
            aria-label="Select Team"
            name="team"
            className={
              'form-control' + (formik.errors.team && formik.touched.team ? ' is-invalid' : '')
            }
            value={formik.values.team}
            onChange={formik.handleChange}
            id="team"
          >
            <option value={0}>Select Team</option>
            {teamList &&
              teamList.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.name}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.team && formik.touched.team && (
            <CFormFeedback invalid>{formik.errors.team}</CFormFeedback>
          )}
        </CCol>
        <CCol md={4}>
          <CFormInput
            type="file"
            id="formFile"
            name="image"
            className={
              formik.touched.image
                ? formik.errors.image
                  ? 'form-control input_user is-invalid'
                  : 'form-control input_user is-valid'
                : 'form-control'
            }
            onChange={(event) => {
              formik.setTouched({
                ...formik.touched,
                image: true,
              })
              formik.setFieldValue('image', event.target.files[0])
            }}
          />
          <br></br>
          {formik.values.image ? (
            <PreviewImage
              className={{ margin: 'auto' }}
              width={100}
              height={100}
              file={formik.values.image}
            />
          ) : null}
          {formik.touched.image && formik.errors.image ? (
            <CFormFeedback invalid>{formik.errors.image}</CFormFeedback>
          ) : null}
        </CCol>

        <CCol md={4}>
          <div className="form-group">
            {' '}
            <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
              Submit
            </CLoadingButton>
          </div>
        </CCol>
      </CForm>
    </>
  )
}

export default TeamPlayerImage
