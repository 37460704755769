import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import AddForm from './AddForm'
import Table from './Table'
import { useEffect } from 'react'
import HeadToHeadService from 'src/service/HeadToHeadService'
import RoundResult from './RoundResult'
import FinalResult from './FinalResult'
const HeadToHeadBattle = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    HeadToHeadService.showHeadToHeadRound().then((res) => {
      if (res.success) {
        setLoading(false)
        setData(res.h2hData)
      }
    })
  }, [])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>H2H Round Results</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <RoundResult data={data} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default HeadToHeadBattle
