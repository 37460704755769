import {
  CCol,
  CForm,
  CFormCheck,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
import ReactQuill from 'react-quill'

const UpdateImage = (props) => {
  console.log('id', props.selectedImage)

  const hanldleCheck = (type) => {
    if (type === 'avatar') {
      props.setIsAvatar(1)
    }
    if (type === 'upload_image') {
      props.setIsAvatar(0)
    }
  }
  const handleImageClick = (imageId) => {
    props.setSelectedImage(imageId)
  }

  const SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    image: Yup.mixed()
      .nullable(true)
      .test('fileSize', 'File size too large, max file size is 5 Mb', (file) => {
        if (file) {
          return file.size <= 5500000
        } else {
          return true
        }
      })
      .test(
        'type',
        'Invalid file format selection',
        (value) =>
          // console.log(value);
          !value || (value && SUPPORTED_FORMATS.includes(value?.type)),
      ),
  })
  const formik = useFormik({
    initialValues: {
      playerId: props.playerData?.id,
      avatar_image_name: props.playerData?.image,
      image: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      var formData = new FormData()
      formData.append('playerId', props.playerData?.id)
      formData.append('avatar_image_name', props.selectedImage)
      formData.append('is_avatar', props.isAvatar)
      formData.append('image', data.image)
      setLoader(true)
      PlayerService.editPlayerImage(formData).then((res) => {
        if (res.status === 200) {
          //props.setUsers(res.data)
          ToastComponent(res.message, 'success')
          setLoader(false)
        } else {
          setLoader(false)
          ToastComponent(res.message, 'error')
        }
      })
    },
  })

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <div>
            <CFormCheck
              button={{ color: 'success', variant: 'outline' }}
              type="radio"
              name="options-outlined"
              id="success-outlined"
              autoComplete="off"
              label="Avatar"
              onChange={(e) => hanldleCheck('avatar')}
              checked={props.isAvatar === 1 ? true : false}
            />
            &nbsp;&nbsp;
            <CFormCheck
              button={{ color: 'danger', variant: 'outline' }}
              type="radio"
              name="options-outlined"
              id="danger-outlined"
              autoComplete="off"
              label="Upload Image"
              checked={props.isAvatar === 0 ? true : false}
              onChange={(e) => hanldleCheck('upload_image')}
            />
          </div>
        </CCol>
        {props.isAvatar === 1 && (
          <CCol md={12}>
            <strong>Default Avatar</strong>
            <div className="image-container">
              {props.playerData?.position === 1 && (
                <>
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position1a.png`}
                    alt="Imag1"
                    className={
                      props.selectedImage === 'Position1a' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position1a')}
                    width={50}
                    height={50}
                  />
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position1b.png`}
                    alt="Position1b"
                    className={
                      props.selectedImage === 'Position1b' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position1b')}
                    width={50}
                    height={50}
                  />
                </>
              )}
              {props.playerData?.position === 2 && (
                <>
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position2a.png`}
                    alt="Position2a"
                    className={
                      props.selectedImage === 'Position2a' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position2a')}
                    width={50}
                    height={50}
                  />
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position2b.png`}
                    alt="Position2b"
                    className={
                      props.selectedImage === 'Position2b' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position2b')}
                    width={50}
                    height={50}
                  />
                </>
              )}
              {props.playerData?.position === 3 && (
                <>
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position3a.png`}
                    alt="Position3a"
                    className={
                      props.selectedImage === 'Position3a' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position3a')}
                    width={50}
                    height={50}
                  />
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position3b.png`}
                    alt="Position3b"
                    className={
                      props.selectedImage === 'Position3b' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position3b')}
                    width={50}
                    height={50}
                  />
                </>
              )}
              {props.playerData?.position === 4 && (
                <>
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position4a.png`}
                    alt="Position4a"
                    className={
                      props.selectedImage === 'Position4a' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position4a')}
                    width={50}
                    height={50}
                  />
                  <img
                    src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}Position4b.png`}
                    alt="Position4b"
                    className={
                      props.selectedImage === 'Position4b' ? 'selected border border-primary' : ''
                    }
                    onClick={() => handleImageClick('Position4b')}
                    width={50}
                    height={50}
                  />
                </>
              )}
            </div>
            {/* <p>Selected Image: {selectedImage || 'None'}</p> */}
          </CCol>
        )}
        {props.isAvatar !== 1 && (
          <CCol md={12}>
            <CFormLabel className="fw-bold" htmlFor="image">
              Player Photo {props.playerData?.full_name}
            </CFormLabel>
            <CFormInput
              type="file"
              id="formFile"
              name="image"
              className={
                formik.touched.image
                  ? formik.errors.image
                    ? 'form-control input_user is-invalid'
                    : 'form-control input_user is-valid'
                  : 'form-control'
              }
              onChange={(event) => {
                formik.setTouched({
                  ...formik.touched,
                  image: true,
                })
                formik.setFieldValue('image', event.target.files[0])
              }}
            />
            <br></br>
            {formik.values.image ? (
              <PreviewImage
                className={{ margin: 'auto' }}
                width={100}
                height={100}
                file={formik.values.image}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_API_URL}uploads/player/${props.playerData.image}`}
                alt="sponsor"
                width="50"
                height="50"
              />
            )}
            {formik.touched.image && formik.errors.image ? (
              <CFormFeedback invalid>{formik.errors.image}</CFormFeedback>
            ) : null}
          </CCol>
        )}

        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default UpdateImage
