import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FeatureService from 'src/service/FeatureService'
import ToastComponent from 'src/components/common/TaostComponent'
import PreviewImage from '../PreviewImage'
import GeneralService from 'src/service/GeneralService'
const EditForm = (props) => {
  const [messageDetails, setMessageDetails] = useState('')
  const [templateDetail, setTemplateDetail] = useState()
  useEffect(() => {
    if (props.selectedFeatureId === props.featureId) {
      GeneralService.getEmailTemplateDetail(props.featureId).then((res) => {
        if (res.status === 200) {
          setTemplateDetail(res.data)
          setMessageDetails(res?.data.body)
        }
      })
    }
  }, [props])

  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    subject: Yup.string().required('Subject is required'),
  })
  const formik = useFormik({
    initialValues: {
      name: templateDetail?.name,
      subject: templateDetail?.subject,
      body: templateDetail?.body,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      var formData = new FormData()
      formData.append('id', props.featureId)
      formData.append('name', data.name)
      formData.append('subject', data.subject)
      formData.append('body', messageDetails)
      setLoader(true)
      GeneralService.editTemplate(formData).then((res) => {
        if (res.status === 200) {
          props.fetchData(
            props.activePage,
            props.columnFilter,
            props.columnSorter,
            props.itemsPerPage,
          )
          ToastComponent(res.message, 'success')
          setLoader(false)
        } else {
          setLoader(false)
          ToastComponent('something went wrong.', 'error')
        }
      })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Name
          </CFormLabel>
          <input
            type="text"
            name="name"
            className={
              'form-control' + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')
            }
            id="name"
            placeholder="name"
            defaultValue={templateDetail?.name}
            onChange={formik.handleChange}
          />
          {formik.errors.name && formik.touched.name && (
            <CFormFeedback invalid>{formik.errors.name}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="body">
            Subject
          </CFormLabel>
          <input
            type="text"
            name="subject"
            className={
              'form-control' +
              (formik.errors.subject && formik.touched.subject ? ' is-invalid' : '')
            }
            id="subject"
            placeholder="subject"
            defaultValue={templateDetail?.subject}
            onChange={formik.handleChange}
          />
          {formik.errors.subject && formik.touched.subject && (
            <CFormFeedback invalid>{formik.errors.subject}</CFormFeedback>
          )}
        </CCol>

        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Body
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={messageDetails}
            onChange={setMessageDetails}
          />
        </CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EditForm
