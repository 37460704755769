import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import Table from './Table'
import { useParams } from 'react-router-dom'
const Timeline = () => {
  const param = useParams()
  var slug = param.type
  var typeName = ''
  var keyName = ''
  if (slug === 'fantasy') {
    typeName = 'Fantasy Points'
    keyName = 'fantasy_points'
  }
  if (slug === 'pt') {
    typeName = 'Point'
    keyName = 'pt'
  }
  if (slug === 'reb') {
    typeName = 'Rebounds'
    keyName = 'reb'
  }
  if (slug === 'ast') {
    typeName = 'Assists'
    keyName = 'ast'
  }
  const [users, setUsers] = useState([])
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>{typeName} Timeline</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table users={users} typeName={typeName} keyName={keyName} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default Timeline
