import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getFixtureVotingListing = async () => {
  const response = await agent.get(`${API_URL}fixture-voting/listing`)
  return response.data
}
const getFixtureVote = async (fixtureId) => {
  const response = await agent.get(`${API_URL}fixture-voting/get-fixture-voting-data/${fixtureId}`)
  return response.data
}
const getAverageFitureVote = async (fixtureId) => {
  const response = await agent.get(
    `${API_URL}fixture-voting/get-average-fixture-voting/${fixtureId}`,
  )
  return response.data
}
const userPlayerRating = async (fixtureId) => {
  const response = await agent.get(`${API_URL}fixture-voting/user-player-rating/${fixtureId}`)
  return response.data
}
const getUserPlayingModal = async (fixtureId, userId) => {
  const response = await agent.get(
    `${API_URL}fixture-voting/user-player-rating-modal/${fixtureId}/${userId}`,
  )
  return response.data
}

const getVotingActivation = async (fixtureId, userId) => {
  const response = await agent.get(`${API_URL}fixture-voting/getVotingActivation`)
  return response.data
}
const changeVotingStatus = async (status) => {
  const response = await agent.get(`${API_URL}fixture-voting/changeVotingStatus/${status}`)
  return response.data
}
const teamFixtureVoting = async (status) => {
  const response = await agent.get(`${API_URL}fixture-voting/teamFixtureVoting`)
  return response.data
}
const teamFixtureVotingDetail = async (teamId) => {
  const response = await agent.get(`${API_URL}fixture-voting/teamFixtureVotingDetail/${teamId}`)
  return response.data
}

const overallPlayerVoting = async (offset = 0, itemsPerPage = 0, activePage = 0, params = []) => {
  const response = await agent.get(
    `${API_URL}fixture-voting/overallPlayerVoting?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}

const FixtureVotingService = {
  getVotingActivation,
  changeVotingStatus,
  getFixtureVotingListing,
  getFixtureVote,
  getAverageFitureVote,
  userPlayerRating,
  getUserPlayingModal,
  teamFixtureVoting,
  teamFixtureVotingDetail,
  overallPlayerVoting,
}

export default FixtureVotingService
