import React, { useEffect, useState } from 'react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CSmartTable,
} from '@coreui/react-pro'

import HeadToHeadService from 'src/service/HeadToHeadService'
import AllTypeHeadToHeadLeaderboard from './AllTypeHeadToHeadLeaderboard'
import GroupTypeHeadToHeadLeaderboard from './GroupTypeHeadToHeadLeaderboard'
const HeadToHeadLeaderboard = (props) => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(200)
  const [h2hType, setH2hType] = useState('')

  useEffect(() => {
    HeadToHeadService.checkHeadToHeadType().then((res) => {
      if (res.status === 200) {
        setH2hType(res.data)
      }
    })
  }, [])
  console.log('users', h2hType)
  return (
    <>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>H2H Leaderboard</strong>
          </CAccordionHeader>
          <CAccordionBody>
            {h2hType === 'all' && <AllTypeHeadToHeadLeaderboard />}
            {h2hType === 'group' && <GroupTypeHeadToHeadLeaderboard />}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </>
  )
}

export default HeadToHeadLeaderboard
